/**
 * Create a page for all wpPost nodes automatically via
 * filesystem routing: https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */
import * as React from "react"
import { graphql } from "gatsby"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import IWpPost from "../interfaces/IWpPost"
import WordpressContent from "../components/wordpress-content"

interface IPostProps {
  data: {
    wpPost: IWpPost
  }
}

export default function Post(props: IPostProps): JSX.Element {
  const post = props.data.wpPost
  const { setCurrentPost } = React.useContext(UserInterfaceContext)

  // TODO: set currentPost with pageContext to see if it prevents null on load
  React.useEffect(() => {
    setCurrentPost(post)
  }, [props.data, setCurrentPost])

  const html =
    post?.content?.replace(
      /href="https:\/\/(www.georgiancollege.ca|www.gcblueprint.local|georgiangatsby.wpengine.com)\//gim,
      `href="/`
    ) || ``

  return (
    <div>
      <div className="relative w-full bg-cover bg-center h-96">
        <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50 absolute top-0">
          <div className="text-center">
            <h1 className="text-white text-2xl font-semibold uppercase md:text-3xl">
              {post.title}
            </h1>
          </div>
        </div>
      </div>
      <div className="m-4">
        <WordpressContent html={html} />
      </div>
    </div>
  )
}

// configure page, for example set defer to use DSG
// export async function config() {
//   // Optionally use GraphQL here
//   return ({ params }) => {
//     return {
//       defer: true,
//     }
//   }
// }

export const query = graphql`
  query PostContent($uri: String!) {
    wpPost(uri: { eq: $uri }) {
      id
      title
      content
      uri
      databaseId
      topParentId
      breadcrumbs
    }
  }
`
