import React, { useContext } from "react"
import WaffleIcon from "../icons/waffle"
import HamburgerIcon from "../icons/hamburger"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import AuthButton from "./auth-button"
import AppsModal from "./apps-modal"
import SearchModal from "./search-modal"
import Link from "./link"
import PtsCart from "./pts-cart"

interface IHeaderButtonProps {
  className?: string
}

const OriginalInfoForMenu = (): JSX.Element => (
  <ul id="infoForMenu">
    <li id="menu-item-113273-info-for" className="p-4 hover:bg-green">
      <a title="" href="https://www.georgiancollege.ca/future-students/">
        Future students
      </a>
    </li>
    <li id="menu-item-11055-info-for" className="p-4 hover:bg-green">
      <a
        title=""
        href="https://www.georgiancollege.ca/community-alumni/alumni/"
      >
        Alumni
      </a>
    </li>
    <li id="menu-item-113277-info-for" className="p-4 hover:bg-green">
      <a title="" href="https://www.georgiancollege.ca/international/">
        International
      </a>
    </li>
    <li id="menu-item-147103-info-for" className="p-4 hover:bg-green">
      <a title="" href="https://www.georgiancollege.ca/indigenous-initiatives/">
        Indigenous
      </a>
    </li>
    <li id="menu-item-152276-info-for" className="p-4 hover:bg-green">
      <a title="" href="https://www.georgiancollege.ca/parents/">
        Parents
      </a>
    </li>
    <li id="menu-item-127473-info-for" className="p-4 hover:bg-green">
      <a title="" href="https://www.georgiancollege.ca/employers-partners/">
        Employers and partners
      </a>
    </li>
    <li id="menu-item-10927-info-for" className="p-4 hover:bg-green">
      <a
        title=""
        href="https://www.georgiancollege.ca/community-alumni/giving/"
      >
        Donors
      </a>
    </li>
  </ul>
)

function HeaderButtons({ className }: IHeaderButtonProps): JSX.Element {
  const { toggleMobileMenuOpen } = useContext(UserInterfaceContext)
  return (
    <div className={`${className}`}>
      <SearchModal />
      <div className="group relative">
        <button className="hidden lg:block bg-blue hover:bg-green text-white p-3 ml-2">
          Info for
        </button>
        <div className="absolute text-left right-0 min-w-[200px] z-50 bg-blue text-white hidden group-hover:block">
          <OriginalInfoForMenu />
        </div>
      </div>
      <Link
        to="/about-georgian/contact-us/student-recruitment-team"
        className="hidden lg:block bg-blue hover:bg-green text-white p-3 ml-2"
      >
        Connect with a recruiter
      </Link>
      <AppsModal />
      <button className="lg:hidden p-3">
        <WaffleIcon className="w-4 h-4" />
      </button>
      <button onClick={toggleMobileMenuOpen} className="lg:hidden p-3">
        <HamburgerIcon className="w-4 h-4" />
      </button>
      <PtsCart />
      <AuthButton />
    </div>
  )
}

export default HeaderButtons
