// @TODO work with files
import parse, {
  attributesToProps,
  DOMNode,
  domToReact,
} from "html-react-parser"
import React, {
  BaseSyntheticEvent,
  Dispatch,
  SetStateAction,
  useState,
} from "react"
import { Script } from "gatsby"
import { IGravityFormResponse } from "../api/gravity-forms-handler"
import classNames from "../utils/classnames"
import isElement from "../utils/is-element"

import "../styles/legacy/gravityforms/basic.min.css"
import "../styles/legacy/gravityforms/theme-ie11.min.css"
import "../styles/legacy/gravityforms/theme.min.css"
import LoadingSpinner from "./loading-spinner"

interface IHtmlGravityFormProps {
  domNode: DOMNode
}

const HtmlGravityForm = ({ domNode }: IHtmlGravityFormProps): JSX.Element => {
  const [submitting, setSubmitting] = useState(false)
  const [displayDomNode, setDisplayDomNode]: [
    DOMNode,
    Dispatch<SetStateAction<DOMNode>>
  ] = useState(domNode)

  const handleGravityFormSubmit = async (
    e: BaseSyntheticEvent
  ): Promise<void> => {
    setSubmitting(true)
    // console.log(e)
    e.preventDefault()

    const elements = e.target.elements
    const formData = new FormData()
    Object.keys(elements).forEach(key => {
      if (
        key.includes(`input_`) ||
        key.includes(`gform_`) ||
        key.includes(`is_submit_`) ||
        key.includes(`state_`)
      ) {
        formData.append(key, elements[key].value)
      }
    })

    // submit back to WP
    const rawResponse = await fetch(
      `https://georgiandevenv.wpengine.com${window.location.pathname}`,
      {
        method: `POST`,
        body: formData,
      }
    )
    const text = await rawResponse.text()

    parse(text, {
      replace: (domNode: DOMNode) => {
        if (isElement(domNode)) {
          if (
            domNode?.attribs?.class?.includes(`gform_wrapper`) ||
            domNode?.attribs?.class?.includes(`gform_confirmation`)
          ) {
            setDisplayDomNode(domNode)
            setSubmitting(false)
          }
        }
      },
    })
    // console.log(`gformWrapper`, displayDomNode)
  }

  return isElement(displayDomNode) && !submitting ? (
    <>
      <div
        className={classNames(
          // `border-4 border-orange-500`,
          domNode?.attribs?.class
        )}
      >
        {domToReact(displayDomNode.children, {
          replace: (innerDomNode: DOMNode) => {
            if (isElement(innerDomNode)) {
              if (innerDomNode?.tagName === `form`) {
                const formId = innerDomNode?.attribs?.id?.replace(`gform_`, ``)
                return (
                  <>
                    <Script
                      type="text/javascript"
                      src="http://georgiandevenv.wpengine.com/wp-includes/js/jquery/jquery.min.js?ver=3.6.0"
                      id="jquery-core-js"
                    />
                    <Script
                      type="text/javascript"
                      src="http://georgiandevenv.wpengine.com/wp-includes/js/plupload/moxie.min.js?ver=1.3.5"
                      id="moxiejs-js"
                    />
                    <Script
                      type="text/javascript"
                      src="http://georgiandevenv.wpengine.com/wp-includes/js/plupload/plupload.min.js?ver=2.1.9"
                      id="plupload-js"
                    />
                    <Script type="text/javascript">
                      {`var gform;gform||(document.addEventListener("gform_main_scripts_loaded",function(){gform.scriptsLoaded=!0}),window.addEventListener("DOMContentLoaded",function(){gform.domLoaded=!0}),gform={domLoaded:!1,scriptsLoaded:!1,initializeOnLoaded:function(o){gform.domLoaded&&gform.scriptsLoaded?o():!gform.domLoaded&&gform.scriptsLoaded?window.addEventListener("DOMContentLoaded",o):document.addEventListener("gform_main_scripts_loaded",o)},hooks:{action:{},filter:{}},addAction:function(o,n,r,t){gform.addHook("action",o,n,r,t)},addFilter:function(o,n,r,t){gform.addHook("filter",o,n,r,t)},doAction:function(o){gform.doHook("action",o,arguments)},applyFilters:function(o){return gform.doHook("filter",o,arguments)},removeAction:function(o,n){gform.removeHook("action",o,n)},removeFilter:function(o,n,r){gform.removeHook("filter",o,n,r)},addHook:function(o,n,r,t,i){null==gform.hooks[o][n]&&(gform.hooks[o][n]=[]);var e=gform.hooks[o][n];null==i&&(i=n+"_"+e.length),gform.hooks[o][n].push({tag:i,callable:r,priority:t=null==t?10:t})},doHook:function(n,o,r){var t;if(r=Array.prototype.slice.call(r,1),null!=gform.hooks[n][o]&&((o=gform.hooks[n][o]).sort(function(o,n){return o.priority-n.priority}),o.forEach(function(o){"function"!=typeof(t=o.callable)&&(t=window[t]),"action"==n?t.apply(null,r):r[0]=t.apply(null,r)})),"filter"==n)return r[0]},removeHook:function(o,n,t,i){var r;null!=gform.hooks[o][n]&&(r=(r=gform.hooks[o][n]).filter(function(o,n,r){return!!(null!=i&&i!=o.tag||null!=t&&t!=o.priority)}),gform.hooks[o][n]=r)}});`}
                    </Script>
                    <Script
                      type="text/javascript"
                      src="http://georgiandevenv.wpengine.com/wp-content/plugins/gravityforms/js/jquery.json.min.js?ver=2.5.15"
                      id="gform_json-js"
                    />
                    <Script
                      type="text/javascript"
                      src="http://georgiandevenv.wpengine.com/wp-content/plugins/gravityforms/js/gravityforms.min.js?ver=2.5.15"
                      id="gform_gravityforms-js"
                    />
                    <Script
                      type="text/javascript"
                      id="gform_conditional_logic-js-extra"
                    >
                      {`/* <![CDATA[ */
                        var gf_legacy = {"is_legacy":""};
                        var gf_legacy = {"is_legacy":""};
                        /* ]]> */`}
                    </Script>
                    <Script
                      type="text/javascript"
                      src="http://georgiandevenv.wpengine.com/wp-content/plugins/gravityforms/js/conditional_logic.min.js?ver=2.5.15"
                      id="gform_conditional_logic-js"
                    />
                    <form
                      onSubmit={(e): Promise<void> =>
                        handleGravityFormSubmit(e)
                      }
                      {...attributesToProps(innerDomNode.attribs)}
                    >
                      {domToReact(innerDomNode.children)}
                    </form>
                    <Script type="text/javascript">
                      {`
                      gform.initializeOnLoaded( function() { jQuery(document).on('gform_post_render', function(event, formId, currentPage){if(formId == ${formId}) {gf_global["number_formats"][${formId}] = {"1":{"price":false,"value":false},"3":{"price":false,"value":false},"4":{"price":false,"value":false},"2":{"price":false,"value":false}};if(window['jQuery']){if(!window['gf_form_conditional_logic'])window['gf_form_conditional_logic'] = new Array();window['gf_form_conditional_logic'][${formId}] = { logic: { 4: {"field":{"enabled":true,"actionType":"show","logicType":"all","rules":[{"fieldId":"3","operator":"is","value":"yes"}]},"nextButton":null,"section":null} }, dependents: { 4: [4] }, animation: 0, defaults: [], fields: {"1":[],"3":[4],"4":[],"2":[]} }; if(!window['gf_number_format'])window['gf_number_format'] = 'decimal_dot';jQuery(document).ready(function(){window['gformInitPriceFields']();gf_apply_rules(${formId}, [4], true);jQuery('#gform_wrapper_${formId}').show();jQuery(document).trigger('gform_post_conditional_logic', [${formId}, null, true]);} );} } } );jQuery(document).bind('gform_post_conditional_logic', function(event, formId, fields, isInit){} ) } );
                      `}
                    </Script>
                  </>
                )
              }
            }
          },
        })}
      </div>
    </>
  ) : (
    <div className="flex gap-1">
      <LoadingSpinner />
      <div>Submitting...</div>
    </div>
  )
}

export default HtmlGravityForm
