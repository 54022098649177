import { navigate } from "gatsby"
import React, { useState } from "react"
import { GlobalHotKeys } from "react-hotkeys"
import IWpPage from "../interfaces/IWpPage"
import IWpPost from "../interfaces/IWpPost"
import isBrowser from "../utils/isBrowser"

interface IUserInterfaceContextProviderProps {
  children: React.ReactNode
}

export interface IUserInterfaceContext {
  mobileMenuOpen: boolean
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleMobileMenuOpen: () => void
  localMobileMenuOpen: boolean
  setLocalMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleLocalMobileMenuOpen: () => void
  currentPost: IWpPage | IWpPost | null
  setCurrentPost: React.Dispatch<React.SetStateAction<IWpPage | IWpPost | null>>
  searchOpen: boolean
  setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  megaMenuActive: string
  setMegaMenuActive: React.Dispatch<React.SetStateAction<string>>
  showMainContent: boolean
  setShowMainContent: React.Dispatch<React.SetStateAction<boolean>>
  setShowMainContentWithSideEffects: (show: boolean) => void
  showAwkward: boolean
  setShowAwkward: React.Dispatch<React.SetStateAction<boolean>>
  tocPortalRef: React.RefObject<HTMLDivElement>
  tocIsInViewport: boolean
  tocMobilePortalRef: React.RefObject<HTMLDivElement>
  setTocIsInViewport: React.Dispatch<React.SetStateAction<boolean>>
  headerIsInViewport: boolean
  setHeaderIsInViewport: React.Dispatch<React.SetStateAction<boolean>>
  mobileTocOpen: boolean
  setMobileTocOpen: React.Dispatch<React.SetStateAction<boolean>>
  headingInView: string
  setHeadingInView: React.Dispatch<React.SetStateAction<string>>
}

export const UserInterfaceContext = React.createContext<IUserInterfaceContext>({
  mobileMenuOpen: false,
  setMobileMenuOpen: () => null,
  toggleMobileMenuOpen: () => null,
  localMobileMenuOpen: false,
  setLocalMobileMenuOpen: () => null,
  toggleLocalMobileMenuOpen: () => null,
  currentPost: null,
  setCurrentPost: () => null,
  searchOpen: false,
  setSearchOpen: () => null,
  searchTerm: ``,
  setSearchTerm: () => null,
  megaMenuActive: ``,
  setMegaMenuActive: () => null,
  showMainContent: true,
  setShowMainContent: () => null,
  setShowMainContentWithSideEffects: () => null,
  showAwkward: true,
  setShowAwkward: () => null,
  tocPortalRef: React.createRef<HTMLDivElement>(),
  tocIsInViewport: true,
  tocMobilePortalRef: React.createRef<HTMLDivElement>(),
  setTocIsInViewport: () => null,
  headerIsInViewport: true,
  setHeaderIsInViewport: () => null,
  mobileTocOpen: true,
  setMobileTocOpen: () => null,
  headingInView: ``,
  setHeadingInView: () => null,
})

export default function UserInterfaceContextProvider({
  children,
}: IUserInterfaceContextProviderProps): JSX.Element {
  const tocPortalRef = React.useRef<HTMLDivElement>(null)
  const tocMobilePortalRef = React.useRef<HTMLDivElement>(null)
  const [megaMenuActive, setMegaMenuActive] = useState(``)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [localMobileMenuOpen, setLocalMobileMenuOpen] = useState(false)
  const [currentPost, setCurrentPost] = useState<IWpPage | null>(null)
  const toggleMobileMenuOpen = (): void => setMobileMenuOpen(!mobileMenuOpen)
  const toggleLocalMobileMenuOpen = (): void =>
    setLocalMobileMenuOpen(!localMobileMenuOpen)
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState(``)
  const [showMainContent, setShowMainContent] = useState(true)
  const [showAwkward, setShowAwkward] = useState(false)
  const [tocIsInViewport, setTocIsInViewport] = useState(true)
  const [headerIsInViewport, setHeaderIsInViewport] = useState(true)
  const [mobileTocOpen, setMobileTocOpen] = useState(false)
  const [headingInView, setStateHeadingInView] = useState(``)

  const setHeadingInView = (heading: string): void => {
    setStateHeadingInView(heading)
    history.pushState({}, ``, heading)
  }

  const setShowMainContentWithSideEffects = (show: boolean): void => {
    setShowMainContent(show)
    if (show === false) {
      if (localMobileMenuOpen) setLocalMobileMenuOpen(false)
    }
  }

  const keyMap = {
    SEARCH: [`command+k`, `control+k`],
    EDIT: [`command+e`, `control+e`, `ctrl+e`],
  }
  const handlers = {
    SEARCH: (): void => {
      setSearchOpen(!searchOpen)
      if (searchOpen) setSearchTerm(``)
    },
    EDIT: (): void => {
      if (currentPost?.databaseId) {
        window.open(
          `https://georgiangatsby.wpengine.com/wp-admin/post.php?post=${currentPost.databaseId}&action=edit`
        )
      } else {
        alert(`Sorry, unknown post.`)
      }
    },
  }

  if (isBrowser)
    window.georgian_onRouteUpdateDelayed = () => setShowAwkward(true)
  if (isBrowser)
    window.georgian_onRouteUpdate = () => {
      setShowAwkward(false)
      setShowMainContent(true)
    }

  // give loadster access to gatsby navigate
  if (isBrowser) window.georgian_navigate = navigate

  return (
    <UserInterfaceContext.Provider
      value={{
        mobileMenuOpen,
        setMobileMenuOpen,
        toggleMobileMenuOpen,
        localMobileMenuOpen,
        setLocalMobileMenuOpen,
        toggleLocalMobileMenuOpen,
        currentPost,
        setCurrentPost,
        searchOpen,
        setSearchOpen,
        searchTerm,
        setSearchTerm,
        megaMenuActive,
        setMegaMenuActive,
        showMainContent,
        setShowMainContent,
        setShowMainContentWithSideEffects,
        showAwkward,
        setShowAwkward,
        tocPortalRef,
        tocMobilePortalRef,
        tocIsInViewport,
        setTocIsInViewport,
        headerIsInViewport,
        setHeaderIsInViewport,
        mobileTocOpen,
        setMobileTocOpen,
        headingInView,
        setHeadingInView,
      }}
    >
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      {children}
    </UserInterfaceContext.Provider>
  )
}
