import React from "react"

function ChevronIcon({
  className,
}: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`fill-current ${className && className}`}
    >
      <path d="M6.028 0v6.425l5.549 5.575-5.549 5.575v6.425l11.944-12z" />
    </svg>
  )
}

export default ChevronIcon
