const graph = require(`@microsoft/microsoft-graph-client`)

function getAuthenticatedClient(accessToken) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: done => {
      done(null, accessToken.accessToken)
    },
  })

  return client
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken)

  const me = await client.api(`/me`).get()
  const groups = await client.api(`/me/transitiveMemberOf`).get() // if student, they'll have be in: ec044632-7e13-4d23-bd05-fc2fe3273782
  const dept = await client.api(`/me/department`).get()
  let photo
  try {
    const blob = await client.api(`/me/photo/$value`).get()
    const url = window.URL || window.webkitURL
    photo = url.createObjectURL(blob)
  } catch (error) {
    photo = null
  }
  const user = { ...me, photo, groups, dept: dept?.value }
  // console.log({ user });
  return user
}

// export async function getEvents(accessToken) {
//   const client = getAuthenticatedClient(accessToken)
//   const now = new Date()
//   const nowUTC = new Date(now.toUTCString())
//   const end = new Date(+new Date() + 1209600000)
//   const endUTC = new Date(end.toUTCString())
//   const events = await client
//     .api(
//       `/me/calendar/calendarView?startDateTime=${nowUTC.toISOString()}&endDateTime=${endUTC.toISOString()}`
//     )
//     .get()
//   console.log({ events })
//   return events
// }
