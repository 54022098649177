import React, { useEffect, useState } from "react"
import sha1 from "js-sha1"
import { useCookies } from "react-cookie"
import ISection from "../interfaces/ISection"

interface ICrn {
  term: string
  crn: string
  subj: string
  number: string
}

interface IPtsCartContext {
  sections: Array<ISection>
  cookies: { CE_SESSION_ID?: string }
  ptsCartOpen: boolean
  updateCartFromBanner: (crn: string) => void
  setPtsCartOpen: (open: boolean) => void
  addToPtsCart: (section: ISection) => void
  removeFromPtsCart: (section: ISection) => void
}

interface IPtsCartContextProviderProps {
  children: React.ReactNode
}

export const PtsCartContext = React.createContext<IPtsCartContext>({
  sections: [],
  cookies: {},
  ptsCartOpen: false,
  updateCartFromBanner: () => {},
  setPtsCartOpen: () => {},
  addToPtsCart: () => {},
  removeFromPtsCart: () => {},
})

export default function PtsCartContextProvider({
  children,
}: IPtsCartContextProviderProps): JSX.Element {
  const COOKIE_NAME = `CE_SESSION_ID`
  // const BANNERURL = `https://sis-ssb.georgiancollege.ca:9110`
  // const BANNERINSTANCE = `GSB1`
  const [ptsCartOpen, setPtsCartOpen] = useState(false)
  const [cookies, setCookie] = useCookies([COOKIE_NAME])
  const [sections, setSections] = useState<Array<ISection>>([])

  function createCookie(): string {
    const rand = (): number => Math.floor(Math.random() * 10000000 + 0)
    const time = Math.floor(new Date().getTime() / 1000)
    const str1 = sha1(`${time}${rand()}`).toUpperCase().substring(0, 15)
    const str2 = `${rand()}`.padEnd(10, `0`)
    return `${str1}${str2}`
  }

  function addSectionToStateCart(section: ISection): void {
    setSections((currentSections: Array<ISection>) => {
      const newSections = [section, ...currentSections]
      window.sessionStorage.setItem(`ptsCart`, JSON.stringify(newSections))
      return newSections
    })
  }

  useEffect(() => {
    // restore cart from sessionStorage
    if (typeof window !== `undefined`) {
      const cart = window.sessionStorage.getItem(`ptsCart`)
      if (cart) {
        setSections(JSON.parse(cart))
      }
    }
  }, [])

  // compare to banner when cart changes
  useEffect(() => {
    updateCartFromBanner()
  }, [sections])

  useEffect(() => {
    // create a new cookie if don't have one
    if (typeof window !== `undefined` && !cookies[COOKIE_NAME]) {
      const COOKIE_VALUE = createCookie()
      // reset sessionStorage cart if creating new cookie
      window.sessionStorage.setItem(`ptsCart`, [])
      setCookie(COOKIE_NAME, COOKIE_VALUE, {
        domain:
          window.location.hostname === `localhost`
            ? `localhost`
            : `georgiancollege.ca`,
        path: `/`,
      })
    }
  }, [cookies])

  async function updateCartFromBanner(): Promise<void> {
    const resp = await fetch(
      `https://ptscart.azurewebsites.net/api/getCart?code=j9EGvVv9bWnCNlhsAQdr1wAq3N1mr7cYznD9PfUx2obihSGXc3fv0A==`,
      {
        method: `POST`,
        headers: {
          //
          "Set-Cookie": `CE_SESSION_ID=${cookies[COOKIE_NAME]}`, // eslint-disable-line
        },
      }
    )
    if (resp.status === 200) {
      const cart = await resp.json()
      const removeIndexes: Array<number> = []
      // check each `section` in the state cart to see
      // if there is a matching entry in the banner cart
      // if not, push to removeIndexes for removal
      sections.forEach((s, i) => {
        const index = cart.crns.findIndex(
          (c: ICrn) =>
            c.term === s.TermCode &&
            c.crn === s.CRN &&
            c.subj === s.SubjCode &&
            c.number === s.CourseNumber
        )
        if (index === -1) removeIndexes.push(i)
      })

      // if there are sections to remove setSections to
      // newSections with indexes removed, update
      // sessionStorage with newSections
      if (removeIndexes.length > 0) {
        const newSections = sections.filter(
          (s, i) => !removeIndexes.includes(i)
        )
        setSections(newSections)
        if (typeof window !== `undefined`) {
          window.sessionStorage.setItem(`ptsCart`, JSON.stringify(newSections))
        }
      }
    }
  }

  async function addToPtsCart(section: ISection): Promise<void> {
    const resp = await fetch(
      `https://ptscart.azurewebsites.net/api/addToCart?code=cEYaFm4MWzviorEEl7AHYs5vi9/11dzEX1FmkyrIaNpyUej18gKTow==`,
      {
        method: `POST`,
        // mode: "no-cors",
        headers: {
          "Content-type": `application/json`, // eslint-disable-line
          "Set-Cookie": `CE_SESSION_ID=${cookies[COOKIE_NAME]}`, // eslint-disable-line
          // Origin: `${BANNERURL}/${BANNERINSTANCE}/ptwebreg_pll.P_ShoppingCart`,
        },
        body: JSON.stringify({
          subject: section.SubjCode,
          title: section.Title,
          number: section.CourseNumber,
          crn: section.AddHash,
        }),
      }
    )
    // console.log({ status: resp.status })
    if (resp.status === 200) {
      addSectionToStateCart(section)
    }
  }

  async function removeFromPtsCart(section: ISection): Promise<void> {
    const resp = await fetch(
      `https://ptscart.azurewebsites.net/api/removeFromCart?code=mmlw4o7w/QG5VVDZPVJbZNrDqaZWhZZezVwvtz7kwXWzq5dAKiht7A==`,
      {
        method: `POST`,
        // mode: "no-cors",
        headers: {
          "Content-type": `application/json`, // eslint-disable-line
          "Set-Cookie": `CE_SESSION_ID=${cookies[COOKIE_NAME]}`, // eslint-disable-line
        },
        body: JSON.stringify({
          query: section.RemoveHash,
        }),
      }
    )
    // console.log({ status: resp.status })
    if (resp.status === 200) {
      updateCartFromBanner()
    }
  }

  return (
    <PtsCartContext.Provider
      value={{
        sections,
        cookies,
        ptsCartOpen,
        updateCartFromBanner,
        setPtsCartOpen,
        addToPtsCart,
        removeFromPtsCart,
      }}
    >
      {children}
    </PtsCartContext.Provider>
  )
}
