/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useContext } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { graphql, useStaticQuery } from "gatsby"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import SearchIcon from "../icons/search"
import Link from "./link"
import slugify from "../utils/slugify"

interface IWpNode {
  title: string
  uri: string
  content: string
}
interface IPtsCourse {
  Code: string
  Number: string
  Title: string
  Description: string
}

interface ISearchModalData {
  allWpPage: { nodes: Array<IWpNode> }
  allWpPost: { nodes: Array<IWpNode> }
  allPtsCourse: { nodes: Array<IPtsCourse> }
}

export default function SearchModal(): JSX.Element {
  const { searchOpen, setSearchOpen, searchTerm, setSearchTerm } =
    useContext(UserInterfaceContext)
  const data: ISearchModalData = useStaticQuery(graphql`
    {
      allWpPage {
        nodes {
          title
          uri
          # content
        }
      }
      allWpPost {
        nodes {
          title
          uri
          # content
        }
      }
      allPtsCourse {
        nodes {
          Code
          Number
          Title
          # Description
        }
      }
    }
  `)

  const pages = data?.allWpPage?.nodes?.filter(
    node =>
      node?.title?.toLowerCase().indexOf(searchTerm) >= 0 ||
      node?.content?.toLowerCase().indexOf(searchTerm) >= 0
  )
  const posts = data?.allWpPost?.nodes?.filter(
    node =>
      node?.title?.toLowerCase().indexOf(searchTerm) >= 0 ||
      node?.content?.toLowerCase().indexOf(searchTerm) >= 0
  )
  const courses = data?.allPtsCourse?.nodes?.filter(
    node =>
      node?.Title?.toLowerCase().indexOf(searchTerm) >= 0 ||
      node?.Description?.toLowerCase().indexOf(searchTerm) >= 0
  )

  function closeSearch(): void {
    setSearchOpen(false)
    setSearchTerm(``)
  }

  return (
    <Fragment>
      <button
        title="shortcut: [cmd/ctrl]+k"
        onClick={(): void => setSearchOpen(true)}
        className="p-3"
      >
        <SearchIcon className="w-4 h-4 lg:w-6 lg:h-6" />
      </button>
      <Transition.Root show={searchOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={searchOpen}
          onClose={closeSearch}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 lg:max-w-[50%]">
                <input
                  onChange={e => {
                    setSearchTerm(e.target.value.toLowerCase())
                  }}
                  placeholder="Search GeorgianCollege.ca"
                  className="border-2 border-lightgray text-xl w-full p-4"
                  type="text"
                />
                {searchTerm !== `` && (
                  <div className="grid grid-cols-2 gap-4 mt-8">
                    <div>
                      <div className={courses?.length > 0 ? `block` : `hidden`}>
                        <h2 className="font-bold text-lg">Courses</h2>
                        <ul className="mb-8">
                          {courses
                            .filter((p, i) => i < 10)
                            .map(p => {
                              const slug = `/marine-training/marine-courses/${slugify(
                                p.Title
                              )}-${p.Code.toLowerCase()}-${p.Number}`

                              const uri = slug.replace(
                                window?.location?.pathname,
                                ``
                              )
                              return (
                                <li key={p.id} className="group">
                                  <Link
                                    onClick={closeSearch}
                                    className="block p-2 -ml-2 hover:bg-lightgray"
                                    to={slug}
                                  >
                                    {p.Title}
                                    <div className="hidden group-hover:block text-xs text-gray-100">
                                      {slug}
                                    </div>
                                  </Link>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                      <div className={pages?.length > 0 ? `block` : `hidden`}>
                        <h2 className="font-bold text-lg">Pages</h2>
                        <ul>
                          {pages
                            .filter((p, i) => i < 10)
                            .map(p => (
                              <li key={p.id} className="group">
                                <Link
                                  onClick={closeSearch}
                                  className="block p-2 -ml-2 hover:bg-lightgray"
                                  to={p.uri}
                                >
                                  {p.title}
                                  <div className="hidden group-hover:block text-xs text-gray-100">
                                    {p.uri}
                                  </div>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div className={posts?.length > 0 ? `block` : `hidden`}>
                      <h2 className="font-bold text-lg">News stories</h2>
                      <ul>
                        {posts
                          .filter((p, i) => i < 10)
                          .map(p => (
                            <li key={p.id} className="group">
                              <Link
                                onClick={closeSearch}
                                className="block p-2 -ml-2 hover:bg-lightgray"
                                to={p.uri}
                              >
                                {p.title}
                                <div className="hidden group-hover:block text-xs text-gray-100">
                                  {p.uri}
                                </div>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}
                {pages.length === 0 &&
                  posts.length === 0 &&
                  courses.length === 0 && (
                    <div className="text-center text-gray-100 italic">
                      No results
                    </div>
                  )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  )
}
