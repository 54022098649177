import React, { useState, useEffect, useMemo } from "react"
import isBrowser from "./isBrowser"

function useIsAtViewportTop(ref: React.RefObject<HTMLElement>): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false)
  if (!isBrowser) return false
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting)
          // if (entry.isIntersecting) console.log({ entry, el: ref.current })
        },
        { rootMargin: `0px 0px -75% 0px`, threshold: 0.1 }
      ),
    []
  )

  useEffect(() => {
    if (ref.current) observer.observe(ref.current)

    return (): void => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export default useIsAtViewportTop
