// @TODO work with files
import parse, { DOMNode, domToReact } from "html-react-parser"
import React from "react"
import environment from "../environment"
import ChevronIcon from "../icons/chevron"
import HomeIcon from "../icons/home"
import isElement from "../utils/is-element"
import isText from "../utils/is-text"
import Link from "./link"

interface IBreadcrumbsProps {
  breadcrumbs: string
}

interface IYoastBreadcrumb {
  url: string
  text: string
  id: number
}

const Breadcrumbs = ({ breadcrumbs }: IBreadcrumbsProps): JSX.Element => {
  const useYoastHtml = breadcrumbs.includes(`<span>`)
  const json: Array<IYoastBreadcrumb> = useYoastHtml
    ? null
    : JSON.parse(breadcrumbs)
  const breadcrumbsReplace = (domNode: DOMNode): JSX.Element | DOMNode => {
    // console.log({ domNode })
    if (isElement(domNode)) {
      if (domNode?.attribs?.href === `/`) {
        return (
          <Link to="/" className="flex">
            <HomeIcon className="w-4 h-4 inline text-gray-300" />
          </Link>
        )
      } else if (domNode?.name === `a`) {
        return (
          <Link to={domNode.attribs.href} className="text-gray-300">
            {domToReact(domNode.children, {
              replace: breadcrumbsReplace,
            })}
          </Link>
        )
      } else if (
        domNode.name === `span` &&
        domNode.attribs[`aria-current`] !== `page`
      ) {
        return (
          <>
            {domToReact(domNode.children, {
              replace: breadcrumbsReplace,
            })}
          </>
        )
      }
    } else if (isText(domNode)) {
      if (domNode.data === ` » `) {
        return <ChevronIcon className="w-3 h-3 inline" />
      }
    }
    return domNode
  }
  return (
    <div className="bg-lightgray">
      <div className="container max-w-[1202px] px-4 mx-auto py-2 flex gap-1 items-center">
        {useYoastHtml ? (
          parse(breadcrumbs, {
            replace: breadcrumbsReplace,
          })
        ) : (
          <>
            {json.map((item, index) => {
              if (index === 0) {
                return (
                  <>
                    <Link
                      key={index}
                      to={item.url.replace(environment.SOURCE_URL, ``)}
                      className="flex"
                    >
                      <HomeIcon className="w-4 h-4 inline text-gray-300" />
                    </Link>
                    <ChevronIcon className="w-3 h-3 inline" />
                  </>
                )
              } else if (index === json.length - 1) {
                return (
                  <span key={index} aria-current="page">
                    {item.text}
                  </span>
                )
              } else {
                return (
                  <>
                    <Link
                      key={index}
                      to={item.url.replace(environment.SOURCE_URL, ``)}
                      className="text-gray-300"
                    >
                      {item.text}
                    </Link>
                  </>
                )
              }
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default Breadcrumbs
