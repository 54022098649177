/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"

const OriginalLogins = (): JSX.Element => (
  <Fragment>
    <p>
      <a
        title="login to Banner"
        href="https://www.georgiancollege.ca/admissions/banner-login-information/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="Log into Banner"
          alt="Banner login"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_Banner.png"
          width="195"
          height="65"
        />
      </a>
    </p>
    <p>
      <a
        href="https://georgiancollege.sharepoint.com/sites/gcPortal/SitePages/Home.aspx"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="Student Portal"
          alt="Student Portal"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_StudentPortal.png"
          width="190"
          height="109"
        />
      </a>
    </p>
    <p>
      <a
        href="https://password.georgiancollege.ca"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="log into Georgian College password manager"
          alt="log into Georgian College password manager"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_PasswordManager.png"
          width="195"
          height="63"
        />
      </a>
    </p>
    <p>
      <a
        href="/student-life/campus-services/information-technology/tab/login-procedures/#turnitin"
        target="_blank"
      >
        <img
          className=""
          title="Turnitin, plagiarism prevention"
          alt="Turnitin, plagiarism prevention"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_Turnitin.png"
          width="195"
          height="63"
        />
      </a>
    </p>
    <p>
      <a
        href="https://olportal.ontariolearn.com/login.php"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="OntarioLearn Login"
          alt="OntarioLearn Logo"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/OL_Logo.jpg"
          width="195"
          height="63"
        />
      </a>
    </p>
    <p>
      <a
        href="https://georgianparking.t2hosted.com/Account/Portal"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="Georgian Parking"
          alt="Georgian Parking Logo"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/Parking_Logo.jpg"
          width="195"
          height="63"
        />
      </a>
    </p>
    <p>
      <a
        href="https://georgiancollege.sharepoint.com/SitePages/blackboard.aspx"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="Log into Blackboard"
          alt="Log into Blackboard"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_Blackboard.png"
          width="185"
          height="65"
        />
      </a>
    </p>
    <p>
      <a
        href="http://mail.georgiancollege.ca/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="student e-mail"
          alt="student e-mail"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_StudentEmail.png"
          width="195"
          height="63"
        />
      </a>
    </p>
    <p>
      <a
        href="https://library.georgiancollege.ca/main"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="Library Services"
          alt="Library Services"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_Library.png"
          width="195"
          height="63"
        />
      </a>
    </p>
    <p>
      <a
        href="https://georgiancollege.sharepoint.com/sites/Employee/SitePages/Home.aspx"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="Staff Intranet"
          alt="Staff Intranet"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_EmployeePortal.png"
          width="195"
          height="63"
        />
      </a>
    </p>
    <p>
      <a
        href="https://careerandcoop.georgiancollege.ca/home.htm"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className=""
          title="Employ Georgian"
          alt="Employ Georgian"
          src="https://www.georgiancollege.ca/wp-content/themes/georgian/imgs/LoginIcons_EmployGeorgian.png"
          width="195"
          height="63"
        />
      </a>
    </p>
  </Fragment>
)

export default function AppsModal(): JSX.Element {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <button
        onClick={() => setOpen(true)}
        className="hidden lg:block bg-blue hover:bg-green text-white p-3 ml-2"
      >
        Apps
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 lg:max-w-lg">
                <div className="grid grid-cols-3 gap-4">
                  <OriginalLogins />
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue text-base font-medium text-white hover:bg-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  )
}
