// @TODO cleanup leftover code, create GravityFormHtml component
import React, {
  BaseSyntheticEvent,
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import * as ReactDOM from "react-dom"
import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
  domToReact,
} from "html-react-parser"
import DOMPurify from "dompurify"
import HtmlGravityForm from "./html-gravity-form"
import isElement from "../utils/is-element"
import { navigate, Script } from "gatsby"
import { attributesToProps } from "html-react-parser"
import isScript from "../utils/is-script"
import Link from "./link"
import classNames from "../utils/classnames"
import isText from "../utils/is-text"
import isComment from "../utils/is-comment"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import useIsAtViewportTop from "../utils/useIsAtViewportTop"
import { Transition } from "@headlessui/react"
import useIsInViewport from "../utils/useIsInViewport"
import ChevronIcon from "../icons/chevron"

interface IWordpressContentProps {
  html: string
  sanitize?: boolean
}

export const options: HTMLReactParserOptions = {
  replace: (domNode: DOMNode) => {
    // console.log({ domNode })
    // replace script tags with gatsby <Script>
    if (isScript(domNode)) {
      return (
        <Script {...attributesToProps(domNode.attribs)}>
          {domToReact(domNode.children, {
            replace: (domNode: DOMNode): JSX.Element => {
              if (isText(domNode) && !isComment(domNode)) return domNode
              return <></>
            },
          }).toString()}
        </Script>
      )
    }
    if (isElement(domNode)) {
      // replace GC Table of Contents block
      if (
        domNode?.attribs?.class?.includes(
          `wp-block-georgiancollege-table-of-contents`
        )
      ) {
        delete domNode?.attribs?.class
        return <TableOfContentsBlock domNode={domNode} />
      }
      // replace headings
      if (
        domNode?.name === `h1` ||
        domNode?.name === `h2` ||
        domNode?.name === `h3` ||
        domNode?.name === `h4` ||
        domNode?.name === `h5` ||
        domNode?.name === `h6`
      ) {
        return <Heading domNode={domNode} />
      }
      // replace relative links
      if (domNode?.name === `a` && domNode?.attribs?.href?.indexOf(`/`) === 0) {
        const to = domNode.attribs.href
        delete domNode.attribs.href
        return (
          <Link to={to} {...attributesToProps(domNode.attribs)}>
            {domToReact(domNode.children, options)}
          </Link>
        )
      }
      // replace anchor links
      if (domNode?.name === `a` && domNode?.attribs?.href?.indexOf(`#`) === 0) {
        return <AnchorLink domNode={domNode} />
      }
      // replace gravity forms
      if (domNode?.attribs?.class?.includes(`gform_wrapper`)) {
        return <HtmlGravityForm domNode={domNode} />
      }
      // example replacement
      if (domNode?.attribs?.class) {
        switch (domNode.attribs.class) {
          case `example-replace-class`:
            return (
              <div className="replaced-element">
                {domToReact(domNode.children, options)}
              </div>
            )
        }
      }
    }
    return domNode
  },
}

const WordpressContent = ({
  html,
  sanitize = false,
}: IWordpressContentProps): JSX.Element => {
  const { tocPortalRef } = useContext(UserInterfaceContext)
  return (
    <>
      {parse(sanitize ? DOMPurify.sanitize(html) : html, options)}
      <div
        ref={tocPortalRef}
        id="table-of-contents-portal"
        className="absolute right-4 top-0 h-full border-0"
      ></div>
    </>
  )
}

interface IHeadingProps {
  domNode: Element
}
const Heading = ({ domNode }: IHeadingProps): JSX.Element => {
  // console.log(`heading`, domNode)
  const ref = useRef(null)
  const { setHeadingInView, headingInView } = useContext(UserInterfaceContext)
  const isInView = useIsAtViewportTop(ref)
  const Tag = domNode.name as "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  const id = domNode.attribs.id

  useEffect(() => {
    const anchor = `#${id}`
    const parent = domNode?.parent as Element
    if (
      isInView &&
      id &&
      id !== `` &&
      parent.attribs.class?.includes(
        `wp-block-georgiancollege-page-section alignfull`
      )
    ) {
      setHeadingInView(anchor)
    }
    if (!isInView && headingInView === anchor) {
      // setHeadingInView(``)
    }
  }, [isInView])
  return (
    <Tag ref={ref} id={id} {...attributesToProps(domNode.attribs)}>
      {domToReact(domNode.children, options)}
    </Tag>
  )
}

interface IAnchorLinkProps {
  domNode: Element
}
const AnchorLink = ({ domNode }: IAnchorLinkProps): JSX.Element => {
  // console.log(`anchor link`, domNode)
  const { headingInView, setHeadingInView, setMobileTocOpen } =
    useContext(UserInterfaceContext)
  return (
    <a
      className={classNames(
        headingInView === domNode.attribs.href ? `active font-bold` : ``
      )}
      {...attributesToProps(domNode.attribs)}
      onClick={(e): void => {
        e.preventDefault()
        document.querySelector(domNode.attribs.href)?.scrollIntoView({
          behavior: `smooth`,
        })
        history.pushState({}, ``, domNode.attribs.href)
        setMobileTocOpen(false)
        // setHeadingInView(domNode.attribs.href)
      }}
    >
      {domToReact(domNode.children, options)}
    </a>
  )
}

interface ITableOfContentsProps {
  domNode: Element
}
const TableOfContentsBlock = ({
  domNode,
}: ITableOfContentsProps): JSX.Element => {
  // console.log(`toc block`)
  const { setTocIsInViewport } = useContext(UserInterfaceContext)
  const tocRef = React.useRef<HTMLDivElement>(null)
  const isInView = useIsInViewport(tocRef)

  useEffect(() => {
    setTocIsInViewport(isInView)
  }, [isInView])
  return (
    <>
      <div
        ref={tocRef}
        className={classNames(`wp-block-georgiancollege-table-of-contents`)}
        {...attributesToProps(domNode.attribs)}
      >
        {domToReact(domNode.children, options)}
      </div>
      <StickyTableOfContents>
        {domToReact(domNode.children, options)}
      </StickyTableOfContents>
    </>
  )
}

const StickyTableOfContents = ({
  children,
}: {
  children: Array<DOMNode>
}): JSX.Element => {
  // console.log(`test`)
  const {
    tocPortalRef,
    tocMobilePortalRef,
    tocIsInViewport,
    headerIsInViewport,
    setMobileTocOpen,
    mobileTocOpen,
  } = useContext(UserInterfaceContext)
  return tocPortalRef.current && tocMobilePortalRef.current ? (
    <>
      {ReactDOM.createPortal(
        <Transition
          as={React.Fragment}
          show={!tocIsInViewport && !headerIsInViewport}
          appear
          enter="transform transition duration-50"
          enterFrom="opacity-0 translate-y-12"
          enterTo="opacity-100 translate-y-0"
          leave="transform transition duration-50"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-12"
        >
          <div
            className={classNames(
              `hidden 2xl:block sticky bottom-0 left-0 lg:top-4 lg:right-0 mb-4 border border-white`
            )}
          >
            <div className="rounded rounded-tl-none w-56 bg-lightgray">
              <p className="font-semibold px-4 pt-2">On this page:</p>
              {domToReact([children[1]], options)}
            </div>
          </div>
        </Transition>,
        tocPortalRef.current
      )}
      {ReactDOM.createPortal(
        <Transition
          as={React.Fragment}
          show={!tocIsInViewport && !headerIsInViewport}
          appear
          enter="transform transition duration-50"
          enterFrom="opacity-0 -translate-y-12"
          enterTo="opacity-100 translate-y-0"
          leave="transform transition duration-50"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-12"
        >
          <div
            id="table-of-contents-mobile"
            className={classNames(mobileTocOpen ? `mobileTocOpen` : ``)}
          >
            <div className="2xl:hidden w-full bg-lightgray flex gap-2 align-middle items-center">
              <p className="pl-4 font-semibold my-4 basis-28 grow-0 shrink-0">
                On this page:
              </p>
              <div
                onClick={
                  mobileTocOpen
                    ? undefined
                    : (e): void => {
                        e.preventDefault()
                        e.stopPropagation()
                        setMobileTocOpen(true)
                      }
                }
                className="grow"
              >
                {domToReact([children[1]], options)}
              </div>
              <div
                onClick={(): void => {
                  setMobileTocOpen(!mobileTocOpen)
                }}
                className="px-4 ml-auto my-4"
              >
                <ChevronIcon className="w-4 h-4 rotate-90" />
              </div>
            </div>
          </div>
        </Transition>,
        tocMobilePortalRef.current
      )}
    </>
  ) : (
    <></>
  )
}
export default WordpressContent
