import React from "react"

import SpeechIcon from "../icons/speech"
import GpsIcon from "../icons/gps"
import QuestionIcon from "../icons/question"
import SocialIcons from "../icons/social"

function FooterContent(): JSX.Element {
  return (
    <div className="container px-4 mx-auto mt-8 lg:flex">
      <div className="mt-8 lg:mt-0 grid grid-cols-5 gap-4 lg:w-5/12">
        <div className="col-span-1 rounded-full w-12 h-12 bg-blue-200 p-3 mr-4">
          <SpeechIcon />
        </div>
        <div className="col-span-4">
          <p className="font-bold text-lg">Connect with us!</p>
          <div>
            <button className="text-xs lg:text-sm bg-green hover:bg-orange text-white px-3 py-2 mr-2 inline-block mt-4">
              Connect with a recruiter
            </button>
            <button className="text-xs lg:text-sm bg-green hover:bg-orange text-white px-3 py-2 inline-block mt-2">
              Join our email list
            </button>
          </div>
          <ul className="block mt-4 grid grid-cols-5 lg:flex">
            <li className="mr-4 hover:bg-green rounded w-12 h-12 p-2 cursor-pointer">
              <SocialIcons.Twitter className="text-white w-8 h-8" />
            </li>
            <li className="mr-4 hover:bg-green rounded w-12 h-12 p-2 cursor-pointer">
              <SocialIcons.Facebook className="text-white w-8 h-8" />
            </li>
            <li className="mr-4 hover:bg-green rounded w-12 h-12 p-2 cursor-pointer">
              <SocialIcons.Youtube className="text-white w-8 h-8" />
            </li>
            <li className="mr-4 hover:bg-green rounded w-12 h-12 p-2 cursor-pointer">
              <SocialIcons.Instagram className="text-white w-8 h-8" />
            </li>
            <li className="mr-4 hover:bg-green rounded w-12 h-12 p-2 cursor-pointer">
              <SocialIcons.Linkedin className="text-white w-8 h-8" />
            </li>
          </ul>
          <ul className="block mt-4">
            <li className="leading-tight">
              <a href="https://www.georgiancollege.ca/about-georgian/student-recruitment-team/">
                Student recruitment team
              </a>
            </li>
            <li className="leading-tight">
              <a href="https://www.georgiancollege.ca/privacy-policy/">
                Privacy and access information
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-8 lg:mt-0 grid grid-cols-5 gap-4 lg:w-3/12">
        <div className="col-span-1 rounded-full w-12 h-12 bg-blue-200 p-3 mr-4">
          <QuestionIcon />
        </div>
        <div className="col-span-4">
          <p className="font-bold text-lg mb-4">Information for: </p>
          <ul>
            <li className="leading-tight">
              <a href="/future-students/">Future students</a>
            </li>
            <li className="leading-tight">
              <a href="/parents/">Parents and supporters</a>
            </li>
            <li className="leading-tight">
              <a href="/current-students/">Current students</a>
            </li>
            <li className="leading-tight">
              <a href="/community-alumni/alumni/">Alumni</a>
            </li>
            <li className="leading-tight">
              <a href="/international/">International</a>
            </li>
            <li className="leading-tight">
              <a href="/employers-partners/">Employers and partners</a>
            </li>
            <li className="leading-tight">
              <a href="/community-alumni/giving/">Donors</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-8 lg:mt-0 grid grid-cols-5 gap-4 lg:w-4/12">
        <div className="col-span-1 rounded-full w-12 h-12 bg-blue-200 p-3 mr-4">
          <GpsIcon />
        </div>
        <div className="col-span-4">
          <p className="font-bold text-lg mb-4">Campuses</p>
          <ul className="">
            <li className="leading-tight w-1/2 inline-block pr-2">
              <a href="/about-georgian/campuses/barrie-campus/">Barrie</a>
            </li>
            <li className="leading-tight w-1/2 inline-block pr-2">
              <a href="/about-georgian/campuses/midland-campus/">Midland</a>
            </li>
            <li className="leading-tight w-1/2 inline-block pr-2">
              <a href="/about-georgian/campuses/Muskoka-campus/">Muskoka</a>
            </li>
            <li className="leading-tight w-1/2 inline-block pr-2">
              <a href="/about-georgian/campuses/Orangeville-campus/">
                Orangeville
              </a>
            </li>
            <li className="leading-tight w-1/2 inline-block pr-2">
              <a href="/about-georgian/campuses/orillia-campus/">Orillia</a>
            </li>
            <li className="leading-tight w-1/2 inline-block pr-2">
              <a href="/about-georgian/campuses/owen-sound-campus/">
                Owen Sound
              </a>
            </li>

            <li className="leading-tight w-1/2 inline-block pr-2">
              <a href="/about-georgian/campuses/south-georgian-bay-campus/">
                South Georgian Bay
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FooterContent
