import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import flatListToHierarchical from "../utils/flat-list-to-hierarchical"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import ChevronIcon from "../icons/chevron"
import LocalMobileMenu from "./local-menu-mobile"
import HamburgerIcon from "../icons/hamburger"
import Link from "./link"
import IWpMenuItem from "../interfaces/IWpMenuItem"
import HomeIcon from "../icons/home"

interface ILocalMenuData {
  allWpMenuItem: {
    nodes: Array<IWpMenuItem>
  }
}

interface ILocalMenuOutputProps {
  localMenuItems: IWpMenuItem
}

const LocalMenuOutput = ({
  localMenuItems,
}: ILocalMenuOutputProps): JSX.Element => (
  <ul>
    {localMenuItems?.children?.map((item: IWpMenuItem) => (
      <li
        key={item.id}
        className={`${
          item.url === `#hiddenlinks` ? `hidden` : `block lg:inline-block`
        } group hover:bg-gray`}
      >
        <Link className="p-6 py-4 lg:p-4 block lg:inline-block" to={item.url}>
          {item.label}
        </Link>
        {(item?.children?.length || 0) > 0 && (
          <ul className="pl-8 lg:pl-0 lg:absolute lg:w-64 font-light group lg:hidden bg-blue group-hover:block z-50">
            {item?.children?.map(item => (
              <li key={item.id} className="relative group">
                <Link
                  className="block lg:flex lg:justify-between lg:items-center px-4 py-3 hover:bg-gray"
                  to={item.url}
                >
                  <span>{item.label}</span>
                  {(item?.children?.length || 0) > 0 && (
                    <>
                      <ChevronIcon className="hidden lg:inline-block w-4 h-4 -mr-2" />
                      {/* TODO: nested hovering */}
                      <ul className="pl-4 lg:pl-0 lg:hidden lg:absolute left-full top-0 bg-blue group-hover:block lg:w-64">
                        {item?.children?.map(item => (
                          <li key={item.id}>
                            <Link
                              className="block px-4 py-3 hover:bg-gray"
                              to={item.url || ``}
                            >
                              {item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
)

const LocalMenu = (): JSX.Element | null => {
  const { currentPost, setLocalMobileMenuOpen } =
    useContext(UserInterfaceContext)
  const data: ILocalMenuData = useStaticQuery(graphql`
    {
      allWpMenuItem(filter: { locations: { in: LOCAL_MENU } }) {
        nodes {
          url
          label
          parentId
          id
          description
          cssClasses
          connectedNode {
            node {
              id
              databaseId
            }
          }
        }
      }
    }
  `)
  // transform flat menuItems list to hierarchical
  const menuItems = flatListToHierarchical(data.allWpMenuItem.nodes)

  // get tree where root item matches this page/post
  const findLocalMenu = (
    menuItems: Array<IWpMenuItem>
  ): IWpMenuItem | undefined =>
    menuItems.find(item => {
      if (
        // item.connectedNode.node.databaseId === (currentPost.topParentId || currentPost.databaseId)
        item?.connectedNode?.node?.databaseId === currentPost?.topParentId
      )
        return true
      findLocalMenu(item?.children || [])
      return false
    })
  const localMenuItems = findLocalMenu(menuItems)

  // no local menu, return nothing
  if (!localMenuItems || !currentPost) return null
  return (
    <nav className="container max-w-[1202px] mx-auto px-2 flex justify-between lg:justify-start items-center text-white">
      <Link
        to={localMenuItems?.url || ``}
        className="font-bold flex items-center px-2 py-2 my-2 text-white gap-2"
      >
        <HomeIcon className="lg:hidden h-4 w-4 mb-1" />
        {` `}
        <span>{localMenuItems.label}</span>
      </Link>
      <div className="hidden lg:block ml-10">
        <LocalMenuOutput localMenuItems={localMenuItems} />
      </div>
      <div className="lg:hidden">
        <button
          onClick={(): void => setLocalMobileMenuOpen(true)}
          className="flex items-center px-2 py-2 my-2 bg-gray text-white"
        >
          {localMenuItems.label}
          <HamburgerIcon className="w-4 h-4 ml-2" />
        </button>
        <LocalMobileMenu
          label={localMenuItems.label}
          homeLink={localMenuItems.url}
        >
          <LocalMenuOutput localMenuItems={localMenuItems} />
        </LocalMobileMenu>
      </div>
    </nav>
  )
}

export default LocalMenu
