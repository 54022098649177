import React from "react"

function ArrowIcon({ className }: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`fill-current ${className && className}`}
    >
      <path d="M6 0l12 12-12 12z" />
    </svg>
  )
}

export default ArrowIcon
