import React, { useState, useEffect, useMemo } from "react"
import isBrowser from "./isBrowser"

function useIsInViewport(ref: React.RefObject<HTMLElement>): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false)
  if (!isBrowser) return false
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  )

  useEffect(() => {
    if (ref.current) observer.observe(ref.current)

    return (): void => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export default useIsInViewport
