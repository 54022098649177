/**
 * Create a page for all wpPage nodes automatically via
 * filesystem routing: https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */
import * as React from "react"
import { graphql } from "gatsby"
import MainLayout from "../layouts/main"
import IWpPage from "../interfaces/IWpPage"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import Seo from "../components/seo"
import WordpressContent from "../components/wordpress-content"

interface IPageProps {
  data: {
    wpPage: IWpPage
  }
}

export default function Page(props: IPageProps): JSX.Element {
  const page = props.data.wpPage
  const { setCurrentPost } = React.useContext(UserInterfaceContext)

  // TODO: set currentPost with pageContext to see if it prevents null on load
  React.useEffect(() => {
    setCurrentPost(page)
  }, [props.data, setCurrentPost])

  const html =
    page?.content?.replace(
      /href="https:\/\/(www.georgiancollege.ca|www.gcblueprint.local|georgiangatsby.wpengine.com)\//gim,
      `href="/`
    ) || ``

  return (
    <>
      <Seo
        title={page?.seo?.title || page?.title}
        description={page?.seo?.metaDesc}
        jsonld={page?.seo?.schema?.raw}
      />
      <MainLayout breadcrumbs={page?.breadcrumbs}>
        {page?.content?.indexOf(`wp-block`) === -1 && (
          <h1 className="text-4xl mb-4 text-blue">{page?.title}</h1>
        )}
        {(page?.content?.indexOf(`wpb`) || -1) >= 0 ? (
          <div id="mainContent">
            <WordpressContent html={html} />
          </div>
        ) : (
          <WordpressContent html={html} />
        )}
      </MainLayout>
    </>
  )
}

// configure page, for example set defer to use DSG
// export async function config() {
//   // Optionally use GraphQL here
//   return ({ params }) => {
//     return {
//       defer: true,
//     }
//   }
// }

export const query = graphql`
  query PageContent($uri: String!) {
    wpPage(uri: { eq: $uri }) {
      id
      title
      content
      uri
      databaseId
      topParentId
      breadcrumbs
    }
  }
`
