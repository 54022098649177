import { useStaticQuery, graphql } from "gatsby"
import React, { useContext, useState } from "react"
import parse, {
  attributesToProps,
  DOMNode,
  domToReact,
} from "html-react-parser"
import Link from "./link"
import ArrowIcon from "../icons/arrow"
import IWpMenuItem from "../interfaces/IWpMenuItem"
import classNames from "../utils/classnames"
import isElement from "../utils/is-element"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import { Helmet } from "react-helmet"
import { useRef } from "react"

interface IGlobalNavProps {
  className?: string
}

interface IGlobalNavData {
  allWpMenuItem: {
    nodes: Array<IWpMenuItem>
  }
}

function GlobalNav({ className }: IGlobalNavProps): JSX.Element {
  const megaMenuRefs = useRef([])
  const {
    megaMenuActive,
    setMegaMenuActive,
    mobileMenuOpen,
    setMobileMenuOpen,
  } = useContext(UserInterfaceContext)
  const [hoverTimeout, setHoverTimeout] = useState(0)
  const data: IGlobalNavData = useStaticQuery(graphql`
    {
      allWpMenuItem(filter: { locations: { in: MEGA_MENU } }) {
        nodes {
          id
          order
          path
          url
          cssClasses
          label
          reusableBlock {
            node {
              content
            }
          }
        }
      }
    }
  `)

  const handleHover = (id: string): void => {
    setHoverTimeout(
      window.setTimeout((): void => {
        setMegaMenuActive(id)
      }, 300)
    )
  }

  return (
    <>
      {mobileMenuOpen && (
        <Helmet
          htmlAttributes={{
            class: `overflow-hidden h-[100vh] lg:overflow-auto lg:h-[unset]`,
          }}
          bodyAttributes={{
            class: `overflow-hidden h-[100vh] lg:overflow-auto lg:h-[unset]`,
          }}
        />
      )}
      <nav
        className={classNames(
          `bg-lightgray lg:bg-white top-0 lg:left-auto lg:top-auto lg:right-0 lg:w-auto lg:initial`,
          `fixed transition duration-300 overflow-y-scroll w-[80vw] top-0 left-0 bottom-0`,
          `lg:mt-4 lg:static lg:translate-x-[unset] lg:overflow-y-auto lg:transition-none lg:transform-none lg:w-full lg:top-[unset] lg:left-[unset] lg:bottom-[unset]`,
          mobileMenuOpen ? `translate-x-0 z-[9999]` : `-translate-x-[80vw]`
        )}
      >
        <ul className="lg:flex">
          {data.allWpMenuItem.nodes.map((node, i) => (
            <li
              ref={(el): HTMLLIElement | null => (megaMenuRefs.current[i] = el)}
              key={node?.id}
              className="group"
              onClick={(e): void => {
                if (node.id !== megaMenuActive) {
                  e.preventDefault()
                  e.stopPropagation()
                  setMegaMenuActive(node.id)
                  window.setTimeout((): void => {
                    megaMenuRefs.current[i].scrollIntoView({
                      behavior: `smooth`,
                      block: `start`,
                      inline: `nearest`,
                    })
                  }, 10)
                }
              }}
              onMouseEnter={
                mobileMenuOpen
                  ? undefined
                  : (): void => handleHover(node?.id || ``)
              }
              onMouseLeave={(): void => {
                window.clearTimeout(hoverTimeout)
                setMegaMenuActive(``)
              }}
            >
              <Link
                className={classNames(
                  `block px-3 py-6 xl:px-6 xl:py-6 font-semibold`,
                  megaMenuActive === node?.id
                    ? `bg-blue text-white`
                    : `text-black`
                )}
                to={megaMenuActive === node.id ? node?.url || `` : ``}
                title={node?.label}
              >
                {node?.label}
                <ArrowIcon className="transform rotate-90 w-3 inline-block ml-1 -mt-0.5" />
              </Link>
              <div
                className={classNames(
                  `lg:absolute z-10 lg:w-[100vw] left-0 min-h-[300px] bg-blue text-white`,
                  megaMenuActive === node?.id ? `block` : `hidden`
                )}
              >
                <div className="mega-menu container mx-auto px-8 py-4 text-left">
                  {parse(node?.reusableBlock?.node?.content || ``, {
                    replace: (domNode: DOMNode): JSX.Element | DOMNode => {
                      if (isElement(domNode)) {
                        if (
                          domNode.name === `a` &&
                          domNode.attribs.href.indexOf(`/`) === 0
                        ) {
                          const to = domNode.attribs.href
                          delete domNode.attribs.href
                          return (
                            <Link
                              to={to}
                              {...attributesToProps(domNode.attribs)}
                            >
                              {domToReact(domNode.children)}
                            </Link>
                          )
                        }
                      }
                      return domNode
                    },
                  })}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}

export default GlobalNav
