import React, { useState, useEffect } from "react"
import { Account, UserAgentApplication } from "msal"
import { getUserDetails } from "../utils/graph-service"

interface IAuthContextProviderProps {
  children: React.ReactNode
}

interface IUser extends Account {
  gotUserProfile: boolean
  photo?: string
  givenName?: string
  displayName?: string
}

interface IAuthError {
  message: string
  debug: string
}

interface IAuthContext {
  isAuthenticated: boolean
  user: IUser | null
  error: IAuthError | null
  login: () => void
  logout: () => void
  loginWithConsent: () => void
  loginWithAccount: () => void
}

const config = {
  appId: `292a1d0f-b27b-476b-95eb-8ef142befa81`,
  scopes: [`user.read`],
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  user: null,
  error: null,
  login: () => {},
  logout: () => {},
  loginWithConsent: () => {},
  loginWithAccount: () => {},
})

export const AuthContextProviderSsr = ({
  children,
}: IAuthContextProviderProps): JSX.Element => (
  <AuthContext.Provider
    value={{
      isAuthenticated: false,
      user: null,
      error: null,
      login: (): void => {},
      logout: (): void => {},
      loginWithConsent: (): void => {},
      loginWithAccount: (): void => {},
    }}
  >
    {children}
  </AuthContext.Provider>
)

export default function AuthContextProvider({
  children,
}: IAuthContextProviderProps): JSX.Element {
  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      authority: `https://login.microsoftonline.com/da9a94b6-4681-49bc-bd7c-bab9eac0ad3c`,
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    },
    cache: {
      cacheLocation: `localStorage`,
      storeAuthStateInCookie: true,
    },
  })

  const [user, setUser] = useState<IUser | null>({
    gotUserProfile: false,
    ...userAgentApplication.getAccount(),
  })
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(user !== null)
  const [error, setError] = useState<IAuthError | null>(null)

  const handleError = (error: IAuthError): void => {
    setIsAuthenticated(false)
    setUser(null)
    setError(error)
    console.error(error)
  }

  const getUserProfile = async (): Promise<void> => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token

      const accessToken = await userAgentApplication.acquireTokenSilent({
        scopes: config.scopes,
      })

      if (accessToken) {
        // Get the user's profile from Graph
        const user = await getUserDetails(accessToken)
        setIsAuthenticated(true)
        setUser({ gotUserProfile: true, ...user })
        setError(null)
        // console.log({ user })
      }
    } catch (err) {
      let error = { message: ``, debug: `` }
      if (typeof err === `string`) {
        const errParts = err.split(`|`)
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err, debug: `` }
      } else {
        error.message = err.message
        error.debug = JSON.stringify(err)
      }
      handleError(error)
    }
  }

  const loginPrompt = async (prompt: string): Promise<void> => {
    try {
      await userAgentApplication.loginPopup({
        scopes: config.scopes,
        prompt,
      })
      await getUserProfile()
    } catch (err) {
      let error: IAuthError = { message: ``, debug: `` }

      if (typeof err === `string`) {
        const errParts = err.split(`|`)
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err, debug: `` }
      } else {
        error.message = err.message
        error.debug = JSON.stringify(err)
      }

      handleError(error)
    }
  }

  const login = (): Promise<void> => loginPrompt(`none`)

  const loginWithConsent = (): Promise<void> => loginPrompt(`consent`)

  const loginWithAccount = (): Promise<void> => loginPrompt(`select_account`)

  useEffect(() => {
    if (user && !user.gotUserProfile) {
      // Enhance user object with data from Graph
      getUserProfile()
    }
    if (user === null) {
      login()
    }
  }, [user])

  const logout = (): void => {
    userAgentApplication.logout()
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        error,
        login,
        logout,
        loginWithConsent,
        loginWithAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
