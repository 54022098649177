import { Link } from "gatsby"
import React, { useContext, useEffect, useRef } from "react"
import ExperienceLogo from "../svg/experience-logo"
import GlobalNav from "./global-nav"
// import GlobalMenu from "./global-menu"
import HeaderButtons from "./header-buttons"
import LocalMenu from "../components/local-menu"
import useIsInViewport from "../utils/useIsInViewport"
import { UserInterfaceContext } from "../context/UserInterfaceContext"

interface IHeaderProps {
  siteTitle: string
}

const Header = ({ siteTitle }: IHeaderProps): JSX.Element => {
  const headerRef = useRef<HTMLDivElement>(null)
  const isInView = useIsInViewport(headerRef)
  const { setHeaderIsInViewport } = useContext(UserInterfaceContext)

  useEffect(() => {
    setHeaderIsInViewport(isInView)
  }, [isInView])
  return (
    <header ref={headerRef} className="bg-white">
      <nav className="main-container" aria-label="Top">
        <div className="w-full py-3 lg:py-6 lg:pb-0 flex items-center justify-between">
          <Link
            className="flex flex-col justify-end mt-4"
            to="/"
            title={siteTitle}
          >
            <span className="sr-only">Georgian College</span>
            <ExperienceLogo className="w-32 lg:w-56" />
          </Link>
          <div className="flex flex-col lg:text-right">
            <HeaderButtons className="flex justify-end container mx-auto" />
            <div className="ml-10 space-x-8 lg:block ">
              <GlobalNav className="bg-white top-0 -left-full lg:left-auto lg:top-auto lg:right-0 w-full lg:w-auto lg:initial mt-4" />
              {/* <GlobalMenu className="bg-white top-0 -left-full lg:left-auto lg:top-auto lg:right-0 w-full lg:w-auto lg:initial mt-4" /> */}
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
