import React, { Fragment, useContext } from "react"
import { Dialog, Transition } from "@headlessui/react"
import CloseIcon from "../icons/close"
import CartIcon from "../icons/cart"
import { PtsCartContext } from "../context/PtsCartContext"
import PtsCartList from "./pts-cart-list"

const PtsCart = (): JSX.Element => {
  const { ptsCartOpen, setPtsCartOpen, sections } = useContext(PtsCartContext)
  return (
    <Fragment>
      {sections.length > 0 && (
        <button
          onClick={(): void => setPtsCartOpen(true)}
          className="flex items-center gap-1 relative md:bg-blue md:hover:bg-green md:text-white md:p-3 ml-2"
        >
          <CartIcon className="h-6 w-6 block" />
          {` `}
          <div className="hidden md:block">View Cart</div>
          <div className="absolute -top-2 -right-4 inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-orange rounded-full">
            <span>{sections.length}</span>
          </div>
        </button>
      )}
      <Transition.Root show={ptsCartOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden"
          open={ptsCartOpen}
          onClose={setPtsCartOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col pb-6 bg-lightgray shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6 bg-green py-4">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          Your Cart
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className=""
                            onClick={() => setPtsCartOpen(false)}
                          >
                            <span className="sr-only">Close cart</span>
                            <CloseIcon
                              className="text-white h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="m-4 flex">
                      {/* Replace with your content */}
                      <div className="flex-1">
                        {sections.length > 0 ? (
                          <>
                            <PtsCartList sections={sections} />
                            <a
                              href={`https://sis-ssb.georgiancollege.ca:9110/GEOR/wwptweb.p_gc_login`}
                              target="_blank"
                              className="block text-center bg-blue px-4 py-2 text-white mt-4"
                              rel="noreferrer"
                            >
                              Checkout
                            </a>
                          </>
                        ) : (
                          <div>Your cart is empty.</div>
                        )}
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  )
}

export default PtsCart
