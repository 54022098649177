import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FooterContent from "./hardcoded-footer-content"

const Footer = (): JSX.Element => (
  <footer className="text-white">
    <div className="bg-blue pt-8 pb-4">
      <div className="container px-4 mx-auto">
        <StaticImage
          src="../images/georgian-college-logo-2017.svg"
          alt="Georgian College"
          layout="fixed"
          width={165}
        />
      </div>
      <div>
        <FooterContent />
      </div>
    </div>
    <div className="bg-gray">
      <div className="container px-4 text-sm lg:text-base mx-auto py-4">
        One Georgian Dr., Barrie ON L4M 3X9 | 705.728.1968 |
        inquire@georgiancollege.ca
      </div>
    </div>
    <div className="bg-white text-black">
      <div className="container text-sm lg:text-base px-4 mx-auto py-4">
        Copyright © 2021 Georgian College.
      </div>
    </div>
  </footer>
)

export default Footer
