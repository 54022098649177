import React from "react"

function ExperienceLogo({
  className,
}: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1773.5 635"
      style={{ enableBackground: `new 0 0 1773.5 635` }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`.st0{display:none;}
	.st1{display:inline;}
	.st2{fill:url(#SVGID_1_);}
	.st3{fill:url(#SVGID_2_);}
	.st4{fill:url(#SVGID_3_);}
	.st5{fill:url(#SVGID_4_);}
	.st6{fill:url(#SVGID_5_);}
	.st7{fill:url(#SVGID_6_);}
	.st8{fill:url(#SVGID_7_);}
	.st9{fill:url(#SVGID_8_);}
	.st10{fill:url(#SVGID_9_);}
	.st11{fill:url(#SVGID_10_);}
	.st12{fill:url(#SVGID_11_);}
	.st13{fill:#005C97;}
	.st14{fill:#59AD40;}
	.st15{fill:url(#SVGID_12_);}
	.st16{fill:#004B87;}
	.st17{fill:#64A70B;}`}
      </style>
      <g id="New_Logo" className="st0">
        <g className="st1">
          <g>
            <g>
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="-524.44"
                y1="-1230.3422"
                x2="391.337"
                y2="-1230.3422"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st2"
                d="M-581.8,1943.9c0-0.1-0.2-0.2-0.5-0.2c-1.8,0.2-8.3,3.6-9.2,4c-4.2,1.8-8.4,4.1-12.5,6.1
					c-3.6,1.8-7.3,3.4-11,4.6c-0.9,0.3-2.8,0.6-2.8,0c0-0.7,1.2-1.5,2.5-2.2s2.8-1.3,3.1-1.4c3.5-1.9,7-3.7,10.6-5.5
					c7.8-4,15.8-7.5,24-10.8c0.4-0.1,2.1-1,3.2-1.2c0.5-0.1,0.9,0,0.9,0.3c0.1,1.1-2.3,2.3-2.9,2.7c-0.5,0.4-2.5,1.1-2.3,2
					c0.1,0.6,0.5,0.8,1.1,0.7c1.4-0.2,3.8-1.7,4.6-2.1c1.7-0.8,3.6-1.6,5.3-2.7c1.6-1,3-2.2,3.8-4c0.9-2,0.1-2.1-0.8-3.8
					c-0.5-1-0.9-2.5-0.3-3.6c0.8-1.4,3.5-1.6,3.3-3.5c-0.1-0.6-0.3-0.9-0.8-0.8c-0.2,0-0.4,0.1-0.7,0.2c-3.7,1.5-7.1,4.4-10.6,6.4
					c-3.7,2.1-7.5,3.9-11.3,6c-3.2,1.8-6.7,2.4-10,4c-3.8,1.8-7.4,3.9-11,5.9c-3.1,1.7-6.2,3.3-9.5,4.8c-3.8,1.7-7.7,3.4-11.6,4.9
					c-1.3,0.5-2.5,0.4-3.6,0.5c-1.6,0.2-3.7,2-5.5,2.8c-3.9,1.8-7.7,3.7-11.6,5.5c-4.2,2-8.3,3.9-12.6,5.6c-3.8,1.6-7.5,2.5-11.5,3
					c-0.9,0.1-1.8,0.2-2.7,0.3c-1.1,0.1-2.2,0.3-3.2,0.7c-1.6,0.6-2.8,1.5-4.5,1.8c-3.5,0.7-7.1,0.9-10.7,1.3c-3,0.4-4.8,1.2-7.2,2
					c-0.5,0.2-1.1,0.3-1.7,0.4c-1.1,0.1-2.3,0.1-3.4,0.2c-4.1,0.2-8.4,0.8-12.5,0.4s-7.9-2.2-11.7-3.6c-3.5-1.3-6.9-2.2-9-5.5
					c-1.2-1.9-1.9-4.1-2.2-6.3c-0.2-1.3-0.2-2.7,0-4.1c0.2-1.6,0-3.2,0.2-4.8c0.1-1.2-0.1-2.6,0.2-3.7c0.2-0.5,0.8-1.1,1.1-1.7
					c0.4-0.9,0.5-2.3,0.6-3.3c0.3-1.7,0.2-3.5,0.6-5.2c0.5-2.1,1.7-3.9,2.2-6c0.4-1.6,0.5-3.2,0.8-4.8c0.8-4,2.6-7.8,4-11.6
					c1.5-3.9,2.8-7.8,4.4-11.7c0.7-1.7,1.4-3.4,2.1-5.1c0.7-1.7,1.2-3.9,2.4-5.3c2.2-2.6,6.1-4.3,9.2-5.5c4-1.6,8-3.1,12-4.7
					c4.2-1.7,8.5-3.4,12.6-5.3c3.6-1.7,7-3.5,10.8-4.6c4.3-1.2,8.6-2.1,12.9-3.5c4-1.3,8.1-2.6,12.2-3.8c2.2-0.7,4.5-1.2,6.7-1.9
					c1.6-0.6,2.4-1.5,3.8-2.4c2.6-1.8,6-2,8.9-3.2c1-0.4,7.4-2.7,7.2-4.6c-0.1-1-1.7-1-2.4-0.9c-1.2,0.1-2.8,0.6-3.5,0.7
					c-1.6,0.2-3.3,0.5-4.9,1c-1.3,0.4-2.7,1.1-4.1,1.2c-0.5,0.1-0.9,0.1-1.4,0c-1-0.1-2.8-0.7-2.9-1.9c-0.1-1,2.7-1.8,3.8-2
					c2.2-0.4,4.3-0.9,6.5-1.3c2.2-0.5,4.4-1,6.7-1.5c1.6-0.4,3.7-0.4,4.8-1.7c0.4-0.4,0.8-1.1,0.8-1.7c-0.1-0.7-0.5-0.9-0.5-1.4
					c-0.1-1.1,2.4-2.3,2.2-3.7c-0.1-0.8-2-0.8-4.3-0.5c-2.6,0.3-5.8,1-7.2,1.1c-3.6,0.4-7.6,0.2-11.3,0.6c-1.9,0.2-3.5,1-5.3,1.5
					c-2.2,0.6-4.5,0.9-6.7,1.3c-4.1,0.7-7.9,1.8-11.8,3.1c-3.2,1.1-6.9,2.9-10.4,3.3c-0.7,0.1-1.4,0.1-2.1,0.1
					c-1.2-0.1-2.4,0-3.5,0.1c-2.2,0.3-4.4,0.8-6.6,1.4c-2.1,0.5-4.5,1.5-6.7,1.7c-1.6,0.2-2.2-0.1-2.3-0.8c0-0.4,0.1-0.9,0.2-1.5
					c1-3.5,3.2-6.3,4-9.9c0.5-1.9,0.4-4,1.3-5.8c0.7-1.4,1.9-2.7,2.8-4c2.2-3.5,4.2-7.1,6.3-10.7c2.2-3.6,4.7-7.2,7-11
					c2-3.3,3.9-6.7,5.2-10.3c2.7-7.4,7.4-14.1,11.4-20.9c1.8-3.1,3.6-7.5,6.5-9.7c2.8-2.1,7.2-3,10.4-4.3c4.1-1.6,8.2-3.5,12.4-4.8
					c3.5-1,7.1-1.4,10.5-2.7c3.7-1.4,6.9-3.1,10.8-4c4-1,8-2.3,11.9-3.6c7.8-2.5,15.8-4,23.6-6.1c4.2-1.1,8.1-2.1,12.4-2.9
					c1.2-0.2,10.1-1.6,9.9-3.1c-0.1-1-2.2-1.4-4.8-1.4c-3.9-0.1-8.9,0.3-9.7,0.4c-1.7,0.2-3.8,0.5-5.4,0.7c-2.7,0.3-5.3,0.9-8,1.2
					c-1.2,0.1-2.3,0.2-3.5,0.2c-0.9,0-3.1,0.3-3.2-0.4c0-0.2,0.1-0.4,0.4-0.7c0.6-0.6,1.9-0.8,3.2-1c1.2-0.1,2.6-0.2,3.5-0.3
					c3-0.4,5.8-1.8,8.8-2.4c2.7-0.5,5.4-0.6,8.1-0.8c2.7-0.2,5.3-0.5,7.9-0.1c2.2,0.3,4.3,1.3,6.5,1.4c0.5,0,1,0,1.5,0
					c1.5-0.2,2.5-0.9,2.2-2.5c-0.2-1.3-0.8-2.4-1-3.6s0.1-2.3-0.1-3.5c-0.3-2.3-2-2.6-4-3.1c-2.4-0.6-4.7-1.3-7.2-1.7
					c-5.2-0.9-10.9-0.3-16.1-0.5c-2.5-0.1-4.3,0.3-8.2,0.7c-1,0.1-2.1,0.2-3.2,0.3c-1.4,0.2-2.8,0.4-4.1,1c-0.9,0.4-1.4,0.8-2.1,0.9
					c-2,0.2-2.1-0.6-3.7-0.4c-1.4,0.2-5.4,1.3-8.1,1.8c-5.3,1-10.7,1.7-15.8,3.4c-5.2,1.7-10.5,3.2-15.8,4.5
					c-2.8,0.7-5.6,1.4-8.4,2.3c-1.8,0.6-4.4,2.1-6.3,0.9c-3.1-1.8-6-5.2-9.4-4.8c-4.1,0.5-6.3,10.3-10.1,13.4c-2,1.7-5,2.4-7.4,3.5
					c-2.3,1-3.9,2.5-6,3.9c-4.7,3.1-10.4,5.2-15.4,7.8c-2.4,1.3-4.8,2.4-7.3,3.4c-1,0.4-1.9,1-2.8,1.3c-0.5,0.2-1.1,0.2-1.7,0.3
					c-2,0.2-2,2.4-1.7,4.2c0.4,3.1,0.5,6-0.3,9c-0.5,1.9-1.3,3.2-1.1,4.6c0.1,0.6,0.4,1.3,1,2.1c1.4,1.7,2.6,3.2,4.7,4.2
					c1.3,0.6,2.4,0.7,3.5,0.5c0.9-0.1,1.9-0.4,2.9-0.6c0.6-0.1,1.6-0.4,2.5-0.5c1.1-0.1,2.1,0,2.3,0.9c0.2,1.8-2.6,4.7-3.7,6.4
					c-1.3,2-1.7,4.2-2.2,6.5c-0.6,2.3-1.7,3.9-2.7,6s-1.6,4.3-2.6,6.4c-2.4,4.5-4.4,9.1-6.7,13.5c-2.3,4.5-4.9,8.9-7.1,13.5
					c-2.4,5-4.6,10.1-6.9,15.2c-0.3,0.8-0.7,1.5-1,2.3c-1.6,3.8-3.2,7.7-7.2,9.2c-2.2,0.8-4.6,1.7-6.8,2.7c-2.3,1-4.5,2.2-6.3,3.6
					c-0.7,0.6-1.3,0.8-1.8,0.9c-1.2,0.1-2-0.8-3.8-0.9c-0.4,0-0.8,0-1.2,0c-1.8,0.2-4.1,1.1-5.6,1.5c-1.3,0.4-1.7,1-1.6,1.7
					c0.1,0.9,0.9,2.1,1,3.4c0.4,3.2-2,3.6-1.6,6.4c0.5,4.4,2.9,7.8,5.5,11.4c1.2,1.7,2.2,4,4.1,4.8c1.6,0.7,2.7,1.3,2.9,2.6
					c0.1,0.5,0.1,1.2,0,2c-0.5,2.9-1.3,5.5-2.1,8.1c-0.6,2.1-1.3,4.2-1.7,6.5c-1.1,5.4-1.4,10.8-1.6,16.3c-0.1,3.1-0.5,6.2-0.8,9.2
					c-0.3,3.2-0.5,6.4-0.1,9.6c0.6,4.8,2,9.3,3.5,13.9c0.7,2.4,1.4,4.8,1.7,7.2c0.2,1.9,0,4.1,0.3,6.2c0.1,0.9,0.3,1.8,0.7,2.6
					c0.9,2,3.3,3.1,4.8,4.6c1.7,1.7,3.1,1.9,5.4,2.2c1.7,0.3,3.4,1,5,1.9c2.9,1.6,5.6,3.8,8.3,5.3c2.4,1.4,4.7,3,7.5,3.7
					c2.4,0.6,4.9,0.7,7.4,0.8c3,0.1,6.6,0.2,9.8,0.3c2.7,0,5.1,0,6.6-0.1l0,0c3.8-0.5,8.5-3,11.6-4c4.1-1.3,8.1-2.5,12.2-3.6
					c1.9-0.5,3.5-1.2,5.8-1.5c1.1-0.2,2.1-0.5,2-1.3c-0.2-1.2-1.4-0.4-1.6-1.5c-0.1-0.6,0.6-1.2,0.8-1.6c0.6-1.2,0.9-2.5,2-3.4
					c1.2-1,2.9-1.7,4.7-2.2c2-0.6,4-1,5.6-1.5c2.3-0.8,4.6-1.6,6.9-2.3c2.1-0.7,4.3-1.5,6.4-2.2c3.8-1.3,7.5-2.7,11.1-4.6
					c1.9-1,3.8-2.1,5.6-3.2c0.7-0.4,4.4-2,4.3-3c-0.2,0-0.4,0-0.6,0c-2.9,0.3-5.3,2.6-8.2,2.9c-0.8,0.1-4.9-0.7-5-1.7
					c-0.1-0.9,0.4-1,0.9-2c-0.2,0-0.5,0-0.8,0c-1.6,0.2-4.3,1.3-5,2.2c0.9,0.5,2.4-0.2,2.5,0.8c0.2,1.5-2.8,2.2-3.8,2.6
					c-0.6,0.2-1.1,0.4-1.5,0.4c-1,0.1-1.6-0.3-3-0.9c-1.2-0.5-2.4-0.3-3.6-0.6c-0.8-0.2-1.1-0.5-1.2-0.7c-0.1-0.4,0.9-0.9,2-1.2
					c1.5-0.5,3-0.8,4.5-1.4c1.6-0.7,2.9-2,4.3-3.2c1.3-1.1,2.7-2.2,4.1-2.4c1.2-0.1,2.1,0,3-0.1c0.5-0.1,1.1-0.2,1.7-0.7
					c0.6-0.5,1.2-1.3,1.9-1.7c0.8-0.5,1.8-0.5,2.7-0.9c1.6-0.7,3.1-1.4,4.6-2.2c2.1-1,4.1-2.1,6.3-3c3.7-1.6,7.5-3.2,11.2-4.9
					c3.9-1.8,7.9-3.5,11.8-5.4C-589.7,1948.5-581.6,1945.6-581.8,1943.9z M-744.3,1939.4c-0.5,2-0.8,4.7-1.5,6.6
					c-0.2,0.5-0.5,0.8-0.8,0.9c-0.4,0.1-0.8-0.2-0.9-0.9c-0.2-1.3,0.6-4.1,1.3-6.8c0.5-2,1.1-3.9,1.4-5.3c0.8-4.5,1.6-8.9,2.8-13.3
					c1.1-4.2,1.8-8.6,3.3-12.8c0.4-1,2.9-7.9,5.1-8.2c0.8-0.1,1.1,0.3,1.2,0.9c0.2,1.5-1.3,4.5-1.5,5.3c-0.7,2.1-1.3,4.3-2,6.4
					c-1.5,4.7-3.1,9.3-4.6,14C-741.9,1930.6-743.2,1935-744.3,1939.4z"
              />

              <linearGradient
                id="SVGID_2_"
                gradientUnits="userSpaceOnUse"
                x1="-502.6001"
                y1="-1284.7881"
                x2="368.8696"
                y2="-1284.7881"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st3"
                d="M-561.8,1913.2c-0.9,0.1-0.6,4.7-0.5,5.8c0.2,1.9,0.4,2.7,0.9,6.6c0.2,1.7,0.7,2.8,1.2,2.8
					c0.3,0,0.6-0.4,0.7-1.7c0-0.4,0-0.9,0-1.3c-0.2-1.9-0.8-2.8-1-4.7c-0.2-1.4,1.7-4.1,1.5-5.6
					C-559.2,1914.1-560.4,1913-561.8,1913.2z"
              />

              <linearGradient
                id="SVGID_3_"
                gradientUnits="userSpaceOnUse"
                x1="-593.3"
                y1="-1392.5"
                x2="-593.3"
                y2="-1392.5"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st4"
                d="M-593.3,2028.5L-593.3,2028.5L-593.3,2028.5z"
              />

              <linearGradient
                id="SVGID_4_"
                gradientUnits="userSpaceOnUse"
                x1="-412.6156"
                y1="-1380.8373"
                x2="6155.833"
                y2="-1380.8373"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st5"
                d="M-488.7,2001.5c0-0.4-0.2-0.6-0.6-0.5c-0.7,0.1-13.7,30.5-13.6,31.2c0.1,0.3,0.3,0.5,0.6,0.5
					C-501.6,2032.6-488.6,2002.5-488.7,2001.5z"
              />

              <linearGradient
                id="SVGID_5_"
                gradientUnits="userSpaceOnUse"
                x1="-502.3"
                y1="-1396.7"
                x2="-502.3"
                y2="-1396.7"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st6"
                d="M-502.3,2032.7L-502.3,2032.7L-502.3,2032.7z"
              />

              <linearGradient
                id="SVGID_6_"
                gradientUnits="userSpaceOnUse"
                x1="-479.092"
                y1="-1359.6597"
                x2="391.9401"
                y2="-1359.6597"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st7"
                d="M-485.3,1994c0-0.3-0.2-0.5-0.7-0.5c-1,0.2-2,2.7-1.8,3.7c0.1,0.4,0.3,0.7,0.7,0.6
					C-486,1997.7-485.2,1994.7-485.3,1994z"
              />

              <linearGradient
                id="SVGID_7_"
                gradientUnits="userSpaceOnUse"
                x1="-486.4471"
                y1="-1307.9297"
                x2="460.6389"
                y2="-1307.9297"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st8"
                d="M-342.7,1831.5c-1.9-2-2.7-4.5-5-6c-1.7-1.2-2.7-2.6-4.3-3.9c-3.2-2.6-8.3-1.4-12.2-2
					c-1.8-0.3-3.5-0.3-5.2-0.1c-0.2,0-0.4,0-0.7,0.1c-3.1,0.5-6,1.7-9,3.3c-0.7,0.4-2.4,1.8-3.5,2c-0.6,0.1-1-0.1-1.1-0.9
					c-0.2-1.1,1.7-2.5,2.1-3.4c0.4-1,0.4-2.2,0.5-3.3c0.1-1.1,0.1-2.2-0.1-3.2c0-0.1,0-0.3-0.1-0.5c-0.6-4-3-7-6.5-9.9
					c-1.7-1.4-3.9-2.7-5.7-3.7c-1.9-1.1-3.6-0.9-5.9-1c-1.1,0-2.1,0-3.1,0.1c-1.3,0.2-3.4,1.4-4,2.2c-1.4,1.8-2.3,4.1-3.2,6.2
					c-2.2,4.7-3.6,9.7-5.3,14.6c-1.9,5.3-4.1,10.6-5.8,16c0-0.1-1.4,2.7-1.3,4.1c0,0.6,0.3,0.9,1,0.8c1.5-0.2,2.6-4.2,3.5-4.7
					c0.1,0.1,0.1,0.3,0.1,0.4c0.2,1.3-0.9,3.4-1.6,4.3c-1,1.3-3.2,1.1-3.9,2.2c-0.3,0.5-0.5,0.8-0.4,1c0.2,0.9,1.9,1.1,2.1,2
					c0.1,0.3,0,0.6-0.6,1.1c-0.3,0.3-0.9-0.1-1.6,0s-1.1,0.5-1,1c0.1,0.8,1,1.3,1.1,2c0.1,0.4-0.1,0.6-0.4,0.6s-0.8-0.1-1.1,0
					c-0.5,0.1-1.8,0.6-1.6,1.5c0.2,1.2,2.1,1.7,2.3,2.7c0.1,0.7-0.7,1.6-1,2.2c-0.7,1.5-1.4,3-2,4.5c-1.1,2.5-1.5,4.9-2.6,7.4
					c-1.5,3.4-3.1,6.2-4.7,9.7c-1.5,3.3-2.3,6.3-3.7,9.8c-2.4,5.8-5.3,12.2-7.7,17.8c-2.9,6.8-5.8,13.8-8.6,20.9
					c-2,4.9-4,9.9-5.9,14.8c-0.8,0.9-1.5,2-2.1,3.2c-1.2,2.5-2.1,5.2-3.5,7.5c-1.8,3-4.2,7.1-7.2,9.9c0,0,0-0.1-0.1-0.1
					c-0.3,0-0.6,0.5-1,1c-0.9,0.7-1.8,1.4-2.8,1.8c-1.6,0.6-3.7,1-5.9,1.3c-3.8,0.4-8.1,0.4-11.1,0c-4.2-0.5-9.4-1.6-13-3.8
					c-4.1-2.5-7.6-6.3-11-9.6c-4.1-3.9-6.8-8.8-9.4-13.8c-1.1-2.2-2.1-4-2.3-5.8c-0.2-1.5,0.2-3.1,1.6-4.8c2.6-3.4,5.3-6.8,7.9-10.1
					c2.9-3.7,5.7-7.3,8.6-11c3.4-4.4,7.6-8.8,11.2-13.1c2.7-3.2,4.8-6.4,7.4-9.6c2-2.5,4.4-5,6.6-7.4c1.7-1.9,3.4-3.9,5.2-5.6
					c1.8-1.7,4-2.5,5.9-4.1c3.3-2.7,4.3-7.5,7.2-10.5c3.3-3.4,6.1-6.7,6.6-11.8c0.1-0.6,0-1.1,0-1.6c-0.2-1.4-0.8-2.2-1.6-3.7
					c-0.4-0.6-0.5-2.1-0.9-3.3c-0.3-0.9-0.7-1.6-1.5-1.5c-1,0.1-2.9,1.2-3.8,1.3c-1.3,0.1-2.6-0.2-3.9-0.1
					c-4.2,0.5-7.9,8.1-10.8,11.7c-7.1,8.8-14.3,17.6-21.3,26.5c-6.7,8.5-13.4,17-19.9,25.7c-1.8,2.4-3.6,4.8-5.5,7.1
					c-1.3,1.7-2.7,5-4.7,5.3c-1.3,0.2-1.1-1.6-1.3-3.2c-0.2-1.7-0.8-3.4-1.2-5.2c-0.5-2.5-0.9-5-1.2-7.4c-0.5-3.7-0.7-7.4-0.9-11.1
					c-0.2-3.6-0.2-7.3-0.3-11c-0.1-3.3-0.4-6.5-0.2-9.8c0.2-2.3,0.8-4.7,0.7-7c0-2-0.6-3.7-0.3-5.7c0.1-0.9,0.3-1.8,0.2-2.5
					c-0.1-1-0.8-1.7-2.8-1.4c-1.6,0.2-3.6,0.9-5.2,1.1c-2.6,0.3-4.1-0.3-6.2-0.1c-5.1,0.6-8,4.1-11.9,7c-3.4,2.5-4.7,6.3-5.3,10.3
					c-0.6,4.3-0.2,8.9-0.5,12.7c-0.2,3,0.6,6.2,0.8,9.3c0.1,2.4,0,4.9,0.3,7.3c0.1,0.8,0.3,2.8,1.1,2.7c1-0.1,1.2-1.5,1.1-3
					c0-1.7-0.4-3.6-0.3-4.5c0.1-1.2,0.7-3.5,1.6-3.6c0.4-0.1,0.8,0.2,1.3,1.1c0.3,0.5,0.5,1.2,0.6,2c0.2,1.8,0,4.1,0.2,5.6
					c0,0.5,0.1,1,0.2,1.5c0.2,1.8,0.5,3.5,0.9,5.3c0,0.2,0.1,0.5,0.1,0.8c0.1,0.6,0.1,1.3,0.2,2c0.2,1.2,0.4,2.5,0.9,3.1
					c0.8-1,0.8-2.4,0.6-3.8c-0.5-3.7,0.4-3.9,1.6-5.8c0.9-1.4,1.1-3.3,1-5.4c-0.1-2.7-0.5-5.7-0.2-7.7c0.2-1,0.4-3.3,1.5-3.4
					c1.3-0.1,1.8,2.8,1.9,3.7c0.4,3.3,1.1,12.4,1.9,19.2c0.2,1.6,0.9,5.9,1.1,7.1c0.2,1.5-0.1,3,0.1,4.5c0.4,2.9,2.3,5.4,2.8,8.4
					c0.2,1,0.5,2.4,0.7,3.8c0.1,1.1,0.2,2.1-0.1,2.9c-0.2,0.7-1.2,2-1.8,2.4c-0.3,0.3-1.1,0.2-1.6,0.7c-1.1,1.3,0,3.6-1.2,3.7
					c-0.7,0.1-1.5-1.4-2.2-3.3c-0.6-1.6-0.7-3.7-0.9-5.3c-0.2-1.4-0.4-2.5-1.2-2.4s-0.9,1.8-0.8,3c0.2,1.3,0.5,2.7,0.7,3.5
					c0.2,1.3,0.9,2.6,1,3.8c0.1,0.5-0.3,1.6-0.2,2.7c0.1,1.2,1.4,0.5,1.5,1.3c0,0.4-0.9,1.4-1,1.6c-1.6,2.3-3.1,4.4-4.6,6.5
					c-2.7,3.8-5.4,7.5-8.3,11.2c-2.7,3.4-5.3,6.9-7.8,10.3c-2.7,3.7-5.4,7.5-8,11.3c-2.1,3.1-5.2,5.8-6.7,9.2
					c-1.3,2.9-2.3,6.1-3.3,9.2c-1,2.9-3,8.1-2.8,10.4c0.1,0.7,0.3,1.1,0.9,1c1.2-0.1,2.1-2.8,3.3-3c0.2,0,0.4,0.1,0.4,0.4
					c0.2,1.5-2.9,7.3-3,8.6c0,0.8,0,1.8,0.1,2.8c0.3,2.2,2.6,4.1,3.9,4c3.7-0.4,5.7-5.5,6.8-8.3c1.1-2.7,2.9-4.9,4.6-7.2
					c4.1-5.6,7.1-11.7,10.2-17.8c3.4-6.8,6.7-13.5,11.4-19.6c2.6-3.4,5-6.8,7.5-10.3c0.2-0.3,2.1-4.5,3.9-4.7
					c1.1-0.1,1.6,0.7,2.2,1.7c0.5,1,1,2.1,1.9,2.6c1.4,0.7,2.1,0.3,3.5,1.4c0.7,0.6,5.7,5.3,5.8,6.4c0,0.2-0.1,0.4-0.2,0.4
					c-1.1,0.1-5.2-5.2-6.5-5c-0.2,0-0.4,0.3-0.4,0.6c0.1,1.1,2.5,3.1,2.9,3.4c0.5,0.5,1.8,3.7,2.5,3.6c0.4,0,2.1-1.6,3-1.7
					c1.1-0.1,2,0.6,2.1,1.5c0.2,2-0.3,3-0.3,3.4c0,0.2,0.3,0.4,1.1,1c1.5,1.1,4.2,1.7,6,2.2c2.7,0.8,5.4,1.2,8.2,1.4
					c3.3,0.2,6.7,0,10.1-0.4c1.1-0.1,5.5-0.5,8-0.8s5.2-0.5,8-0.8c2.9-0.3,5.8-0.8,8.7-1.8c0.8-0.3,1.5-0.7,2.3-1.1
					c-1.8,3.7-4.4,9.3-4.3,10.4c0,0.2,0.1,0.4,0.3,0.4l0,0c0.5-0.1,3.6-5.8,6.5-11.9c1.5-0.8,3.1-1.6,4.6-2.5
					c-0.2,1.1-0.4,2.1-0.9,3.3c-0.9,1.9-1.8,3.7-2.7,5.5c-1.4,2.8-3.4,9.2-4.2,9.3c-0.4,0.1-0.8-0.4-1.3-0.3s-0.5,0.9-0.7,1.4
					c-0.7,1.6-1.6,4-2.4,5.8c-0.7,1.6-13.8,30.9-14.4,32.5c-1,2.5-1.9,5.7-3,8.2c-1.8,4.5-3.7,9-5.5,13.6
					c-0.5,1.3-3.3,10.1-4.6,10.4c-0.1,0-0.2-0.2-0.2-0.4c-0.3-1.9,9.2-26.3,9-27.4c0-0.2-0.2-0.4-0.5-0.4c-1.8,0.3-3.9,7.9-4.6,9.2
					c-2.2,4.2-3.2,8.9-4.9,13.3c-1.3,3.4-4,5.2-4.2,9c0,0.8-0.1,2.1,0,3.2c0.5,2.9,2.9,1.9,4.3,3.9c1.3,1.9,0.7,5.1,2.2,6.9
					c0.8,1,2.1,0.9,3.1,1.7c1.5,1.4,1.4,3,3.8,3.7c1.7,0.5,3.5,0.5,5.3,1.1c0.8,0.3,1.6,0.3,2.2,0.2l0,0c3-0.5,4-4.6,5.2-7.7
					c1.6-4.2,3.1-8.4,4.6-12.6c3-8.4,6.2-16.8,9.4-25.2c1.8-4.6,15.1-37.8,17-42.3c1.8-4.2,4.2-8.3,5.5-12.7c1.2-4,2.8-7.8,4.6-11.5
					c1.6-3.2,2-6.7,3.6-10.1c4.2-8.9,7.4-18.4,11.9-27.2c0.7-1.3,1.2-1.9,1.6-2c0.7-0.1,1,1.1,1.2,2.6c0.2,1.4,0.2,3.2,0.1,4.1
					c-0.2,4.1-0.9,8.1-0.2,12.2c0.2,1.5,3,10.5,3.9,11.8c1.7,2.5,3.4,6.6,6,8.1c1.4,0.8,3,1.4,4.5,2.3c1.7,1.1,2.8,1.5,4.7,1.3
					c0.1,0,0.3,0,0.5,0c0.7-0.1,5.5-2.9,7.2-3.2c0.6-0.1,0.9,0,1,0.4c0.2,1-3.2,2.9-3.1,4c0.1,0.5,0.5,0.4,0.7,0.4
					c2.2-0.3,7.4-5.6,12.5-11.3c5.6-6.3,11.1-13.2,12.1-14.7c7.5-10.7,14.6-21.5,20.9-33c2.8-5.1,5.1-10.2,7.3-15.6
					c2.1-5.2,4.8-10,6.8-15.3c2.2-5.8,5.2-11.8,6.4-17.9c0.8-4.1,2.7-7.8,3.6-12c1.1-4.8,1.6-10.7,0.9-16.4
					C-337.4,1840-339.2,1835-342.7,1831.5z M-556.3,1958.3c-2.6,3.4-5,6.9-7.4,10.4c-2.5,3.8-5.1,7.5-7.7,11.2
					c-1.3,1.9-3.2,4.8-4.8,7c-1.1,1.5-2.2,2.6-2.8,2.7c-0.9,0.1-1.3-0.5-1.4-1.2c-0.2-1.3,0.6-3.2,1.3-3.7c0.8-0.5,1.4-0.6,1.8-0.7
					c0.5-0.2,0.8-0.5,1.2-2.1c0.3-1.5,0.5-2.7,0.8-3.9c0.3-1.2,0.8-2.4,1.5-3.7c1.3-2.3,2.7-4.7,4.5-6.7c1.6-1.7,2.8-3.1,4.1-5.1
					c1-1.5,2.8-6.7,4.6-6.9c0.2,0,0.6,0.2,0.6,0.4c0.4,3.2-2.9,5.8-4,8.5c2.9-2.2,5.4-6.2,7.4-9.2c1.8-2.6,3.8-5.3,6.6-5.9
					C-550.2,1952.2-554.7,1956.2-556.3,1958.3z M-531.5,1917.5c1.3-1.5,2.8-4.8,5-5.3c-0.2,2.8-4,6.5-5.7,8.8c-0.5,0.7-1.3,1.9-2,2
					l0,0c-0.4,0-0.8-0.2-0.9-1.4C-535.2,1920-532.2,1918.4-531.5,1917.5z M-530.4,1971.7c-1.4,0.2-3.1-0.8-4.8-2.2
					c-2.7-2.3-5.3-5.7-6.6-7.2c-1.7-2.2-2.7-5.2-2.9-7.2c-0.2-1.4,0.5-3.2,1.6-3.3c2.1-0.3,4.3,6.4,7.4,11.7c1.2,2,2.6,3.7,4.2,5.4
					c0.7,0.7,1.8,1.7,1.9,2.2C-529.6,1971.4-529.8,1971.7-530.4,1971.7z M-485.6,2045.2c-0.2,0-0.3-0.2-0.3-0.5
					c-0.2-1,2.7-10,4.5-10.3c0.3,0,0.4,0.1,0.5,0.5C-480.8,2036.2-484.4,2045.1-485.6,2045.2z M-466.4,1999.6L-466.4,1999.6
					c-0.5-1.4,1.2-7.8,2.8-8.1c0.2,0,0.4,0.1,0.4,0.5C-463,1993.5-465.1,1998.8-466.4,1999.6z M-447.6,1959.2
					c-1.7,4.3-3.8,8.2-5.4,12.6c-1,2.8-2.4,5.6-3.7,8.3c-0.9,1.9-2.3,6.7-3.4,6.9l0,0c-0.6,0.1-1.2-0.4-1.3-1
					c-0.2-1.1,0.7-2.9,1.2-4c1-2.3,1.4-1.2,2.3-2.4c0.9-1.3,0.8-3.9,1.1-5.4c0.6-2.8,2.6-6.4,4.6-11c0.5-1.2,2-7,4.1-7.3
					c0.8-0.1,1.1,0.1,1.2,0.6C-446.8,1957.3-447.3,1958.4-447.6,1959.2z M-386,1950.4c0.1-1.5,4.6-7.2,5.5-7.3
					c0.2,0,0.4,0.5,0.4,0.7C-379.9,1945.1-383.5,1949.9-386,1950.4z M-379.6,1908.9c-0.5,0.9-0.2,1.8-0.4,2.8
					c-0.5,2.7-2.6,5.6-3.8,8.1c-3,6.1-6.3,12-10,17.7c-1,1.6-6.8,9.5-12.2,16c-3.9,4.6-7.7,8.5-9.5,8.8l0,0
					c-0.7,0.1-0.9-0.4-0.9-0.8c-0.3-2.2,9.2-11.4,10.6-13c3.9-4.6,7.3-9.5,10.5-14.6c3.5-5.5,6.9-11,9.9-16.8c1.5-3,3-6,4.5-9
					c0.3-0.5,1.6-2.8,2.3-2.9c0.4-0.1,0.8,0.2,1,0.9C-377.4,1907.3-379,1907.7-379.6,1908.9z M-378.2,1901.7c-0.2-1.5,3-7.6,3.8-7.7
					c0.3,0,0.6,0.3,0.7,0.6c0.1,0.9-2.8,7.1-4,7.5C-377.9,1902.1-378.1,1902-378.2,1901.7z M-377.6,1939L-377.6,1939
					c-0.4,0.1-0.6-0.4-0.6-0.6c-0.2-1.1,5-10.1,6.2-10.3c0.2,0,0.3,0.5,0.3,0.7C-371.5,1929.9-376.7,1938.8-377.6,1939z
					 M-368.8,1923c-0.4,0.1-0.5-0.3-0.6-0.4c-0.1-0.7,2.8-7.3,4.4-7.5c0.3,0,0.6,0,0.6,0.4C-364.1,1916.6-367.3,1922.8-368.8,1923z
					 M-364.7,1850.3c-1.1,4.4-2.1,8.9-3.2,13.3c-2.4,9.8-5.8,18.8-9.6,28.1c-1.8,4.4-2.9,8.1-5.4,12.2c-2.5,4-4.8,8.1-7.2,12.1
					c-4.7,7.7-9.6,15.3-14.6,22.9c-2.5,3.9-6.2,7.1-9.3,10.4c-1.4,1.5-3.3,4.1-4.6,4.3c-0.8,0.1-1.8-0.6-2.1-3.1
					c-0.6-4.4,1.4-10.7,2.6-16s2.5-10.6,3.5-16c0.3-1.8,0.6-3.7,0.4-5.5c-0.2-1.5-2-5.5-2.2-6.5c-0.8-4.7,3.8-9.9,6.1-13.8
					c9.2-15.6,18.7-31.6,31.7-44.3c3-2.9,5.7-6,8.7-8.8c1.2-1.1,3.5-3.6,5.2-3.9c1.1-0.2,1.9,0.5,2.3,2.8
					C-361.6,1842.2-363.8,1847.1-364.7,1850.3z M-360.8,1910.4c-0.5,0.1-1.2-0.4-1.3-1c-0.1-0.4,0.6-1.3,1.2-1.4
					c0.3-0.1,0.6,0.1,0.7,0.7C-360,1909.9-360.3,1910.4-360.8,1910.4z"
              />

              <linearGradient
                id="SVGID_8_"
                gradientUnits="userSpaceOnUse"
                x1="-193.1"
                y1="-1303.4"
                x2="-193.1"
                y2="-1303.4"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st9"
                d="M-193.1,1939.4L-193.1,1939.4L-193.1,1939.4z"
              />

              <linearGradient
                id="SVGID_9_"
                gradientUnits="userSpaceOnUse"
                x1="-481.2805"
                y1="-1225.076"
                x2="498.3543"
                y2="-1225.076"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st10"
                d="M-88.7,1814.2c0.5-1.6,0.3-4.3,1.3-5.6c0.7-0.9,2-1,1.9-1.6c0-0.2-0.3-0.6-0.9-1c-1.5-1.3-2.6-1.8-3.2-3.8
					c-0.5-1.6-0.6-3.6-2.3-4.6c-1.8-1-4.3-0.3-6.3-0.4s-2-0.7-2.6-2.4c-0.8-2.5-4.5-2.7-7.3-2.4c-5,0.6-9.8,3.1-14.3,5.3
					c-1.1,0.6-2.2,1.1-3.3,1.7c-0.6,0.3-1.6,1.1-2.3,1.2c-0.5,0.1-0.7-0.2-0.8-0.5c-0.1-0.7,0.2-1.8,0.5-2.5c1.3-3.7,3.7-7.4,4-11.3
					c0.1-0.8,0.1-1.5,0-2.2c-0.4-3.3-2.5-6.2-5.1-8.4c-3.2-2.6-7-4.1-11.1-4.7c-2-0.3-4-0.5-5.9,0.3c-1.7,0.7-3.1,1.8-4.7,2.7
					c-1.7,0.9-3.5,1.7-4.9,3.1c-1.3,1.3-2,3-2.9,4.5c-1,1.7-2.2,3.3-2.9,5.1c-0.8,1.9-0.3,3.7-0.5,5.7c-0.2,1.9-1.3,3.8-2,5.6
					c-0.8,2-1.6,4.1-2.4,6.1c-1.8,4.3-3.8,8.6-5.7,12.8c-1.9,4.1-3.8,8.3-5.8,12.4c-1,2.1-2.4,4.2-3.2,6.4c-0.6,1.6-0.9,3.1-1.6,4.7
					c-1.8,3.5-2.9,7.2-4.8,10.6c-2.1,4-4.1,8-6,12.1c-2.8,6-5.2,12.2-7.6,18.4c-0.9,0.7-1.8,1.4-2.7,2c-7.4,5.4-14.8,10.7-22.2,16.1
					c-8.6,6.2-17.6,12-27,16.8c-8.2,4.2-16.7,7.8-25.6,10.4c-5.9,1.8-14.6,4-20.8,4.8c-5.3,0.6-13.1,0.5-13.6-3.7
					c-0.1-1.2,0.2-2.5,0.1-3.6c-0.2-1.9-0.5-1.5-1-2.8s-0.8-2.4-1-3.5c-0.1-1-0.1-2.2,0.1-3.5c0.5-3.6,1.5-7.2,2.6-10.7
					c0.3-0.9,0.6-2.3,0.9-3.7c0.4-1.5,1-3,1.9-3.7c0.5-0.4,1.5-0.6,2.5-0.7c2.1-0.2,4.8-0.1,6.1,0c1.8,0.1,3.1,0.2,4.5,0
					c0.9-0.1,1.9-0.3,3.1-0.8c6.2-2.3,11.5-5.6,16.5-9.5c5.2-4,9.9-8.7,14.6-13.4c4.8-4.9,10.2-9.5,14.6-14.8c1.9-2.3,3.4-5,5.5-7.1
					c1.9-1.9,3.4-3.7,4.6-6.1c2.9-5.6,6.6-11.5,8.1-17.7c0.4-1.6,0.4-3.2,0.2-4.9c-0.5-4-2.4-8.1-4.6-11.2
					c-3.5-4.9-8.9-7.8-14.6-9.3c-3.1-0.9-7.4-1.9-10.9-1.5c-3.9,0.5-5.7,1.1-8.6,1.4c-1.2,0.1-2.4,0.3-3.5,0.4
					c-1.6,0.2-3.1,0.4-4.2,0.8c-3.3,1.1-5.7,4.2-8,6.6c-2.7,2.9-5.4,5.8-8,8.7c-2.6,2.9-5.1,6-7.4,9.2c-4.1,5.7-7.7,11.9-11.3,17.9
					c-4.1,6.9-8.4,13.5-11.7,20.8c-3,6.6-5.9,13.3-7.9,20.1c-2,6.5-3.3,13.1-3.4,20c-0.1,3.8,0.7,7.4,1.2,11.1
					c0.1,1,0.1,2.5,0.3,3.5c0.1,0.9,0.4,1.5,1,1.4c0.7-0.1,1-0.4,1.4-0.5c0.1,0,0.3,0,0.4,0.1c1.2,0.7,2,2.3,2.7,3.9
					c0.7,1.7,1.2,3.6,1.8,4.8c1.2,2.7,2.1,6.1,4.2,8.2c2.5,2.6,5,4.8,7.8,6.7c2.1,1.5,4.2,2.8,6.6,4c3.4,1.8,6.8,2.6,10.4,2.8
					c3,0.2,6.2,0.1,9.3,0c1.2,0,2.4-0.2,3.6-0.3l1.1-0.1c2-0.2,4-0.4,6-0.3c2.8,0.2,5.2,0.5,7.8,0.2l0,0c3.5-0.4,6.9-1.3,10.2-2.5
					c3.9-1.4,7.8-3.3,11.5-5.2c5.7-2.9,11.3-6.3,16.6-10.1c5.4-3.9,10.5-8.2,15.1-12.7c1-0.9,2-1.7,3.1-2.5v0.1
					c0.3,2.7,1.1,5.2,2.1,7.9c0.7,1.7,1,4.1,2.9,4.6c0.7,0.2,1.1-0.2,1.7,0.4c0.6,0.5,0.5,1.4,0.8,2c0.8,1.6,2.3,0.8,3.4,1.9
					c0.8,0.8,1.4,2.9,2.8,2.7c1-0.1,1.4-0.6,1.9-0.6c0.3,0,0.7,0.2,1.1,1c0.5,0.8,0.9,1.3,1.3,1.3c0.3,0,0.6-0.3,1-1.2
					c0.4-1,0.4-1.9,1.3-2.5c0.3-0.2,0.8-0.1,1.2-0.2c1.7-0.2,1.4-3.5,1.5-4.9c0.1-2.2,0.4-4.3,0.9-6.3c0.5-2.3,1.2-4.5,2.1-6.7
					c1.6-4.1,3.6-8,5.2-12.1c1.7-4.3,3.2-8.7,5.1-12.9c3.9-8.7,8.5-17,12.8-25.5c1.7-3.4,3-7.3,5.3-10.3c2.9-3.8,6.4-7.1,9.6-10.6
					c6.1-6.6,13.5-11.5,19.8-17.9c2.8-2.9,5.9-5.3,9.2-7.7c1.1-0.8,4.7-3.5,5.7-3.6s1.3,0.6,1.5,1.5c0.1,1,0,2.3,0,2.6
					c0.4,2.8,2.7,2.9,4.5,3.4c1.7,0.4,3.6,1.4,5.3,1.2c1.7-0.2,3.4-1.8,4.7-2.9C-93.6,1820.4-89.9,1817.8-88.7,1814.2z
					 M-324.6,1898.2c-0.5,2.2-1,4.4-1.5,6.6s-0.9,4.4-1.2,6.7c-0.3,2-0.6,2.9-1,3c-0.5,0-1.2-1.2-1.5-3.4c-0.3-2.3,0-4.9,0.4-7.5
					c0.4-2.5,1-4.8,1.5-6.4c0.2-0.7,0.7-3.6,2-3.7c1-0.1,1.4,0.8,1.5,1.8C-324.3,1896.2-324.5,1897.6-324.6,1898.2z M-279.9,1839.1
					c2.6-2.7,5.4-5.4,8.2-8c2.6-2.5,5.4-4.8,8.1-7.1c3-2.5,5.9-4.9,8.8-7.4c0.9-0.8,3.7-3.3,5.7-3.5c1-0.1,1.7,0.3,1.9,1.9
					c0.3,2.3-1.5,6-3.9,9.8c-2.1,3.2-4.6,6.6-6.7,9.1c-2.6,3.2-5.1,6.6-7.9,9.6c-2.1,2.3-4.9,4.1-6,6.9c-0.6,1.7-0.6,2.6-2.1,4.1
					c-1,1-2.3,1.8-3.5,2.6c-3.3,2.4-6.1,5.3-9.2,8c-2.6,2.3-5.4,4.4-8.2,6.3c-1.2,0.8-3.3,3.1-5.3,3.3c-0.8,0.1-1.3-0.2-1.5-1.3
					c-0.3-2.2,1.7-6.3,2.6-8.2c1.5-3.1,3.4-5.8,5.2-8.7C-289.7,1850.2-285.1,1844.6-279.9,1839.1z M-319.3,1880.3
					c-0.7,0.1-1.8-1.3-2-2.5c-0.3-2.2,2.4-4.8,2.9-6.8c0.4-1.5,0.3-2.8,1-4.3c0.6-1.2,1.7-2.3,2.4-3.5c1.5-2.3,1.9-5.1,3.4-7.4
					c0.8-1.3,1.7-2.1,2.1-3.6c0.3-1.2-0.1-1.7,0.8-2.7c0.3-0.4,1.7-0.8,2.2-1.2c1.1-1,2.9-3.5,3.5-4.9c0.9-2,0.2-3.5,1.9-5.1
					c1.1-1,2.7-1.2,3.9-2.5c1-1.1,1.8-2.4,2.8-3.5s2-2.3,3.1-3.3c1.2-1.2,2.1-1.2,3-1.9c0.6-0.5,1.9-2.2,2.8-2.2
					c0.4,0,0.8,0.1,1,1.2c0.1,0.9-1.5,2.5-3.4,4.8c-1.2,1.5-2.5,3.7-3.6,4.9c-2.1,2.3-4.3,4.7-5.8,7.3c-2.4,4.2-5.2,8.2-7.8,12.3
					c-0.8,1.3-1.6,2.7-2.3,4c-1.3,2.5-2.7,4.2-4.1,6.7c-1.6,2.9-3.1,6.6-4.6,9.6C-316.5,1877-317.7,1880.2-319.3,1880.3z
					 M-180.7,1868.2c0-0.3,0.5-0.8,1.2-1.4c-0.3,0.6-0.5,1.1-0.8,1.7C-180.6,1868.5-180.7,1868.4-180.7,1868.2z M-191.8,1894.2
					c-1.7,1.7-3.4,3.3-5.3,4.8c0.9-2.4,1.8-4.7,2.7-7.1c1.6-4,3.3-8,4.9-12c2-1.8,4.1-3.4,6.3-5
					C-186.1,1881.3-188.9,1887.8-191.8,1894.2z M-208.6,1897.7c0.6-0.7,1.3-1.2,1.9-1.7c-0.5,1.5-0.9,3-1.4,4.5
					c0,0.1-0.1,0.2-0.1,0.3c-0.7,0.4-1.3,0.7-1.9,0.8c-0.7,0.1-1.4-0.1-1.4-0.7C-211.6,1900-209.2,1898.4-208.6,1897.7z
					 M-214.8,1910.5c0-0.8,0.7-2.5,2-4c1.1-1.2,2.5-2.3,3.7-3.1c-0.7,2-1.2,4.1-1.7,6.2c-1,0.7-2.3,1.5-2.7,1.5
					C-214.4,1911.2-214.8,1911-214.8,1910.5z M-202.2,1914.1c-0.1-1.7,0.8-3.5,1.4-5.1c0.5-0.5,1-0.9,1.5-1.4
					c0.7-0.6,1.4-1.3,2.1-1.9c-0.1,0.1-0.1,0.3-0.2,0.4C-198.6,1908.5-199.9,1912.5-202.2,1914.1z M-173.6,1879.1
					c-3.2,7.5-6.6,14.9-9.7,22.5c-1.5,3.6-2.7,7.3-4.3,10.9c-0.5,1.1-1.9,4.8-3.1,5c-0.7,0.1-0.9-0.3-1-1.4
					c-0.3-2.8,2.4-10.2,2.7-11.3c1.1-3.9,2.3-7.8,4.6-11.2c1.9-2.9,3.7-5.3,4.9-8.7c0.6-1.6,1-3.9,2.2-5.2c0.7-0.8,1.4-1,1.8-2
					c0.4-0.9,2.3-7,4-7.2c0.5-0.1,0.7,0.2,0.8,0.6C-170.3,1872.8-173.2,1878.2-173.6,1879.1z M-166.7,1838.4c-1.4,3.1-3.2,6.9-5,11
					c-1.9,4.2-3.8,8.5-5.7,12.7c0-0.9,0.2-1.6,0.2-2.1c0-0.4-0.3-0.7-1.2-0.5c-0.7,0.1-2,1.2-3.3,2.6c2.3-5.1,4.6-10.1,7.2-15.1
					c1.9-3.7,3.9-7.3,5.7-11c1.6-3.2,3.5-6.6,4.3-6.7c0.5-0.1,0.7,0.2,0.7,0.7C-163.7,1831.3-164.9,1834.4-166.7,1838.4z"
              />

              <linearGradient
                id="SVGID_10_"
                gradientUnits="userSpaceOnUse"
                x1="-479.1831"
                y1="-1086.896"
                x2="478.1642"
                y2="-1086.896"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st11"
                d="M-37.9,1743.8c1.4,1.8,2.6,3.2,4.6,4.4c1.9,1.1,5.2,1.8,8,1.6c1.2-0.1,2.2-0.4,3-0.8
					c2.2-1.3,3.7-4.7,5.3-6.8c1.6-2.1,3.3-4.2,4.7-6.5c1.3-2,3-3.4,4.3-5.3c1.4-2,2.4-4.5,4-6.3c2.9-3.2,6.2-7.2,5.6-11.9
					c-0.4-3.3-2.5-5-4.5-7c-1.6-1.6-2.8-3.7-4.6-5.1c-3.9-3.1-11.6-4.4-14.1-4.1c-2.7,0.3-6.1,2.3-6.5,2.6c-1.8,1.1-3.1,3.8-4.4,5.5
					c-3.6,5-6.1,12.6-7.6,18.5c-0.7,2.7-1.5,5-1.2,7.8c0.2,1.4-0.9,4.9-0.6,7.2C-41.5,1740-39.4,1741.8-37.9,1743.8z"
              />

              <linearGradient
                id="SVGID_11_"
                gradientUnits="userSpaceOnUse"
                x1="-483.026"
                y1="-1185.1439"
                x2="536.108"
                y2="-1185.1439"
                gradientTransform="matrix(1 0 0 -1 0 636)"
              >
                <stop offset="0" style={{ stopColor: `#005C97` }} />
                <stop offset="0.4964" style={{ stopColor: `#008599` }} />
                <stop offset="1" style={{ stopColor: `#59AD40` }} />
              </linearGradient>
              <path
                className="st12"
                d="M558.4,1781.7c-0.7,0.1-1.6,0.5-2.3,0.5c-0.5,0.1-1.1,0.1-1.1-0.4c-0.1-1,4.1-3.1,3.9-4.1
					c-0.1-0.7-0.6-0.7-0.7-1.7c-0.1-1,0.2-1.8,0.2-2.3c0-0.4-0.3-0.7-1.2-0.5c-1.4,0.2-5.3,4.6-5.9,6.2c-0.2,0.6,0.1,1.4-0.2,1.9
					c-0.8,1.3-3.2,3.2-5.4,5c-4.6,3.6-9.3,7.2-14,10.7c-7.4,5.4-14.8,10.7-22.2,16.1c-8.6,6.2-17.6,12-27,16.8
					c-8.2,4.2-16.7,7.8-25.6,10.4c-5.9,1.8-14.6,4-20.8,4.8c-5.3,0.6-13.1,0.5-13.6-3.7c-0.1-1.2,0.2-2.5,0.1-3.6
					c-0.2-1.9-0.5-1.5-1-2.8s-0.8-2.4-1-3.5c-0.1-1-0.1-2.2,0.1-3.5c0.5-3.6,1.5-7.2,2.6-10.7c0.3-0.9,0.6-2.3,0.9-3.7
					c0.4-1.5,1-3,1.9-3.7c0.5-0.4,1.5-0.6,2.5-0.8c2.1-0.2,4.8-0.1,6.1,0c1.8,0.1,3.1,0.2,4.6,0c0.9-0.1,1.9-0.3,3.1-0.8
					c6.2-2.3,11.5-5.6,16.5-9.5c5.2-4.1,9.9-8.7,14.6-13.4c4.8-4.9,10.2-9.5,14.6-14.8c1.9-2.3,3.3-5,5.5-7.1
					c1.9-1.9,3.4-3.7,4.6-6.1c2.9-5.6,6.6-11.5,8.1-17.7c0.4-1.6,0.4-3.2,0.2-4.9c-0.5-4-2.4-8.1-4.6-11.2
					c-3.6-4.9-8.9-7.7-14.6-9.3c-3.1-0.9-7.4-1.9-10.9-1.5c-3.9,0.5-5.7,1.1-8.6,1.4c-1.2,0.1-2.4,0.3-3.5,0.4
					c-1.6,0.2-3.1,0.4-4.2,0.8c-3.3,1.1-5.7,4.2-8,6.6c-2.7,2.9-5.4,5.8-8,8.7c-2.6,2.9-5.1,6-7.4,9.2c-4.1,5.7-7.7,11.9-11.3,17.9
					c-4.1,6.9-8.4,13.5-11.7,20.8c-3,6.6-5.9,13.3-8,20.1c-1,3.2-1.8,6.5-2.4,9.8c-1.5,1.1-3.1,2.9-4,3c-0.4,0-0.5,0-0.6-0.2
					c-0.1-1,4-4.8,4.4-5.5c0.5-0.8,1.6-2.8,1.5-3.9c0-0.5-0.4-0.7-0.9-0.6c-0.7,0.1-1.5,1.3-1.9,1.8c-1.1,1.1-2.1,2.3-3.2,3.4
					c-0.4,0.5-1,0.8-1.5,1.3c-0.7,0.5-1.3,1.1-1.5,1.9c-0.3,1-0.1,1.6-0.7,2.5c-0.3,0.5-1,1-1.4,1.4c-1.2,1.2-2.4,2.3-3.7,3.3
					c-1.5,1.2-3.1,2.3-4.6,3.5c-1.6,1.2-3.1,2.3-4.6,3.6c-1.9,1.6-3.9,3.1-5.9,4.6c-4.3,3.2-8.8,6.2-13.5,9
					c-6.2,3.7-12.6,7.1-19.1,10.1c-3.8,1.7-7.6,2.9-11.5,4.3c-3.6,1.3-7.2,2.5-11,3c-1.9,0.2-3.9,0.7-5.9,0.9
					c-1.8,0.2-3.7,0.3-5.4-0.2c-3.4-1-3.6-2.7-4-6.2c-0.8-6.3,0.2-16.3,3.1-23.6c2.6-6.8,4.9-14.3,9.1-20.3
					c8.5-12.4,17.6-24.3,28.3-35c3-3,6.3-7.1,9.5-9.9c0.9-0.8,4.8-4,6.8-4.2c0.7-0.1,1.1,0.2,1.2,1c0.4,3.5-6.7,15.8-7.7,17.7
					c-1.6,3.2-3.3,6.5-5.2,9.5c-0.4,0.7-4.5,5.5-4.3,6.7c0,0.2,0.3,0.4,0.4,0.3c0.8-0.1,2.9-2.7,3.4-3.2c1.1-1.1,1.5-2.2,2.2-3.4
					c0.6-1,2.8-4.2,4.3-4.4c0.5-0.1,1,0.1,1.1,0.9c0.2,1.6-1.6,4.1-2,5.5c-0.3,1.1-0.5,1.9-1,2.9c-0.4,0.6-0.8,1.3-0.7,1.8
					c0,0.5,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.8-0.2c0.7-0.1,0.7,0.6,1.1,0.9c0.7,0.4,1.1,0.5,1.6,0.5c0.5-0.1,1.1-0.4,2-0.7
					c0.4-0.1,0.7-0.2,0.9-0.2c0.9-0.1,1.1,0.5,2.2,0.4c0.7-0.1,1.3-0.8,1.9-1.6c0.5-0.6,0.9-1.3,1.1-1.6c1.2-1.1,2.5-2.1,3.9-3
					c1.4-0.8,2.7-1.5,3.9-2.3c1.5-1,2.9-2.2,4.2-3.8c1-1.3,2.1-2.6,3.2-3.9c1.1-1.4,2.1-2.7,3-4.2c2.8-4.6,4.2-10.5,3.5-15.9
					c-0.2-1.4-0.5-2.7-0.9-4c-0.6-1.8-1.5-3.4-2.7-4.9c-0.9-1.1-1.8-2.8-2.9-3.7c-1.2-0.9-2.5-0.1-3.7-0.7c-1.2-0.5-1-1.2-1.5-2.4
					c-0.5-1.3-1.1-2.1-1.9-2.8c-1.4-1.3-3.2-1.8-5.2-2.7c-1.3-0.6-2.2-1.6-3.1-2.5s-1.9-1.6-3.4-1.7c-1.7-0.1-2.9-1.1-4.6-1.2
					c-1.6-0.1-3.2,0.3-4.8,0.3c-1,0-1.9,0-2.8,0.1c-2.4,0.3-4.4,1.1-6.5,3c-2.9,2.6-5,6-7.6,8.8c-2.1,2.2-4.3,4.3-6.4,6.4
					c-2.5,2.6-5,5-7.3,7.8c-1.8,2.2-3.4,4.8-5.3,6.9c-0.9,1-3.4,2.4-3.6,3.9c-0.2,0.9,0.8,1.6,0.9,2.6c0.1,1.1-1.7,3.1-2.7,3.8
					c-1.1,0.8-1.6,0-2.2,0.1c-1.1,0.1-0.2,1.3-0.1,2c0.2,1.6-1.9,2.5-2.9,3.7c-1.3,1.5-2.9,3.8-3.9,5.9c-0.6,1.3-0.7,2.4-1.4,3.7
					c-0.6,1.1-1.1,2-2,2.8c-0.8,0.8-2.1,2-3.2,3.3c-1,1.2-1.8,2.4-1.8,3.4c-0.1,1.5,0.7,1.4-0.2,3c-0.7,1.1-1.8,1.9-2.5,2.9
					c-0.8,1.2-0.8,2.3-1,3.7c-0.2,1.6-0.8,3-1.4,4.4c-0.1,0.3-0.2,0.5-0.3,0.8c-10.1,8-20.1,15.9-30.2,23.9
					c-5.7,4.5-11.6,9.2-17.7,13.4c-5.6,3.8-11.4,7.3-17.6,9.7c-1.9,0.7-6,3.2-8.4,3.5c-1.7,0.2-2.3-0.5-2.4-1.5
					c-0.2-1.6,0.8-3.9,1.3-5.1c2.4-6.6,5.2-13,8-19.4c1.3-2.9,2.1-6,3.7-8.8c1.6-2.8,3.3-5.3,4.7-8.2c1.3-2.8,2.4-5.2,4.2-7.6
					c0.5-0.7,3.7-5.6,3.5-7c0-0.3-0.1-0.4-0.3-0.4c-1.1,0.1-2.2,2-2.8,2.2c-0.2,0.1-0.4-0.1-0.5-0.7c-0.1-0.9,1.7-2.6,2-3
					c0.8-1.2,1.5-2.7,2.3-3.9c1.3-2,2.4-4.1,3.6-6.1c1.2-2,2.4-4,3.9-5.9c1.6-2.1,2.3-4.8,3.6-7.1c1.2-2.2,2.4-4.3,3.6-6.6
					c3.6-6.5,5.2-10.7,4.9-13.4c-0.5-4.2-3.1-8.1-7.3-9.5c-0.6-0.2-1.6-0.1-2.5-0.1c-1.2,0-2.4-0.2-2.5-1.2
					c-0.1-1.2,0.9-1.3,0.8-1.8c-0.1-0.4-0.7-1.2-2-1.6c-1.3-0.3-2.5-0.4-3.7-0.2c-3.9,0.4-7.4,3.3-10.5,5.5
					c-4.7,3.3-9.1,7-13.3,10.9c-4.3,3.9-8.3,8-12.5,12.1c-3.6,3.5-7.8,6.4-11.1,10.1c-3.6,3.9-7.1,7.9-10.7,11.8
					c-3.4,3.8-6.5,9.7-10.8,12.5c-0.5,0.3-1.3,0.7-1.9,0.8c-0.6,0.1-1.1-0.1-1.2-1c-0.2-1.4,2.5-3.1,2.9-3.8
					c2.7-3.9,3.6-8.1,5.3-12.5c1.6-4.2,4.5-7.8,6.1-12.1c1.7-4.6,2.8-9.3,4.3-13.9c0.7-2,0.9-3.8,0.6-5.6c-0.3-2.4-1.3-4.7-2.9-7.1
					c-1.9-3-6.9-4.9-10.3-5.6c-1.2-0.3-2.5-0.3-3.6-0.2c-6.9,0.8-12.3,7.9-13.5,14.4c-1,5.2-1.9,9.5-3.9,14.4
					c-1.9,4.7-3.8,9.4-5.6,14.1c-1.5,4-3.5,7.6-4.3,11.7c-0.5,2.1-1.9,3.6-2.6,5.5c-0.8,2.3-1.1,3.8-2.4,6c-2.4,4-3.5,8.3-5.6,12.4
					c-2,4-4.5,7.8-6.6,11.8c-2,3.7-3.7,7.5-5.2,11.4c-2,1.4-4,2.9-6,4.3c-9.6,6.8-19.5,13.2-29.6,19.4c-5.1,3.2-10.3,6.2-15.7,8.9
					c-5.4,2.7-11.1,4.5-16.8,6.5c-5.5,1.8-11.7,2.4-17.5,2.9c-4.7,0.4-9.7,0.4-13.9-1.9c-4.6-2.5-6.6-6.7-7.2-11.4
					c-0.6-4.7,0.3-9.8,1.3-14c0.4-1.5,1.4-6.9,3.8-7.2c0.9-0.1,1.3,1.9,1.6,2.7c0.5,1,1.2,1.9,2,2.7c1.8,1.6,4,2.3,6.4,2.6
					c2.5,0.3,5,0.3,7.4,0c8.3-1,15.9-5.2,21.9-11.1c3.8-3.7,7.8-7.1,11.2-11.4c4.1-5.1,7.5-11.2,11.3-16.3c3.3-4.5,6.6-9.1,9.5-13.8
					c1.4-2.2,2-4.8,3.3-7.1c1.1-2.1,2.5-4.1,3.6-6.3c2.4-4.7,4.8-10.1,6.1-15.2c0.4-1.6,0.4-3.3,0.2-4.8c-0.5-3.6-2.2-7.1-3.9-10.5
					c-2.3-4.7-5.6-6.8-10.3-9c-4.3-1.9-10.2-2.7-14.9-2.1c-2.9,0.3-5.7,1.2-8.6,1.5s-5,0.7-7.3,2.7c-4.3,3.8-8.2,8.1-11.9,12.4
					c-1.6,1.9-4.3,4-5.9,5.9c-4,4.9-8.9,9.9-12.5,15c-3,4.2-5,8.4-7.6,12.7c-2.3,3.7-5.7,7.7-8,11.6c-2.2,3.7-3.4,7.4-5.5,11.3
					c-5.2,9.6-9.5,20.7-9.1,31.8c0.1,2-0.1,5,0.5,7.2c-3.2,2.3-6.3,4.7-9.5,7c-7.1,5.1-14.1,10.3-21.5,14.9
					c-5.7,3.6-11.5,7-17.6,9.9c-3.5,1.7-7,3.5-10.7,4.8c-1.7,0.6-4.5,1.9-7,2.2c-1.3,0.2-2.6,0-3.4-0.6s-1.1-1.1-1.2-1.7
					c-0.2-1.7,2.8-4.9,2.6-6.5c-0.2-1.8-0.5-1.9-0.6-2.8c-0.1-1.2,0.3-3.1,0.7-4.9c1.1-4.1,2.4-8.2,3.8-12.2c1.3-3.6,2.6-7.3,3.8-11
					c1.1-3.5,3.2-6.8,4.7-10.2c1.5-3.4,3-6.8,4.3-10.2c2.9-7.2,6.9-13.9,10.5-20.8c3.8-7.4,6.8-15.1,10.8-22.4
					c1.2-2.2,1.4-3.9,1.2-5.7c-0.2-1.3-0.5-2.6-0.9-4.2c-0.7-2.7-1.5-7.6-4.1-9.2c-1.9-1.2-2.4,0-3.1-2.1c-0.5-1.4,0.2-1.9-1.4-2.8
					c-1.1-0.6-2.4-0.3-3.5-0.9c-1.1-0.5-2.2-1.8-3.9-1.6c-1.6,0.2-3.6,1-5.1,1.2c-1.2,0.1-2.5,0-3.4,0.9c-1.7,1.6-1.7,5.9-2.6,8.1
					c-1.6,3.9-3.4,7.8-5.1,11.7c-1.7,3.7-3.3,7.4-4.9,11.2c-1.6,3.9-3.6,7.7-5.2,11.6c-2.2,5.3-2.9,9.7-5,15
					c-2.1,5.5-5.4,11.9-7.5,17.5c-2.1,5.9-4.5,12.1-5.2,18.3c-0.5,3.7-2.7,6.8-3,10.6c-0.3,3.3-1.4,6.5-1,9.9
					c0.5,4.4,2.4,8.2,3.8,11.5c0.5,1.2,1.3,5.7,3.2,5.5c1-0.1,0.8-0.9,1.9-1c1.2-0.1,2.5,1.6,3.7,3.6c1.2,2.1,2.4,4.5,3.2,5.4
					c2.1,2.3,5.1,3.8,7.9,5.3c5.1,2.8,11.8,3.7,17.7,3.1c7.1-0.8,12.8-5.8,18.3-10c1.1-0.8,2.1-1.1,3.1-1.2c2-0.2,3.9,0.2,6-1.1
					c2.6-1.5,4.4-4.1,6.4-6.2c4.4-4.7,9.2-9.1,13.4-14c0.7-0.8,5.2-3.8,5.1-4.6c-0.1-0.7-0.9-0.8-1.8-0.7c-2.1,0.3-5.4,1.9-6,2.3
					c-2.8,1.8-5.8,4.2-9,5.1c-1,0.3-5,1.4-6.8,1.6c-0.6,0.1-0.9-0.2-0.9-0.4c-0.1-1,4.8-4.1,5.2-4.4c3-2.3,6-4.6,9-6.9
					c1.1-0.9,1.8-1.9,1.8-2.3c0-0.2-0.2-0.2-0.4-0.2c-0.3,0-0.7,0.3-1.6,0.9c-0.7,0.5-4.8,3.9-6.4,4c-0.4,0-0.6,0-0.6-0.2
					c-0.1-0.6,1.7-2.3,3.4-3.9c1.6-1.5,3.3-2.8,3.7-3.2c0.9-0.8,2.2-0.9,3-1.7c0.9-0.8,1.1-1.9,2.1-2.7c1.1-0.9,1.3-0.9,2-2.2
					c0.7-1.2,0.7-2.4,1.9-3.2c0.9-0.6,1.9-0.7,2.8-1c0.6,0.6,1.4,1.1,1.9,2c2.4,4.6,3.4,10.3,5.2,15.2c1,2.7,2.4,4.5,4.2,6.8
					c1.5,1.9,2.4,4.3,4,6.2c3.1,3.8,7.5,7.7,11.8,10.1s10.3,3.4,15.1,3.8c6.7,0.5,12.1,1.4,19.2,0.5l0,0c3.9-0.5,8.9-2.9,12.3-4.8
					c7.2-4.1,14.5-8.5,20.9-13.7c1.5-1.2,2.7-2.6,4.1-3.9c1.2-1.1,2.4-1.4,3.9-2.1c3.3-1.6,6.1-4.4,8.9-6.7c1-0.8,1.9-1.6,2.9-2.4
					c0.3,0.1,0.5,0.1,0.7,0.3c0.5,0.4,0.5,1.1,0.8,1.5c0.9,1.6,0.8,3.4,2.4,4.4c1.2,0.7,2,0.5,3,1.6c1.1,1.3,0.9,3.1,2.5,2.9
					c2.4-0.3,2.9-3.5,5-3.8c1-0.1,1.2,0.5,1.8,0.5c1.6-0.2,1.5-3.7,3.1-5.4c1.4-1.5,3.3-2.7,4.8-4.1c1.9-1.7,3.2-3.6,4.3-5.7
					c1-1.8,1.9-3.7,2.8-5.6c2.1-4.4,5-7.9,8-11.7c5.2-6.6,10.3-13.2,15.5-19.8c7.9-10,16.1-19.9,25-29c3.5-3.6,6.8-7.4,10.3-11
					c1.6-1.7,3.4-3.3,5.1-4.9c1-0.9,3.9-4.5,5.3-4.7c0.8-0.1,1.1,0.2,1.2,0.7c0.3,2.1-3.4,7.9-3.9,8.9c-0.3,0.7-6.9,9.4-6.7,11.3
					c0,0.4,0.3,0.6,0.8,0.5c2.9-0.3,6.2-8.6,7.1-9.8c1.3-1.7,1-2.8,1.8-4.6c0.9-1.9,2.8-2.5,4.3-3.9c0.5-0.4,2.1-2.8,3.3-2.9
					c0.5-0.1,0.8,0.2,0.8,0.5c0.1,1.1-1.4,3.7-1.7,4.2c-1.8,3.8-3.4,7.8-4.5,11.8c-0.1,0.5,0.1,1,0.1,1.5c0.2,1.3-1.1,2.2-1.7,3.3
					c-1.2,2-2,4.2-3.3,6.2c-1.2,1.9-1.4,3.3-2.2,5.4c-0.3,0.9-1,1.5-1.3,2.3c-0.2,0.5,0.3,1.2,0.1,1.6c-0.4,1-3.1,6.5-4.4,6.6
					c-0.3,0-0.5-0.1-0.5-0.7c-0.3-2.1,3.1-7.5,2.9-9.4c-0.1-0.5-0.3-0.7-0.5-0.6c-1.6,0.2-6.3,7.8-6.8,8.8c-1.5,2.7-2.2,6.2-4.2,8.5
					c-1.1,1.3-1.6,1.4-2,3c-0.4,1.4-0.4,2.5-1.1,4c-1.7,3.7-1.4,6.8-1.3,10.9c0,1.6-0.1,3.1,0.1,4.4c0.2,1.4,0.6,2.7,1.8,3.9
					c1.9,2,2.7,2.9,3.4,5.7c0.3,1.3,0.4,3.3,1.2,4.4c0.9,1.2,1.9,0.9,3,1.7c1.8,1.2,1.9,3.6,3.8,4.7c1.5,0.9,3.7,1.2,5.4,1.9
					c1.4,0.7,2.3,1.3,3.9,1.2c0.6,0,1.1,0,1.6-0.1c1.7-0.2,3.3-0.6,5-0.8c1.1-0.1,2.3-0.2,3.5,0.1c1.7,0.3,3.4,0.6,5.1,0.5l0,0
					c1-0.1,1.9-0.3,2.9-0.7c2.1-0.9,3.7-2.7,5.9-3.7c2.6-1.1,5.6-2.4,8.5-4c3.2-1.8,6.1-4,8-6.7c1.8-2.7,1.7-4.7,4.8-6.3
					c2.5-1.3,3.8-2.8,5.7-4.7c0.7-0.7,3.5-3.2,7.1-6.3c3.1-2.7,6.7-5.9,10.1-8.9c1.8-1.6,3.7-3.4,5.4-5c0.3,1.9,0.6,3.9,1,5.8
					c0.7,3.3,1.7,6.5,2.9,8.9c1.8,3.6,4.5,6.5,7.1,9.5c1.9,2.1,3.7,4.2,5.1,6.6c1.8,3,3.7,5.2,7.4,5.4c1.7,0.1,3.1,0,4.7,0.8
					c1.5,0.7,2.8,1.3,4.5,1.6c3.7,0.9,7.5,1.7,11.4,2c0.7,0.1,1.4,0,2.1,0l0,0c2.6-0.3,4.9-1.5,7.5-2.2c4-1,8-1.9,11.7-3.7
					c3.4-1.6,6.5-3.7,9.9-5.2c3.3-1.5,5.6-3.8,8.6-5.8c2.6-1.8,4.3-4.6,6.8-6.6c0.7-0.5,1.7-1.1,2.6-1.7c1.1-0.7,2.1-1.5,2.4-2.3
					c0.4-0.9,1.1-1.1,1.1-1.6c-0.1-0.6-0.8-0.2-0.9-1.2c-0.1-0.9,2-2,4-3.2c1.6-1,3.1-2,3-2.8c0-0.4-0.3-0.5-0.7-0.5
					c-1.4,0.1-4.6,2.6-7.6,4.6c-2,1.4-3.9,2.6-5,2.7c-0.7,0.1-1.1,0-1.1-0.3c-0.2-1.2,6.3-5.5,7.2-6.3c1.2-1,2.7-1.5,3.9-2.4
					c1.6-1.1,3.1-2.3,4.6-3.5c4.1-3.2,8.5-6.6,12.2-10.5c0.1,1,0.3,2,0.4,3s0.1,2.5,0.3,3.5c0.1,0.9,0.4,1.5,1,1.4
					c0.7-0.1,1-0.4,1.4-0.5c0.1,0,0.3,0,0.4,0.1c1.2,0.7,2,2.3,2.7,3.9c0.7,1.7,1.2,3.6,1.8,4.8c1.2,2.7,2.1,6.1,4.2,8.2
					c2.5,2.6,5,4.8,7.8,6.7c2.1,1.5,4.2,2.8,6.6,4c3.4,1.8,6.8,2.5,10.4,2.8c3,0.2,6.2,0.1,9.3,0c1.2,0,2.4-0.2,3.6-0.3l1.1-0.1
					c2-0.2,4-0.4,6-0.3c2.8,0.2,5.2,0.5,7.8,0.2l0,0c3.5-0.4,6.9-1.3,10.2-2.5c3.9-1.4,7.8-3.3,11.5-5.2c5.7-2.9,11.3-6.3,16.6-10.1
					c5.4-3.9,10.5-8.2,15.1-12.7c2.4-2.3,5.2-3.9,7.7-6.1s4.9-4.6,7.4-6.9c5.4-4.8,10.3-8.8,13.7-15.3c0.5-1,2.2-3.4,2-4.5
					c-0.1-0.5-0.3-0.7-0.7-0.7c-1.6,0.2-5.5,5.1-6.5,6.3c-4.4,4.8-9.7,8.4-14.7,12.6c-1.3,1-2.5,2.1-3.9,2.9c-0.8,0.5-3.3,2.3-4,2.3
					c-0.9,0.1-1.3-0.1-1.3-0.6c0-0.8,0.7-2.5,2-4c1.8-1.9,4.4-3.6,5.4-4.3c1.4-1,4.3-2.8,4.9-4.3c-0.2-0.1-0.5-0.1-0.7,0
					c-1.1,0.1-2.2,0.9-3.3,1.8c-1.1,0.8-2.5,1.8-3.5,2c-0.7,0.1-1.4-0.1-1.4-0.7c-0.1-1,2.3-2.6,2.9-3.3c2.2-2.2,4-3.7,6.6-5.3
					c1.1-0.7,2.8-1.8,4.3-2.9c1.5-1.2,2.9-2.4,3.6-3.5c1.4-2.3,2.3-3.9,4.3-5.9c2.6-2.4,5.5-4.5,8.3-6.6c0.7-0.5,5.6-3.6,5.4-5
					C559.6,1781.7,558.9,1781.7,558.4,1781.7z M-80.5,1875.2c-2.1,5.3-1.4,10.5-2,16.2c-0.3,2.9-0.5,4.7-1.9,4.8
					c-0.6,0.1-1.8-1.1-2.1-3.8c-0.3-2.2,0.3-5.9,1.2-10.3c1.4-6.2,3.6-13.7,5.5-19.8c1.7-5.5,3.8-10.7,6.2-15.9
					c1.1-2.5,2.3-3.7,3-3.8c0.4,0,0.5,0.3,0.6,0.9c0.1,0.8-0.4,1.9-1.3,3.5c-2.1,3.7-3.5,8.1-4.6,12.5
					C-77.5,1864.8-78.6,1870.3-80.5,1875.2z M53.2,1800.6c3.4-3.6,6.8-7.9,10.5-11.3c4.3-4,8.8-7.8,13.5-11.2c1.1-0.8,4-3,6-3.2
					c1-0.1,1.8,0.2,1.9,1.5c0.3,2.3-3.5,8.3-5.5,11.7c-3.4,5.9-7.1,11.6-11,17.2c-3.8,5.5-7.7,10.9-11.6,16.3
					c-4.6,6.2-9,12.5-14.6,17.8c-2.7,2.5-5.5,5.2-8.6,7.2c-1,0.7-6.6,3.4-10.3,3.8c-1.8,0.2-3.2-0.2-3.4-1.6
					c-0.1-1.2,0.9-2.6,1.4-3.5c2.2-3.6,3.8-7.4,5.8-11.1c2.4-4.2,4.9-8.4,7.7-12.3c2.3-3.4,4.8-6.7,7.4-9.8
					C45.9,1808,49.5,1804.6,53.2,1800.6z M2.1,1856.6c0.9-3.9,2.4-7.7,4.1-11.4c1.7-3.7,3.8-7.2,5.5-11c1.8-4,4.1-7.9,6.7-11.5
					c1.1-1.6,2.3-3.1,3.2-4.8c0.8-1.5,0.8-2.7,1.2-4.3c0.5-1.7,1.4-2,2.5-3.2c0.5-0.5,0.7-0.8,0.9-2c0.3-1.7,0.6-1.6,1.9-2.1
					c1-0.4,2.7-1.2,3.2-2.3c0.7-1.6,0.8-3.6,2.3-4.8c1.4-1.1,2.9-1.5,4.1-2.9c1.1-1.2,2.1-2.5,3.1-3.7c1.2-1.4,5.1-5.8,6.9-6
					c0.7-0.1,0.9,0.5,1,0.9c0.2,1.4-2.2,3.9-2.8,4.7c-2.4,3-4.8,6-7.2,9c-4.3,5.4-9.5,10-12.7,16.1c-1.7,3.2-3.7,6.2-5.7,9.2
					c-1.9,2.9-2.9,6-4.7,9c-1.9,3.2-3.9,6.1-5.2,9.6c-1.5,3.9-3.1,7.7-4.3,11.7c-1,3.3-1.6,6.5-2.4,9.8c-0.8,0.6-1.5,1.3-2.3,1.9
					c-0.1-0.3-0.2-0.6-0.3-1.1C1,1866.3,1.3,1860.1,2.1,1856.6z M2.4,1869.6c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0.1,0,0.2-0.1
					C2.7,1869.5,2.6,1869.5,2.4,1869.6z M111.8,1867.8c0.8-0.7,1.5-1.5,2.3-2.2c-0.4,0.7-0.7,1.3-1,2c-0.4,0.9-0.4,1.9-0.9,2.7
					c-0.2,0.5-0.7,0.9-1.1,1.3c-0.9,0.1-2,0.8-2.9,1c-0.5,0.1-1.2-0.2-1.2-0.6C106.8,1870.7,111.1,1868.3,111.8,1867.8z
					 M104.1,1882.7c-1.1,0.1-2.1,0-2.2-1c-0.2-1.8,2.2-3.9,3.6-5.2c1.1-0.9,3-1.8,4.5-2.8c0,0.3-0.2,0.7-0.1,1
					c0.1,0.9,0.5,1.8,0.7,3c0.1,0.4,0,1,0,1.6c-1.6,1.1-3.2,2.3-5,3C105.2,1882.5,104.7,1882.6,104.1,1882.7z M221.7,1771.6
					c-2.3,2.4-4.8,4.6-7.3,6.8c-4.8,4.1-8.7,8.6-13.1,13c-4.8,4.9-9.8,9.6-14.4,14.7c-1.9,2.1-4.1,5.6-5.9,7.7
					c-0.7,0.9-3.5,3.8-4.6,4l0,0c-0.3,0-0.5-0.1-0.5-0.5c-0.1-0.9,3.2-4.6,3.4-4.9c2.1-2.5,4.3-4.9,6.5-7.4
					c4.3-4.9,8.6-9.8,13.3-14.4s9.5-9.2,14.1-13.9c2.2-2.3,4.5-4.4,6.8-6.5c0.6-0.5,3.9-4.3,5-4.1c0.3,0,0.5,0.2,0.5,0.4
					C225.5,1767.8,222.1,1771.2,221.7,1771.6z M279.8,1843.3c-2,1.7-4.5,2.4-6.6,3.8c-2,1.4-4.5,3.1-7,3.4c-2,0.2-3.3-2.3-4.8-2.1
					c-1.2,0.1-3.2,1.6-4.4,1.8c-0.5,0.1-0.8-0.1-0.9-0.7c-0.2-1.2,2.1-3,2.5-3.6c0.8-1.2,0.7-1.8,1.1-2.9c0.5-1.5,1-2.4,1.6-3.2
					c0.9-1.1,1.8-1.3,2.9-2.2c2.5-2,4.9-4,7.3-6c3.3-2.8,6.6-5.6,9.9-8.4c1.5-1.3,3.1-2.6,4.7-3.9c-0.2,0.7-0.4,1.4-0.5,2.1
					c-0.7,3.7-0.6,7.6-0.2,11c0.2,1.3,0.4,2.8,0.6,4.3C283.3,1839.5,280.5,1842.6,279.8,1843.3z M394.2,1830.5
					c-2.6,2.3-5.4,4.5-8.2,6.6c-0.9,0.7-1.8,1.4-2.8,1.9c-0.6,0.3-1.5,0.3-2,0.8c-1.1,0.9-1.2,2.1-2.7,2.6c-0.2,0.1-0.3,0.1-0.5,0.1
					c-1.2,0.1-1.9-0.7-3.1-0.4c-0.6,0.1-2,1.4-2.9,1.5c-0.3,0-0.7-0.2-0.8-0.6c-0.1-0.7,2.3-2,2.9-2.5c1.6-1.1,3.2-2.4,4.8-3.6
					c2.9-2.3,5.9-4.7,8.5-7.4c1.2-1.2,3.3-2.7,3.7-4.4c0.2-0.7-0.3-2.9,0.6-3c1.2-0.1,0.8,1.9,2.1,1.7c0.8-0.1,2.3-2.3,2.8-2.9
					c1.3-1.3,2.9-2,4.1-3.3c0.6-0.6,1.1-1.3,1.7-1.9c-0.1,1.1-0.3,2.2-0.3,3.4c0,1.2,0.1,2.3,0.2,3.5
					C399.8,1825.4,397.1,1828,394.2,1830.5z M408.3,1825.1c-0.3,2-0.6,2.9-1,3c-0.5,0-1.2-1.2-1.5-3.4c0-0.3,0-0.6,0-0.9
					c0.4-0.6,1-1.1,1.4-1.7c0.2-0.3,1.6-2.4,2.5-4.3c0,0.2-0.1,0.4-0.1,0.6C409.1,1820.6,408.6,1822.8,408.3,1825.1z M411,1811.8
					c-0.2,1-0.4,1.9-0.7,2.9c-0.2-0.3-0.4-0.4-0.9-0.4c-1.1,0.1-2.4,2.6-3.1,3.5c-0.1,0.1-0.2,0.2-0.3,0.3c0.1-0.3,0.1-0.7,0.1-1
					c0.4-2.5,1-4.8,1.5-6.4c0.2-0.7,0.7-3.6,2-3.7c1-0.1,1.4,0.8,1.5,1.8C411.3,1809.9,411.1,1811.2,411,1811.8z M455.7,1752.8
					c2.6-2.7,5.4-5.4,8.2-8c2.6-2.5,5.4-4.8,8.1-7.1c3-2.5,5.9-4.9,8.8-7.4c0.9-0.7,3.7-3.3,5.7-3.5c1-0.1,1.7,0.3,1.9,1.9
					c0.3,2.3-1.5,6-3.9,9.8c-2.1,3.2-4.6,6.6-6.7,9.1c-2.6,3.2-5.1,6.5-7.9,9.6c-2.1,2.3-4.9,4.1-6,6.9c-0.6,1.7-0.6,2.6-2.1,4.1
					c-1,1-2.3,1.8-3.5,2.6c-3.3,2.4-6.1,5.3-9.2,8c-2.6,2.3-5.4,4.4-8.2,6.3c-1.2,0.8-3.3,3.1-5.3,3.3c-0.8,0.1-1.3-0.2-1.5-1.3
					c-0.3-2.2,1.7-6.3,2.6-8.2c1.5-3.1,3.4-5.8,5.2-8.7C445.9,1763.9,450.5,1758.2,455.7,1752.8z M416.4,1794
					c-0.7,0.1-1.8-1.3-2-2.5c-0.3-2.2,2.4-4.8,2.9-6.8c0.4-1.5,0.3-2.8,1-4.3c0.6-1.2,1.7-2.3,2.4-3.5c1.5-2.3,1.9-5.1,3.4-7.4
					c0.8-1.3,1.7-2.1,2.1-3.6c0.3-1.2-0.1-1.7,0.8-2.7c0.3-0.4,1.7-0.8,2.2-1.2c1.1-1,2.9-3.5,3.5-4.9c0.9-2,0.2-3.5,1.9-5.1
					c1.1-1,2.7-1.2,3.9-2.5c1-1.1,1.8-2.4,2.8-3.5s2-2.3,3.1-3.3c1.2-1.2,2.1-1.2,3-1.9c0.6-0.5,1.9-2.2,2.8-2.2
					c0.4,0,0.8,0.1,1,1.2c0.1,0.9-1.5,2.4-3.4,4.8c-1.2,1.5-2.4,3.7-3.6,4.9c-2.1,2.3-4.3,4.7-5.8,7.3c-2.4,4.2-5.2,8.2-7.8,12.3
					c-0.8,1.3-1.6,2.7-2.3,4c-1.3,2.5-2.7,4.2-4.1,6.7c-1.6,2.9-3.1,6.6-4.6,9.6C419.1,1790.6,418,1793.8,416.4,1794z"
              />
            </g>
            <g>
              <g>
                <path
                  className="st13"
                  d="M134.9,2173.8h-27.4v26.1h27.4v29.8c-7,3.2-17.7,5.4-29.5,5.4c-4.8,0-9.3-0.4-13.6-1.2
						c-29.7-5.3-46.6-28.5-46.6-61.8c0-37.5,22.5-63.7,65.1-63.7c19,0,34.8,4.8,48.7,11.8v-30c-13.4-4.8-30.5-8.8-51.1-8.8
						c-58.6,0-96.9,39.4-96.9,92.9c0,47.2,29.9,82.8,80.9,87.3c3.6,0.3,7.4,0.5,11.2,0.5c24.6,0,48.2-6.2,64.3-14.2v-48v-26.1H134.9
						z"
                />
                <path
                  className="st13"
                  d="M187.4,2196.3c0-38,20.9-66.7,57-66.7c35.6,0,56.2,25.7,56.2,67.7v7.5h-81.1
						c0.5,19.6,14.5,32.1,35.6,32.1c12.8,0,23-3.5,32.1-8.6l9.6,22c-13.4,8.3-29.2,11.8-44.7,11.8
						C205.9,2262.2,187.4,2230.6,187.4,2196.3z M268.3,2182.6c-0.5-14.5-7.5-28.6-24.4-28.6c-15.8,0-24.6,13.4-24.6,28.6H268.3z"
                />
                <path
                  className="st13"
                  d="M315.1,2195.8c0-38,25.4-66.1,63.2-66.1c37.7,0,63.5,28.1,63.5,66.1s-25.7,66.4-63.5,66.4
						C340.6,2262.2,315.1,2233.8,315.1,2195.8z M408.8,2195.8c0-23.3-10.4-40.4-30.5-40.4s-30.5,17.1-30.5,40.4
						c0,23.6,10.5,40.7,30.5,40.7C398.4,2236.5,408.8,2219.3,408.8,2195.8z"
                />
                <path
                  className="st13"
                  d="M461,2132.9h27l3.5,14.2c11.5-11.5,22-17.4,34.8-17.4c5.9,0,12.9,1.6,17.9,5.1l-11.3,27
						c-5.6-3.5-10.7-4.3-14.4-4.3c-8.3,0-15.3,3.5-25.7,12.6v89.4H461V2132.9z"
                />
                <path
                  className="st13"
                  d="M538.5,2285.7c0-13.9,9.6-24.9,22.8-30.5c-5.3-3.2-8.3-9.1-8.3-15.8c0-7.5,4.8-16.6,15.3-23.8
						c-13.4-7.8-21.2-22.5-21.2-39.4c0-23.3,17.1-46.6,51.7-46.6c11.3,0,20.1,2.7,25.2,5.6c1.4,0.5,2.1,0.3,3.2-0.5
						c6.7-6.7,15-11,30.5-11c2.9,0,6.7,0.3,8.5,0.5l-6.4,23c-2.9-0.8-6.4-1.3-9.4-1.3c-2.7,0-6.7,0.5-9.4,2.2
						c7.5,8,11.3,18.7,11.3,30c0,28.4-21.7,46.9-52.5,46.9c-5.9,0-11.3-0.8-15.5-2.1c-2.4,4-3.2,6.7-3.2,9.4
						c0,22.8,83.3,1.6,83.3,49c0,23-23,41.8-66.9,41.8C560.2,2323,538.5,2307.7,538.5,2285.7z M633.8,2284.7
						c0-18.2-39.3-14.5-56.5-21.1c-4,2.9-10.2,8.3-10.2,16.6c0,12.6,13.4,20.4,34.5,20.4C621,2300.5,633.8,2294,633.8,2284.7z
						 M622.1,2177.6c0-12.6-8.6-23.8-22.8-23.8c-12.6,0-21.9,8.3-21.9,23.6c0,12.6,8,23.6,22.2,23.6
						C612.4,2200.9,622.1,2192.8,622.1,2177.6z"
                />
                <path
                  className="st13"
                  d="M681.4,2096.5c0-10.7,8.3-19.3,19.5-19.3c11.3,0,19.8,8.3,19.8,19.3c0,10.4-8.6,19-19.8,19
						C689.6,2115.5,681.4,2107.3,681.4,2096.5z M684.8,2132.9H717v126.7h-32.1v-126.7H684.8z"
                />
                <path
                  className="st13"
                  d="M820,2247.5c-9.1,8.3-20.6,14.7-36.4,14.7c-24.1,0-42.1-16.1-42.1-40.4c0-17.1,8.3-27.8,21.4-34.5
						c8.8-4.6,19.8-6.7,32.7-7.2l21.7-0.8v-4c0-14.7-9.6-21.1-24.4-21.1c-12.6,0-24.1,4-33.2,8.8l-10.4-20.6
						c15-8.3,31.3-12.6,49-12.6c34,0,50.9,15.5,50.9,46v51.4c0,14.7,0.5,25.7,2.4,32.4h-26.8L820,2247.5z M800.2,2201.1
						c-8.3,0.3-14.2,1.6-18.5,4c-5.4,2.9-7.8,8-7.8,14.5c0,11,7.2,17.4,18.8,17.4c11.8,0,20.3-5.9,24.6-9.4v-27L800.2,2201.1z"
                />
                <path
                  className="st13"
                  d="M875.9,2132.9h27.3l3.2,14.2c13.4-10.4,27.6-17.4,43.4-17.4c20.1,0,40.7,9.9,40.7,46v83.8h-32.1v-73.9
						c0-15.5-3.2-28.1-19.5-28.1c-10.4,0-21.7,5.6-31.1,13.4v88.6h-31.9L875.9,2132.9L875.9,2132.9z"
                />
              </g>
              <g>
                <path
                  className="st14"
                  d="M-103.9,2016.1c-22.1,0-42.2,9-56.8,23.6c-3.8,3.8-7.2,8-10.2,12.4c12.7-8.5,28-13.4,44.4-13.4h80.3v80.3
						c0,16.4-4.9,31.6-13.4,44.3c21.7-14.4,36-39,36-66.9v-80.3L-103.9,2016.1L-103.9,2016.1z"
                />
                <path
                  className="st13"
                  d="M-146.9,2059.2c-22.1,0-42.2,9-56.8,23.6c-3.7,3.7-7,7.8-10,12.1c12.8-8.6,28.1-13.6,44.7-13.6h80.3v80.3
						c0,16.5-5,31.9-13.6,44.7c21.5-14.4,35.7-39,35.7-66.7v-80.3h-80.3V2059.2z"
                />
                <path
                  className="st14"
                  d="M-109.1,2101.7v80.3c0,44.4-36,80.3-80.3,80.3h-80.3V2182c0-44.4,36-80.3,80.3-80.3H-109.1z
						 M-215.3,2238.9c5.1-2.7,6.6-5.9,6.6-13.9v-23.7h-23.7c-9.2,0-14,1.9-16.8,6.6l-0.5,0.9h33.5v30.6L-215.3,2238.9z
						 M-181.6,2245.8c6-3.4,7.6-6.2,7.9-14.4v-65h-63.6c-10.3,0-13.3,3.4-16.2,7.8l-0.6,0.9h71.6v71.2L-181.6,2245.8z
						 M-146.7,2224.3c6.1-4.1,7.7-6,8.1-14.4v-78.6h-79c-10,0-14.4,4.6-16.8,8.1l-0.6,0.9h87.4v84.7L-146.7,2224.3z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="New_logo">
        <linearGradient
          id="SVGID_12_"
          gradientUnits="userSpaceOnUse"
          x1="12.5021"
          y1="374.602"
          x2="1362.5039"
          y2="374.602"
          gradientTransform="matrix(1 0 0 1 0 -156)"
        >
          <stop offset="2.165098e-07" style={{ stopColor: `#004B87` }} />
          <stop offset="0.1458" style={{ stopColor: `#004F87` }} />
          <stop offset="0.3021" style={{ stopColor: `#005C89` }} />
          <stop offset="0.4629" style={{ stopColor: `#00728B` }} />
          <stop offset="0.6405" style={{ stopColor: `#00778B` }} />
          <stop offset="0.7139" style={{ stopColor: `#037888` }} />
          <stop offset="0.7766" style={{ stopColor: `#0B7C7D` }} />
          <stop offset="0.8353" style={{ stopColor: `#19836B` }} />
          <stop offset="0.8916" style={{ stopColor: `#2C8C53` }} />
          <stop offset="0.9458" style={{ stopColor: `#459833` }} />
          <stop offset="0.9985" style={{ stopColor: `#63A70C` }} />
          <stop offset="1" style={{ stopColor: `#64A70B` }} />
        </linearGradient>
        <path
          className="st15"
          d="M189.7,49.5c-0.6,0.4-1.9,0.3-2.8,0.6c-1.2,0.4-2.3,1.5-4.1,1.2c-0.4-0.1-1-0.4-1.6-0.6
		c-1.6-0.3-6.4,1.2-7.7,1.6c-1.6,0.3-3.1,0.4-4.6,0.7c-2.3,0.9-5.2,0.6-7.7,1.5c-5.1,1.7-10.3,2.8-15.4,4.6c-2.2,0.4-4.4,1-6.5,1.5
		c-2.3,0.7-4.8,1.3-7.1,2c-1,0.4-2.6,1.5-4.1,1.5c-2.8-0.1-5.1-2.9-7.3-4.2c-0.9-0.6-2.6-1.7-4.4-1c-4.1,1.7-5.8,10.3-9.3,13.2
		c-2.5,2.2-6.8,2.9-9.7,4.5c-1.9,1.2-3.8,2.9-5.7,4.1c-3.5,2-7.4,3.3-11.2,5.4c-3.8,1.9-7.8,4.1-11.9,5.8c-1,0.3-2.2,0.4-3.2,0.7
		c-1.3,2.8,0.1,6.2-0.3,9.7c-0.1,1.6-0.4,3.2-0.9,4.5c-0.3,0.9-0.9,1.7-0.9,2.9c0,0.9,0.7,1.7,1.2,2.2c1.6,2,3.1,3.9,5.7,4.6
		c3.2,0.9,7.1-1.6,9.9-0.7c0.7,3.1-2.5,4.9-3.6,7c-1.2,2-1.5,4.4-2.2,6.8c-0.6,1.9-1.7,3.5-2.6,5.2c-1,1.9-1.3,3.9-2.3,6
		c-0.7,1.6-1.9,3.2-2.6,4.9c-1,2.2-1.7,4.2-2.8,6.2c-3.6,7.6-8.1,14.7-11.6,22.4c-1.6,3.5-3.2,7.1-4.8,10.6
		c-1.6,3.3-2.5,6.8-5.4,9.2c-2.3,1.7-6,2.3-8.7,3.6c-1.7,0.7-3.8,1.7-5.2,2.9c-0.7,0.6-1.5,1.5-2.3,1.7c-1.5,0.6-3.5-0.7-4.6-0.9
		c-2.5-0.3-6,1.5-7.7,2.2c-0.1,0.3-0.1,0.4-0.4,0.6c0.3,2,1.5,3.3,1,5.4c-0.3,1.3-1.2,2-1.6,3.2c-0.6,1.5,0.3,4.6,0.9,6
		c1.3,2.8,3.1,5.1,4.6,7.4c0.7,1.2,1.3,2.5,2.2,3.5c1.2,1.3,3.8,1.5,4.5,3.2c0.7,1.6-0.1,3.6-0.4,4.6c-0.9,3.2-1.7,6.1-2.6,9
		c-1.3,4.5-1.6,9.7-2.2,14.8c-0.3,2,0.1,3.9-0.1,6.1c-0.4,3.3-0.7,6.8-1.2,10c-0.3,1.7,0,3.5-0.1,5.2c-0.1,1.5,0.3,3.9,0.7,5.1
		c0.6,1.5,0.3,2.6,0.7,4.1c1,2.8,1.6,5.8,2.6,8.6c1,2.8,1.5,6.8,1.2,9.6c-0.1,1.7,0.6,4.5,1.3,5.5c0.9,0.7,1.7,1.6,2.5,2.3
		c0.1,0.1,0.4,0.1,0.6,0.3c1,0.9,1.9,2.2,3.2,2.8c1,0.4,2.2,0.4,3.3,0.6c1.5,0.3,3.1,0.7,4.4,1.5c5.7,2.9,10.2,7.8,16.6,9.6
		c5.2,1.5,11.8,0.3,17.6,0.9c1.6,0.1,5.1,0.4,7-0.3c5.5-2,10.6-3.8,16-6c4.5-1.7,9.4-2.3,14.1-3.9c0-0.1,0-0.3,0.1-0.4
		c0.4-1.2-1.2-0.6-1.5-2.2c1.3-1.6,1.6-3.9,3.3-5.1c2.8-1.9,6.8-1.9,10.3-3.3c7.7-3.2,16.6-5.2,23.4-9c2.6-1.5,4.9-2.8,7.4-4.2
		c1-0.6,2.5-0.9,2.8-2c-0.4,0-1,0.1-1.5,0.1c-0.6,0.4-1.6,0.6-2.3,0.9c-1.2,0.6-2.5,1.3-3.9,1.7c-1.5,0.6-5.5-0.6-6-1.2
		c0-0.3-0.1-0.7-0.1-1c0.4-0.3,0.7-0.7,0.9-1.3c-0.4-0.1-1,0-1.6,0c-0.6,0.4-1.3,0.4-2,0.7c-0.7,0.4-1.3,0.9-2,1.3
		c0,0.1,0,0.1,0,0.3c0.1,0,0.1,0,0.3,0c0.4,0.1,1.5,0.1,1.9,0.1c0.1,0.3,0,0.1,0.1,0.3c0,0.3-0.1,0.6-0.1,0.9
		c-0.9,0.7-3.9,2.6-6,2.3c-1.2-0.1-2.2-1.2-3.5-1.3c-1.5-0.3-3.1,0.3-3.5-1.2c1.9-1.2,4.9-1.5,7.1-2.6c2.3-1.3,3.9-3.6,6.2-4.9
		c1.6-0.9,3.9,0.1,5.7-0.9c0.9-0.6,1.5-1.7,2.5-2.2c0.7-0.1,1.6-0.4,2.3-0.6c1.2-0.4,2.5-1,3.6-1.6c6.7-3.8,14.7-6.8,21.9-10.2
		c4.4-2,8.9-3.6,13.1-5.8c1.3-0.7,3.8-1.3,4.1-2.8c0.1-0.1,0-0.1,0.1-0.4c-0.3,0-0.4-0.1-0.9,0c-0.9,0.7-2.2,0.7-3.2,1.3
		c-2,1-4.2,2-6.1,3.1c-0.7,0.4-1.5,0.4-2.3,0.9c-3.8,2-7.7,3.8-11.8,5.8c-3.3,1.7-6.5,3.5-11.2,4.1c-0.1-0.3-0.1-0.3-0.3-0.6h0.1
		c0.3-1,1.5-1.3,2.5-1.9c2.2-1.3,4.6-2.2,7-3.5c5.8-3.3,12.3-6.4,18.6-9.3c1.9-0.9,3.8-1.5,5.7-2.3c2.2-1,7.8-4.1,10.2-3.9
		c0,0.3,0,0.4,0.1,0.7c-0.3,0.3-0.4,0.7-0.7,1c-1.5,1.3-3.3,1.7-4.5,3.2c0.1,0.3,0,0.1,0.1,0.3c0.1,0.4,0,0.3,0.4,0.4
		c1.2,0.6,3.9-1.5,4.6-1.9c3.5-2,10.2-3.8,9.7-8.7c-1.3-1.3-1.7-3.5-1.5-5.5c0.9-0.7,2.9-1.6,3.2-2.8c0.1-0.3,0-0.7,0-1.2
		c-0.4-0.1-0.6-0.1-1.3-0.1c-1.3,1.2-3.6,1.9-5.1,2.9c-5.5,3.8-12.3,7.7-18.7,10.7c-2.8,1.3-6,1.6-8.6,3.1c-5.4,3.1-10.9,6-16.3,8.9
		c-3.2,1.7-6.8,2.9-10,4.5c-1.9,0.9-3.9,1.6-5.8,2.3c-1.3,0.4-2.8-0.1-3.9,0.4c-1.3,0.6-2.6,1.7-3.9,2.3c-1.6,0.9-3.2,1.6-4.6,2.3
		c-2.3,1.3-4.6,2-7,3.2c-4.8,2.5-9.9,4.6-15,6.8c-2.3,1-5.1,1.5-8.1,1.9c-1.7,0.1-3.5,0.4-5.2,0.6c-2.2,0.7-3.9,1.6-6,2.3
		c-0.7,0.1-1.5,0.1-2.2,0.3c-3.1,1-7.3,0.1-10.3,1.3c-2,0.7-4.1,1.3-6,2c-1.5,0.6-3.6,0.3-4.8,0.3c-4.6-0.1-9.3,1.2-13.2,0.4
		c-3.5-0.7-6.7-2-9.7-3.3c-5.1-1.9-8.4-2.5-10.9-7.8c-1.5-3.1-1.2-7.6-0.9-10.5c0-1.9,0-3.8,0-5.7c0.1-1,1.2-1.6,1.5-2.6
		c0.7-2.5,0.3-5.5,1-8.1c0.6-2,1.7-3.9,2.5-6c0.7-2.5,0.6-5.1,1.3-7.4c0.9-2.9,2.2-5.8,3.5-8.6c0.6-1.3,0.7-2.6,1.3-4.1
		c1-2.6,2.2-5.5,3.3-8.1c1.3-2.9,2.8-9,4.8-10.9c4.4-4.2,11.6-5.5,17.9-8.4c3.8-1.7,7.8-3.1,11.8-4.8c4.5-2.2,9-4.4,13.9-6.2
		c3.1-0.7,6.1-1.5,9-2.3c5.4-2,10.9-3.2,16.1-5.2c2.3-0.9,4.8-0.9,7-1.9c2.3-1,3.8-2.8,6.2-3.9c1.2-0.3,2.5-0.6,3.6-0.9
		c2.3-0.9,4.5-1.6,6.5-2.8c1.2-0.7,2.9-1.2,3.2-2.5c0.1-0.1,0.1-0.4,0-0.7c-2.9-1.5-10.5,1.2-13.5,2.2c-2,0.7-4.6,0-5.2-1.2
		c-0.1-0.1-0.1-0.4-0.3-0.9c3.5-2.6,11.5-2.8,16.3-4.5c1.6-0.6,3.5-0.3,4.8-1.2c0.7-0.4,0.9-1.3,1.5-1.9c-0.1-1-0.4-1.2-0.6-2.2
		c0.9-0.7,2.2-2,2-3.5c-1.3-0.6-4.2-0.6-6.1,0.1c-1,0.1-2,0.3-3.1,0.4c-1,0.4-3.3,0.9-4.6,0.7c-5.5-0.6-11.3,0.7-16,2.3
		c-1.5,0.3-2.9,0.4-4.4,0.7c-3.9,1.3-8,1.6-11.5,3.1c-3.8,1.6-8.6,4.1-13.7,3.6c-3.9-0.4-10.7,1.9-13.8,3.1c-1.7,0.6-4.2,1-4.5-0.7
		c0.7-1.2,0.7-2.8,1.3-4.1c1-2.2,2.3-4.4,3.1-6.7c0.6-2.3,0.4-4.8,1.6-6.7c0.9-1.6,2.5-3.1,3.5-4.6c2.2-3.9,4.4-7.6,6.7-11.5
		c0.9-1.6,2.3-2.9,3.2-4.5c2.5-4.2,5.1-8.1,7.1-12.5c1.5-3.2,2.5-6.5,4.1-9.6c2.5-4.6,5.8-9,8.3-13.7c2-3.8,3.6-8,7.6-10.2
		c2.6-1.5,5.7-1.9,8.6-3.1c4.2-1.9,8.7-3.6,13.2-5.2c2.6-0.6,5.2-1.2,7.8-1.7c3.9-1.5,7.6-3.1,11.3-4.5c1.6-0.4,3.2-0.7,4.9-1.2
		c3.8-1.3,7.6-2.5,11.3-3.8c6-1.5,11.9-3.1,18-4.5c4.2-1,8.4-2,12.6-3.2c2-0.7,4.5-0.6,6.4-1.2c2-0.7,5.8-0.4,5.7-2.8
		c-8.1-3.5-23.8,2.3-33.8,1.6c-0.1-0.1-0.1-0.1-0.3-0.3c0-0.1,0-0.3,0.1-0.4c1.2-0.7,3.3-1.7,5.5-1.6c0.6,0,1.7,0.1,2.5-0.1
		c4.2-1.5,10.2-3.5,15.5-3.1c1.2-0.1,2.5-0.3,3.6-0.4c1.6,0.1,3.3,0,4.8,0.3c2.2,0.4,8.3,2.6,9.6,0c0.3-0.4,0.3-1.7,0-2.5
		c-0.9-2.3-0.6-5.4-1.5-7.7C218.3,47.4,204.5,47.7,189.7,49.5z M764.5,18c-0.6,0.6-1.7,0.6-2.6,1c-1.9,0.9-3.6,1.9-4.8,3.3
		c-5.5,7-9.6,17.3-11.2,27.2c-0.1,1.2,0.1,2.6,0,3.8c-0.3,1.6-1.3,4.9-0.4,7c0.7,1.9,2.2,3.2,3.3,4.6c3.1,3.6,4.5,5.7,9.9,6.5
		c6.8,1,8.9-3.8,10.9-7.4c1-1.6,2.5-2.9,3.5-4.5c0.4-0.4,0.7-0.9,1.2-1.3c0.7-1.3,1.6-2.8,2.8-3.9c0.9-0.7,1.7-1.7,2.3-2.6
		c1.2-1.9,2-4.1,3.5-5.8c2.2-2.5,4.5-4.8,5.5-8.3c1.6-5.5-2-8.1-4.4-10.7c-1.3-1.5-2.2-2.9-3.5-4.4C777.6,19.7,770.2,17.4,764.5,18z
		 M637.1,101.5c-1,0.4-2,0.7-3.1,1.2c-1.5,0.9-3.5,2.3-4.4,3.8c-2.2,3.8-3.5,8-4.9,12.5c-1.3,4.5-3.3,9.3-5.4,13.4
		c-1.2,2.6-2.8,4.9-4.1,7.4c-1.9,3.5-3.5,7.3-5.2,10.9c-1,2.2-1.7,4.5-2.8,6.7c-2.8,5.8-5.4,11.8-8.1,17.7c-1.3,2.9-2,6.1-3.3,9.2
		c-2.6,5.7-6,10.9-8.9,16.3c-1.2,2.2-1.9,4.6-3.2,6.8c-0.7,1.3-2.6,1.9-3.8,2.8c-2.3,1.9-4.9,3.5-7.4,5.4
		c-7.6,6-15.8,11.6-24.4,16.7c-2.6,1.6-5.1,2.6-7.8,4.2c-4.6,2.6-10,4.9-15.1,7.3c-3.6,1.3-7.3,2.6-10.7,3.8
		c-3.9,1-7.7,2.2-11.6,3.2c-1.5,0.6-3.1,0.4-4.5,1c-0.7,0.1-1.6,0.1-2.3,0.3c-3.9,1.3-9.6,1-12.6,0.1c-1.2-0.3-2.3-0.4-2.9-1.3
		c-2-2.2-0.1-4.1-0.9-6.4c-0.4-1.3-1.2-2.6-1.6-3.9c-0.7-1.7-0.3-4.1,0-5.4c0.3-1.5,0.1-2.6,0.6-3.9c0.6-2.3,1.5-4.6,2.2-7
		c0.7-2.3,0.7-5.5,2.6-7c0.9-0.7,2.8-0.9,3.9-0.7c1.2,0,2.3-0.1,3.5-0.1c1.6,0.1,3.5,0.4,5.4,0.3c3.9-0.3,9.7-3.5,12.5-5.1
		c7.4-4.4,13.2-10.2,19.2-15.7c1.6-1.5,3.1-3.3,4.6-4.8c3.9-3.8,7.8-7.6,11.6-11.3c1.7-2.3,3.5-4.6,5.2-7c1.6-1.7,3.2-3.5,4.6-5.4
		c1.9-3.2,3.6-6.4,5.4-9.7c1.7-3.5,3.6-7.6,4.4-11.8c0.3-2.2-0.3-5.2-1-7c-1.5-3.6-2.6-6.7-4.9-9.2c-0.6-0.6-1.5-1.2-2-1.7
		c-0.6-0.6-1-1.2-1.6-1.6c-6.1-3.5-16.3-8.1-26.6-4.5c-1,0.1-1.9,0.3-2.9,0.3c-3.2,0.4-6.5,0.6-8.7,1.7c-2.5,1.5-3.9,3.6-5.7,5.4
		c-2.9,2.8-5.4,6-8.3,8.7c-0.9,0.9-1.5,2.2-2.5,3.1c-2.2,2-4.2,4.6-5.7,7.3c-0.7,1.3-2,2.5-2.8,3.8c-2.2,3.8-4.6,7.4-6.8,11.3
		c-3.2,5.5-7,10.7-10,16.4c-4.9,9.2-9.2,19-12.2,29.6c-1,3.6-1.6,7.7-2.2,11.6c-0.1,1.3,0,2.5-0.1,3.9c-0.3,2-0.3,5.4,0.4,7.6
		c0.1,0.7,0.1,1.6,0.3,2.3c0.3,2.5,0.3,5.1,1,7.1c0.1,0,0.1,0,0.3,0c0.4,0.1,1.5-0.3,2.2-0.3c0.6,0.9,1.5,1.5,1.9,2.5
		c0.7,1.2,0.7,2.5,1.3,3.8c1.2,2.9,2.2,6.4,3.8,9c1,1.6,2.6,2.6,3.9,3.9c3.6,3.8,8.4,6.8,13.1,9.2c3.5,1.6,7.7,1.7,12.2,2.2
		c2,0.3,4.2-0.3,6.2-0.1c2.3-0.3,4.8-0.6,7.1-0.9c3.5,0.3,6.5,0.1,10,0.4c2.2,0.3,7.1-1.3,8.7-1.9c1.7-0.7,3.3-0.9,4.9-1.6
		c4.6-2.2,9.3-4.4,13.5-6.8c3.6-2,7.3-3.8,10.3-6.2c2-1.6,4.4-3.1,6.4-4.8c1-0.9,2-2.2,3.3-2.8c0.4,3.5,2.9,5.2,4.2,7.7
		c0.6,1.2,0.7,2.9,2,3.2c0.6,0.3,2.2-0.1,2.6,0.3c0.6,0.6,0.3,2.8,0.3,3.9c0.3,0,0.6,0,1,0c0.7-0.9,1.6-0.1,2.8-0.1
		c0.3-0.7,0.9-2.3,1.6-2.8c0.1-0.1,0.1-0.1,0.4-0.1c0.1,0.3,0,0.1,0.1,0.3c-0.3,2.6-0.4,7.3,3.1,6.8c0.4-0.6,1.2-0.9,1.6-1.5
		c0.9-1.6,1.2-3.3,1.9-4.9c0.4-0.9,1.6-2,1.6-3.2c0-0.4-0.6-1-0.6-1.9c0.1-1.6,0.4-3.2,0.9-4.6c1.2-3.6,2.8-7.1,4.2-10.5
		c1-2.3,1.7-4.6,2.9-6.8c1.7-3.6,3.9-7.1,5.8-10.6c2.3-4.4,4.5-8.7,6.7-12.9c1-1.7,1.9-3.8,2.6-5.7c0.4-1.2,0.6-2.6,1.2-3.5
		c0.6-1,2.8-1.3,3.8-1.9c1.2-0.7,2.5-2.3,3.2-3.5c2-3.6,4.1-7.1,6.1-10.7c1.5-2.6,3.3-4.8,4.9-7.4c0.4-0.6,1-1.2,1.5-1.6
		c1.6-2.6,3.6-5.1,5.5-7.4c0.7-0.9,1.5-1.6,2-2.5c1.2-2,2.8-3.8,4.2-5.5c1.7-2.3,3.5-4.6,5.5-6.7c0.4-0.6,0.7-1,1.2-1.6
		c2.3-2.2,4.2-4.8,6.4-7c4.5-4.2,8.9-8.4,13.5-12.6c2-1.7,4.1-3.5,6.1-5.4c1-0.9,1.6-2,3.6-2.3c0.1,0.3,0.1,0.4,0.3,0.9
		c-0.6,0.9-0.6,2-1.2,3.1c-1.3,2.9-1.9,6.4-3.2,9.3c-1.2,2.5-2.8,4.5-4.1,6.8c-0.9,1.7-1.5,3.6-2.3,5.4c-1,2-3.1,4.4-3.1,7.1
		c0.4,0.1,0.6,0.3,1.2,0.1c0.6-0.7,1.2-1.5,1.7-2.2c1,0.1,1.2,0.6,2.5,0.4c0.7-0.7,1.7-1.9,2.9-2.3c0.1,0.1,0.1,0.1,0.3,0.3
		c-0.4,1.5-1.3,2.5-1.5,4.1c0.1,0.1,0,0,0.1,0.3c0.1,0,0.1,0,0.3,0c1,0.4,3.2-1,3.8-1.5c3.5-2.2,6.2-4.8,9.2-7.4
		c2-1.9,4.1-3.6,6-5.5c0.7-0.6,1.2-1.6,1.7-2.3c0.6-0.6,1.3-1.2,1.7-1.9c3.3-5.7,6.1-11.8,8.1-18.3c1.6-5.2,3.3-14.7-2.5-17.9
		c-1.5-0.9-3.8-0.1-5.7-0.6c-0.7-0.9-1.3-2.3-2.5-2.8c-4.1-2-11.6,1-15,2.8c-3.3,1.9-6,4.4-8.7,6.7c-3.9,3.3-7.8,6.8-11.6,10.3
		c-1.9,1.7-3.8,3.5-5.8,5.2c-0.9,0.7-1.6,1.9-3.2,2.2c-0.3-0.3-0.4-0.4-0.6-1c0.6-1.2,0.7-2.6,1.2-3.6c0.9-1.9,2.2-3.8,2.3-6.1
		c0.3-2-1.7-5.8-2.5-7C652.5,102.5,646.7,100.3,637.1,101.5z M189.9,335.4c0.1,1.3-0.1,2-0.6,3.1c-0.7,1.9-2,3.8-2.5,6
		c-0.6,3.8,1.7,6.8,4.9,6.5c0.7-0.6,1.7-0.9,2.3-1.5c2.3-2,3.9-7.4,5.5-10.3c0.3-0.4,0.7-0.7,1-1.2c1.6-2.8,3.8-5.1,5.2-7.7
		c4.1-7.3,7.6-15,11.5-22.2c1.3-2.5,3.1-4.8,4.5-7.3c0.6-0.7,1.2-1.3,1.7-2c2.2-3.8,5.5-7,7.7-10.7c1-1.7,1.6-4.4,4.4-4.6
		c0.1,0.1,0,0,0.3,0.1c0.7,0.9,2.3,3.8,3.1,4.2c0.7,0.4,1.6,0.3,2.5,0.6c0.7,0.3,1.3,1,1.9,1.6c1.5,1.5,4.5,3.5,4.9,6h-0.1v0.1
		c-2.3-0.9-4.4-4.9-6.7-4.9c0,0.3,0,0.6,0,1c0.6,0.6,1,1.3,1.6,1.9c0.4,0.6,1.3,0.9,1.7,1.6c0.6,1,0.7,2.9,2.3,2.9
		c0.7-1,2-1.6,3.5-1.6c0.3,0.3,0.7,0.7,1.2,1c0.3,1.7-0.3,2.6-0.3,4.1c0.9,0.4,1.6,1.2,2.5,1.6c2.3,1.2,6,1.9,8.6,2.3
		c6.4,1.3,15.8-0.1,23.8-1.2c4.2-0.6,9.7-0.4,13.4-1.9c5.1-1.9,8.9-4.2,13.1-6.7c0,0.1,0,0.1,0,0.3c-1,1-1.2,3.1-1.9,4.4
		c-1.5,3.1-2.6,6.5-4.1,9.7c-0.4,1-0.4,2-1,3.1c-5.2,11.6-10,23.2-15.7,34.6c-1.2,2.3-2.3,4.8-3.5,7.1c-0.4,1-0.6,2-1.2,2.9
		c-1.2,2.3-3.6,3.2-3.2,6.8c0.9,0.4,2.2,0.6,3.2,1c0,0.3,0,0.1-0.1,0.3c-0.7,0.9-2.3,0.7-3.5,1.3c-1,0.4-2,1.5-2.6,2.2
		c-0.9,1.6-1.6,3.6-2.2,5.4c-0.3,1.2-0.4,2.5-0.9,3.5c-0.6,1.2-1.7,2.3-2.2,3.5c-0.7,1.5-0.3,3.6-2.5,4.1c-0.6-0.7-1.2-2.2-2.3-2
		c-0.1,0.1,0,0-0.3,0.1c-1,3.1-2.3,11.5-0.1,15.3c0.9,1.3,2.8,1.7,3.3,3.3c1,2.5-0.4,5.4,0.7,8.1c0.7,1.7,2,1.7,3.6,2.5
		c0.9,0.4,1.5,1.6,2,2.3c0.4,0.4,1.2,0.7,1.7,1.2c2.2-0.3,2-1.5,3.8-1.7c0.9,1.3,1.7,3.8,3.8,3.8c0.3-0.4,1-0.6,1.2-1
		c2-3.3,2.5-7.8,3.6-11.8c1.5-4.6,2.9-9.2,4.9-13.5c3.3-7.3,6.1-15.1,9.4-22.5c7-15.1,13.8-30.7,20.9-45.6c1.2-2.6,2.2-5.1,3.3-7.7
		c1.2-2.6,2.9-5.1,4.1-7.6c0.7-1.6,0.1-4.8,1-6.2c0.7-0.6,1.5-1.3,2.2-1.9c1.2-2,0.7-5.8,3.8-6.4c0.6,0.4,1.3,0.4,1.9,0.9
		c1.3,0.7,2.2,1.9,3.8,2.2c2,0.4,5.1,0,7.1-0.7c1.5-0.6,3.5-0.3,4.8-0.7s2.5-0.3,3.6-0.7c1.3-0.6,2.5-1.6,3.6-2.2
		c1.6-0.9,3.5-1.3,5.1-2.2c4.2-2.5,7.8-5.8,11.3-8.7c5.5-4.5,10.5-9.2,15.8-13.8c2.5-2.2,5.2-3.9,7.7-6.1c2.6-2.3,3.8-6.1,6.4-8.4
		c2-1.9,3.8-3.8,5.7-5.7c1.3-1.3,2.2-3.2,3.5-4.4c5.4-5.1,9.7-11.8,13.5-18.2c1.5-2.3,4.6-3.5,6-6c4.6-8,8.9-15.8,11.6-25.3
		c0.9-3.1,1.3-6,2-9.2c1-4.2,3.1-12.5,1-18.2c-0.6-1.7-0.4-3.6-0.9-5.2c-0.1-0.7-0.1-1.6-0.3-2.3c-0.9-2.5-0.9-5.5-1.9-7.6
		c-0.6-1-1.3-2.5-2.2-3.5c-1.5-1-2.9-2-4.4-3.1c-1.7-1.7-3.3-4.4-5.5-5.5c-1.9-1-3.8-0.9-6-1.5c-1.7-0.6-3.1-1.6-5.1-1.9
		c-11-1.7-21.9,6-27.7,11.2c-2.2,1.9-6.1,7.1-9.3,7.7c-2.3-3.2,0-8.6-1.5-12.2c-1.5-3.8-8.3-8.1-12.5-8.6c-2.5-0.3-4.6,1-5.8,1.7
		c-3.9,2.3-6,5.8-6.5,10.9c0.1,2,0.4,4.1,0.6,6c-0.1,2,0,3.9-0.6,5.5c-1,3.5-3.1,6.5-4.1,10c-0.9,2.6-1.2,5.2-2,7.8
		c-1.7,5.5-4.1,10.9-6.1,16.3c-2.2,5.8-3.3,12.5-6.1,17.7c-0.9,1.6-2.2,3.2-3.2,4.8c-1,1.9-1.9,4.1-2.8,6.1c-0.3,1-0.6,1.9-0.9,2.9
		c0,0.6,0,1.3,0,1.9c-0.4,1-1.7,1.3-2.6,2c-1.2,1-0.9,2.8-1.5,4.4c-0.6,1.9-2.8,3.1-2.8,5.4c1.6,1.2,0.9,6-0.9,6.4
		c-0.6,0.4-2,0.1-2.8,0.4c-0.1,0.7,0,1.9,0.1,2.8c-0.4,1.2-0.7,2.5-1.2,3.6c-1.5,3.8-2.5,7.7-4.1,11.3c-1,2.2-3.8,3.2-5.1,5.5
		c-1.3,2.3-2,4.9-3.2,7.4c-1.9,3.9-5.8,10-9.3,12.6c-4.8,3.8-15.8,3.5-21.5,2.5c-3.3-0.7-6.4-1-9.2-2.3c-3.8-1.7-7-4.8-9.9-7.8
		c-1.5-1.5-3.2-2.8-4.5-4.2c-3.6-4.1-7.8-11.2-9.9-16.9c-1-2.9,1-5.1,2-6.5c3.3-4.4,6.8-8.6,10.2-12.9c3.9-4.9,7.6-10.3,12.2-14.8
		c1.6-1.6,2.8-3.5,4.4-5.1c1.2-1.2,2.8-3.3,3.6-4.9c0.9-1.2,1.7-2.2,2.8-3.3c2.2-2.8,4.2-5.7,6.7-8.1c2.5-2.3,4.4-5.2,7-7.4
		c1.6-1.3,3.6-2,5.2-3.3c1.5-1.2,2.8-3.1,3.6-4.6c1-1.7,1.7-4.2,3.2-5.5c3.5-3.3,6.5-6.7,7.3-12.2c0.4-2.9-1.5-4.5-2.2-6.5
		c-0.4-1.3-0.1-3.2-1.3-3.5c-0.4,0-1,0.1-1.5,0.1c-0.3,0.3-0.7,0.1-1.2,0.4c-0.6,0.3-1.6,0.9-2.8,0.7c-1-0.1-2.5-0.4-3.6,0
		c-1,0.4-1.9,1-2.6,1.6c-2.3,2-4.2,5.1-5.7,7.8c-1.2,1.3-2.2,2.8-3.3,4.1c-0.6,0.7-1.2,1.5-1.6,2.3c-1.6,1.6-2.8,3.3-4.1,5.1
		c-1,1.2-2,2.3-3.1,3.5c-0.7,0.9-1.3,1.9-2,2.8c-1.9,1.7-3.3,3.9-4.9,6c-0.4,0.6-0.9,1.2-1.3,1.7c-2.2,2.2-3.9,4.8-5.8,7.3
		c-3.2,4.1-6.5,8.1-9.7,12.3c-4.2,5.4-8.1,10.7-12.3,16.1c-1.5,1.9-2,4.9-5.2,5.4c0-0.1-0.1-0.1-0.1-0.3c-0.9-0.9-0.4-3.3-0.9-4.8
		c-1.2-3.2-1.6-7.1-2-11.2c-0.6-4.8-1.3-10.2-0.9-14.4c0.3-2.3-0.3-4.8-0.1-7c0.3-3.3-0.7-6.7-0.3-9.9c0.3-2.3,0.7-4.6,0.9-7.1
		c-0.1-1.3-0.3-2.6-0.4-3.9c0.1-1.6,0.7-3.6,0-5.2c-2.8-1.2-5.5,1.2-8.4,0.9c-1.5-0.1-3.1-0.1-4.5-0.3c-4.1-0.4-8.7,4.2-10.7,5.8
		c-3.5,2.8-5.2,4.2-6.5,8.7c-1.2,4.4-0.4,9.9-1,14.8c-0.3,2,0.3,4.5,0.6,6.8c0.4,3.2,0.6,6.2,0.3,9.2c-0.1,1.6,0.4,3.6,1.6,3.6
		c0.1-0.4,0.6-0.9,0.7-1.5c0.6-3.1-1.6-8.1,1-9.4c0.1-0.1,0.3-0.1,0.6-0.1c1.6,1.9,1.7,4.6,1.5,7.6c-0.1,1.5,0.4,4.9,0.9,6.1
		c0.1,1,0.3,2,0.4,3.1c0.1,1.7,0.6,3.2,1,4.5c0.1,0,0.1,0,0.3,0c0.1-0.7,0.4-1.5,0.6-2.5c0.1-1.6-0.6-3.1-0.1-4.2
		c0.4-1.6,2-2.6,2.5-4.4c0.4-1.6,0-3.5,0.3-5.4c-0.1-0.9-0.1-1.7-0.3-2.6c0.1-2.2-0.4-6,1.5-6.8c0.3-0.1,0.3-0.1,0.7-0.1
		c1.6,2.2,1.5,6.4,1.9,9.9c0.3,2.3,0.9,5.4,0.6,7.7c0.4,3.5,1,7.1,1.5,10.6c0.1,1.2,0.3,2.2,0.4,3.3c0,0.7-0.3,2.3,0,3.1
		c0.7,2.5,1.7,4.6,2.6,7c0.1,1.2,0.4,2.3,0.6,3.5c0.1,0.4,0.6,1.7,0.4,2.5c-0.1,1.6-0.7,3.2-1.9,4.1c-0.9,0.7-1.7,0.3-2,1.5
		c-0.6,1,0.3,2.8-1.3,3.1c-1-1.3-1.7-2.8-2.3-4.6c-0.7-1.9-0.4-4.5-1-6.1c-0.3-0.1-0.3-0.1-0.9-0.1c-1,2.5,0.1,6.5,1.2,9.3
		c0.4,1.3-0.1,2.5,0,3.9c0.4,0.1,1,0.4,1.5,0.7c-0.3,1.6-2,2.8-2.8,4.1c-1.7,2.9-4.4,5.5-6.1,8.4c-0.9,1.6-1.9,3.1-3.1,4.4
		c-0.4,0.6-1,1.2-1.5,1.6c-1.3,2.2-3.2,3.8-4.5,5.8c-3.3,5.8-8.3,10.6-11.6,16.6c-2,2.5-3.9,4.8-6,7.3c-1.3,2.3-2,4.8-2.9,7.3
		c-0.9,2.2-1.5,4.5-2.3,6.7c-0.9,2.5-2.2,4.9-1.9,8.3c0.4,0.1,0.4,0.3,1,0.3c0.9-0.9,1.9-1.9,2.6-2.9
		C189.4,335.4,189.7,335.4,189.9,335.4z M775.4,207.4c-3.2,0.4-4.6,4.4-8.7,4.9c0-0.1,0-0.3,0-0.4c2.2-2.5,4.6-5.1,7.3-7.1
		c0.9-0.4,1.7-0.9,2.6-1.2c0.6-0.9,1.3-1.9,1.9-2.8c0.4-0.4,1-0.6,1.3-0.9c1.9-1.7,1.2-4.8,4.8-5.4c0.3,3.1,0.3,6.4,1.2,8.7
		c0.1,0,0.1,0,0.3,0c0.4,0.1,1.5-0.3,2.2-0.3c0.6,0.9,1.5,1.5,1.9,2.5c0.9,1.6,1,3.3,1.7,5.1c1,2.5,1.9,5.5,3.3,7.7
		c1,1.6,2.6,2.6,3.9,3.9c3.6,3.8,8.4,6.8,13.1,9.2c3.5,1.6,7.7,1.7,12.2,2.2c2,0.3,4.2-0.3,6.2-0.1c2.3-0.3,4.8-0.6,7.1-0.9
		c3.5,0.3,6.5,0.1,10,0.4c2.2,0.3,7.1-1.3,8.7-1.9c1.7-0.7,3.3-0.9,4.9-1.6c4.6-2.2,9.3-4.4,13.5-6.8c4.9-2.9,9.7-5.5,14.1-9
		c2.6-2,5.2-4.1,7.7-6.2c1.2-1,2.2-2.6,3.6-3.2c0.7,0.4,2,0.1,2.6,0.7c1.2,1.3,1.2,3.9,2.3,5.2c0.9,1,2.8,1,3.6,1.9s0.9,3.1,2.5,3.1
		c1.6,0.6,3.2-3.3,5.5-3.8c0.4,0.3,1,0.6,1.7,0.6c0.1-0.1,0.4-0.3,0.6-0.3c0.6-1.3,1.2-4.1,2-4.9c2.9-3.2,6.5-4.6,8.7-8.6
		c1.6-2.8,2.6-5.7,4.2-8.4c0.9-1.6,2-3.1,3.1-4.6c3.5-4.4,7-8.9,10.6-13.2c1.5-2.5,3.3-4.5,5.1-6.7s3.2-4.5,5.1-6.4
		c1.6-2.2,3.2-4.2,4.8-6.4c1.2-1.2,2.3-2.5,3.3-3.8c0.4-0.6,0.9-1.2,1.3-1.7c4.5-4.4,8.3-9.6,12.8-13.9c1.7-1.7,3.3-3.9,5.1-5.7
		c3.9-3.6,7.3-7.7,11.2-11.3c1.9-1.7,3.5-4.9,6.5-5.2c0,0.1,0.1,0.3,0.1,0.4c0.9,1.2-1.5,4.9-1.9,5.8c-1.9,3.6-4.4,6.7-6.4,10.2
		c-0.7,1.5-2.2,2.6-2,4.6c0.4,0.1,0.6,0.1,1.3,0.1c0.9-1,2-1.7,2.8-2.9c1.2-2,2-3.9,3.2-6c0.3-0.7,1-1.3,1.5-1.9
		c0.7-1.6,0.6-3.6,1.7-4.8c1-0.9,2.3-1.5,3.5-2.3c1.3-1.2,1.6-2.8,3.9-3.2c0.1,0.1,0.1,0.3,0.3,0.4c-0.1,1.9-1.2,3.1-1.9,4.4
		c-0.9,1.7-1.6,3.6-2.3,5.4c-0.7,2.2-1.5,4.4-2.2,6.5c-0.3,1.2,0.6,1.7,0,2.6c-0.4,0.9-1.5,1.6-1.9,2.5c-0.7,1.3-1.2,2.8-1.9,4.1
		c-0.6,1.2-1.6,2.3-2,3.5c-0.4,1.3-0.7,2.6-1.2,3.8c-0.3,0.4-0.9,0.9-1,1.3c-0.3,0.7,0.1,1.3,0,2.2c-0.3,1.5-3.2,6.2-4.6,6.7
		c-0.1-0.4-0.3-1-0.3-1.6c0.6-0.9,0.6-2,1-2.9c0.6-1.2,1.3-2.6,1.7-3.9c0.3-0.9,0.6-2.2-0.6-2.3c-0.4,0.6-1.2,1-1.7,1.6
		c-0.6,0.6-0.9,1.3-1.3,1.9c-1.2,1.9-2.8,3.5-3.8,5.5c-0.9,2.2-1.7,4.2-2.6,6.4c-0.7,1.5-2.3,2.3-3.1,3.8c-0.6,1.2-0.4,2.5-0.9,3.8
		c-0.6,1.9-1.5,3.5-1.9,5.7c-0.3,2.2,0.3,4.4,0.1,6.8c-0.1,1.6-0.3,4.1,0.4,5.7c0.9,2,2.8,3.1,3.8,4.9c1,1.9,0.7,5.2,1.9,7
		c0.7,1.3,2.9,1.2,3.8,2.2c1,1,1.5,2.8,2.3,3.9c1.2,1.3,3.3,1.3,5.1,1.9c1,0.3,2,1.2,3.2,1.6c2.9,0.9,6.2-1.3,9.3-1
		c2,0.1,6.4,1.5,8.9,0.6c2.9-1,4.4-2.8,6.8-4.1c1.5-0.9,3.1-1.2,4.6-1.9c3.6-1.7,7.4-3.9,10.2-6.4c1-0.9,2-2.3,2.8-3.5
		c0.9-1.6,1.3-3.5,2.8-4.6c1.5-1.3,3.5-1.9,4.8-3.1c1.2-1,2.2-2.5,3.3-3.5c5.2-4.8,10.7-9,16-13.8c2.6-2.3,4.8-5.1,7.6-7.3
		c0.1,1.2,0.3,2.2,0.4,3.3c0.4,0.6,0.1,2,0.4,2.9c0.6,1.5,0.9,3.2,1.6,4.5c2,3.6,4.8,6.4,6.8,9.9c1.5,2.6,2.2,6,3.9,8.3
		c3.5,4.5,9,7,14.1,9.7c3.1,1.6,5.7,3.9,9.2,4.9c2.8,0.9,5.7,0.4,8.9,0.9c4.2,0.6,8.1,0.7,12.6,1.2c2.2,0.3,4.8-0.9,6.2-1.5
		c1.9-0.9,3.5-1.2,5.2-2.2c2.6-1.5,5.4-3.1,7.7-4.5c3.5-2,6.8-4.2,10.3-6.1c3.1-1.7,7-2.6,10-4.4c1.2-0.7,2.3-1.7,3.2-2.5
		c2.3-1.7,5.1-2.9,7.3-4.8c3.6-2.9,6.8-6.5,10.2-9.7c1.9-1.7,3.3-3.5,4.5-5.8c1.2-2.5,1.7-5.1,3.2-7.4c0.6-0.9,2.8-3.9,1.5-5.2
		c-0.1-0.1-0.1,0-0.4-0.1c-0.4,0.6-1.2,1.3-1.6,2c-0.6,0.9-0.9,2.2-1.6,2.9c-1.3,1.3-2.8,2.3-4.2,3.6c-0.9,0.7-1.3,1.9-1.9,2.9
		c-0.6,0.6-1,1.2-1.6,1.7c-1.6,0.1-2.3,0.3-3.6,0.7c-0.1,0.3,0,0.7-0.1,1s-0.7,0.4-0.9,0.7c0.1,0.3,0.1,0.6,0.3,0.9
		c-0.1,0.4-1,0.9-1.3,1.2c-1.5,0.1-1.3-0.3-2.5-0.4c-1.2-0.3-2,0.7-2.5,1c-1.5,1-3.1,1.7-3.8,3.2c-0.1,0.3-0.1,0.7-0.3,1
		c-0.3,0.3-0.9,0.4-1,0.9c-1.6,0.1-2.5-0.6-3.9,0c-2,0.9-4.1,2.3-6.1,3.1c-0.7,0.3-1.9,0.4-2.5,0.1c-0.1-0.1-0.1-0.4-0.3-0.9
		c0.6-0.9,0.3-2.2,1-3.1c1.6-2.2,6.4-4.6,9-6.1c1.6-0.9,3.2-2.3,4.8-3.2c3.1-1.7,6-3.3,8.9-5.4c1.5-1,3.1-2.3,4.5-3.2
		c3.8-2.2,10.9-5.8,8.9-11.6c-0.1,0-0.3,0-0.4-0.1c-0.7-0.3-2.9,0-3.5,0c-0.1-0.3-0.1-0.3-0.3-0.6c1-0.9,3.6-3.3,3.5-5.1
		c-0.1,0-0.1,0-0.3,0c-0.6-0.3-1.2,0.7-1.5,1c-1.3,1.2-2.2,2.5-3.3,3.6c-1.6,1.7-3.8,2.9-5.7,4.4c-3.5,2.8-7.4,5.5-11.2,8.1
		c-13.1,8.9-28.9,15.3-44.2,22.4c-2.5,1.2-5.5,1.9-8.4,2.9c-1.7,0.3-3.3,0.4-5.1,0.7c-1.3,0.4-4.4,1.7-6.2,1.5
		c-4.2-0.6-7.7-2.8-11.2-4.2c-1.3-0.6-3.2-0.4-4.5-1c-2-1-3.9-7.3-3.5-11c0.3-3.1,0.4-6.2,0.7-9.3c0.7-3.6,1.6-7.3,2.5-10.7
		c0.4-2,0.6-3.9,1-6c2-7,5.4-12.9,8.7-18.9c0.6-1.2,1.6-2.2,2.3-3.3c1.3-2.5,2.5-5.1,3.8-7.6c0.7-1.2,1.9-2.2,2.9-3.2
		c1.3-1.3,2.5-3.1,3.6-4.5c5.5-7.1,11-14.1,18-20c2.5-2,4.8-4.4,7.3-6.4c1.9-1.5,4.5-2.2,6.7-3.5c1.7-1,3.1-2.5,5.5-2.9
		c0.4,0.4,0.6,0.6,0.7,1.6c-0.7,1.3-0.9,2.8-1.6,4.1c-3.1,5.8-6.5,11.5-9.6,17.1c-1.2,2.2-2,4.2-3.2,6.2c-0.7,1.2-2.2,2.2-3.2,3.2
		c-0.6,0.7-1.2,1.5-1.6,2.3c0,0.3,0,0.4,0.1,0.7c0.6,0.4,1,1.2,1.7,1.6c1,0.6,2.3,1.2,3.1,2c1.9,1.9,3.5,4.4,6.5,4.8
		c1.5,0.1,3.1-0.6,3.8-1.2c1.5-1.2,2.3-2.6,3.5-3.8c1.7-1.7,3.5-3.6,4.8-5.8c0.9-1.5,2.3-2.6,3.2-4.1c0.9-1.5,1.7-3.3,3.1-4.5
		c1.7-1.6,4.1-3.5,5.2-5.5c1.3-2.2,1.9-4.6,2.6-7.3c0.7-2.5,1.6-6.1,0.3-9.9c-0.1-0.7-0.1-1.5-0.3-2.2c-0.6-1.7-0.7-3.8-1.6-5.4
		c-2.9-6.2-10.7-12.6-17.4-14.1c-3.9-0.9-9.6,0.6-13.8,0.1c-2.3-0.3-7-0.6-9,0.3c-1.9,0.9-3.3,2.5-5.1,3.5c-1.9,1.2-5.1,2.5-4.9,5.2
		c0.1,0.1,0,0,0.1,0.1c0.4,0,0.4,0.1,0.7-0.1c0.7-0.3,4.4-4.4,4.8-1.7c-0.3,0.4-0.4,1-0.7,1.6c-1.5,2.5-3.9,4.4-6,6.4
		c-1,1-1.7,2.6-2.8,3.6c-1.5,1-2.8,2-4.2,3.1c-1.6,1.5-2.8,3.5-4.4,4.9c-2.6,2.5-5.4,4.9-8,7.4c-1.5,1.5-2.3,3.5-3.9,4.9
		c-1,0.9-2.8,2.5-3.3,3.6c-0.9,1.5-1.5,3.1-2.3,4.5c-0.4,0.6-1,1.2-1.5,1.6c-1.3,2.2-2.9,4.1-4.1,6.2c-0.6,0.9-1.5,1.7-1.9,2.6
		c-1.6,2.8-3.2,5.5-4.6,8.4c-1.2,2.3-1.6,4.8-2.8,7.1c-1.3,2.9-2.3,6.1-2.8,9.9c-5.8,4.2-11.3,9-16.7,13.4
		c-6.4,5.2-12.9,9.9-19.3,15.1c-6.2,5.1-12.6,10.3-19.9,14.5c-4.9,2.9-10.7,5.2-16,7.8c-1.3,0.7-4.8,2-5.8,0.6c-0.7-1,0-3.2,0.3-4.1
		c1.3-3.6,2.8-7.3,4.4-10.7c1.7-3.8,3.2-7.6,4.9-11.2c0.9-1.9,1.2-3.9,2.2-5.8c1.3-2.8,3.2-5.1,4.5-7.7c1.2-2,1.9-4.2,2.9-6.2
		c1.2-2.5,2.9-4.5,4.2-6.8c0.7-1.3,1.9-2.5,1.7-4.5c-1,0.3-2.9,2.3-3.2,2.2c-0.3-0.3-0.3-0.3-0.4-0.9c3.2-4.2,5.8-9,8.4-13.7
		c1.3-2.2,3.2-4.1,4.4-6.2c0.7-1.3,1-2.8,1.7-4.2c1.5-3.2,3.5-6.2,5.1-9.3c1.6-3.1,5.8-9,3.9-14.1c-1.2-3.2-3.1-5.8-5.7-7
		c-1.7-0.9-5.8,0.4-6.1-2c0.4-0.6,0.9-1,0.9-1.9c-0.6-0.3-1-0.9-1.6-1.2c-1.9-0.7-5.1-0.3-7,0.6c-3.9,1.9-8,4.8-11,7.4
		c-5.2,4.2-10.2,8.7-15,13.2c-2.9,2.6-5.4,5.8-8.3,8.4c-2.8,2.5-5.7,4.6-8.3,7.1c-3.1,2.8-5.4,6.2-8.4,9.2c-0.6,0.6-1,1.6-1.6,2.2
		c-4.1,3.9-6.7,9.3-10.7,12.9c-0.6,0.6-4.1,3.5-4.9,1.9c-0.1-0.1-0.1-0.4-0.3-0.9c0.4-0.4,0.6-1.2,1-1.6c1.2-1.2,2.3-2,3.2-3.5
		c2.2-4.1,3.1-8.9,5.1-13.1c1.2-2.6,3.1-4.9,4.2-7.4c1.6-3.5,2.5-7.3,3.5-11c0.9-2.8,2-5.2,2.5-8.3c0.4-3.1-1.2-6.1-2.3-7.8
		c-2.3-4.1-5.7-5.4-10.3-6.7c-1.6-0.4-4.4-0.9-6.5,0c-2.6,1-4.5,2.5-6.2,4.2c-5.7,5.4-5.5,16-8.4,23.4c-2.8,7-5.5,14.4-8.7,21.2
		c-1,2-1,4.6-2,6.8c-0.4,1-1.3,2-1.9,3.1c-0.9,1.6-1,3.6-1.7,5.2c-1,2-2.3,3.9-3.3,6c-1.3,2.8-2,5.5-3.3,8.3
		c-1.9,4.1-4.8,7.8-6.8,11.8c-0.7,1.3-1.3,2.6-1.9,3.8c-0.3,0.7-0.4,1.7-0.9,2.2c-0.9,0.9-2,1.2-3.1,2c-3.2,2.5-6.5,4.6-9.9,7.1
		c-8,6.2-16.6,12.3-25.6,17.6c-3.6,2.2-7.3,3.8-11.2,6c-6.8,3.8-14.7,6.2-22.5,9.4c-4.6,1.7-9.7,2.2-14.5,3.8
		c-1.3,0.1-2.6,0.4-3.9,0.6c-4.6,1.6-12.2,1-14.8-0.6c-3.1-1.7-0.6-5.1-1.9-7.8c-0.4-0.9-1-2-1.5-3.1c-0.7-1.7-0.3-4.1,0-5.4
		c0.3-1.5,0.1-2.6,0.6-3.9c0.6-2.3,1.5-4.6,2.2-7s0.7-5.5,2.6-7c0.6-0.6,2.6-1,3.8-0.9c2.9,0.3,5.8,0,8.6,0.3
		c1.2,0.1,2.6-0.6,3.5-0.9c3.1-1.3,5.8-2.2,8.4-3.6c7-4.1,12.8-9.3,18.4-14.5c2.2-2,4.1-4.5,6.4-6.5c3.9-3.8,7.8-7.6,11.6-11.3
		c1.7-2.3,3.5-4.6,5.2-7c1.6-1.7,3.2-3.5,4.6-5.4c1.3-2.2,2.5-4.5,3.6-6.8c2.3-4.4,4.9-8.7,6-14.1c1.3-6.8-3.8-14.7-6.8-17.9
		c-4.6-4.9-12.6-7.1-20.5-8c-2.3-0.3-6.7,0.6-8.7,1.3c-1.2,0.1-2.5,0.3-3.6,0.4c-3.9,0.4-7.1,0.9-9.3,2.6c-3.6,2.9-6.2,6.8-9.4,10
		c-8.1,7.8-14.5,17.3-20,27.2c-3.3,6.1-7.6,11.8-10.9,17.9c-3.6,6.8-6.8,14.1-9.6,21.5c-2.2,5.8-3.5,11.9-4.6,18.4
		c-0.1,2.5-0.3,4.9-0.4,7.4c-0.1,1.3,0.6,2.8,0.1,3.8c-0.9,1-2.3,1.6-3.3,2.5c-3.2,2.5-6.7,4.6-9.9,7.3
		c-7.8,6.1-16.6,11.9-25.4,17.1c-3.6,2.2-7.7,3.9-11.5,6c-4.1,2.3-8.9,3.8-13.5,5.5c-3.2,1.2-6,0.1-6.4-2.5c0.4-0.6,0.4-1.3,0.7-2
		c0.6-1,1.6-2,1.9-3.3c0.1-1-0.4-2.5-0.7-3.2c-0.3-1,0.1-3.1,0.4-3.9c1-3.6,2.2-7.3,3.3-10.9c0.6-1.9,1.6-3.9,2.3-6
		c0.6-2,1.5-3.8,2-5.7c0.3-1.2,0.4-2.2,1-3.2c1.3-3.1,3.1-5.8,4.4-8.9c2.3-4.9,3.9-10.2,6.4-15.1c2.6-5.4,5.8-10.6,8.6-15.8
		c1-1.9,1.7-3.9,2.8-6c1.9-3.9,3.6-8.1,5.7-12.1c1.3-2.6,3.1-4.8,3.5-8.1c0.1-1.6-0.4-3.6-0.9-4.8c-1.2-3.3-1-7-3.1-9.3
		c-0.9-1-3.3-1.3-3.8-2.2c-0.6-0.6-0.7-2-1-3.1c-0.4-0.1-0.7-0.6-1.2-0.7c-1-0.1-1.9-0.3-2.9-0.4c-0.9-0.3-1.6-1.3-2.6-1.6
		c-1.7-0.6-4.5,0.4-6.1,1s-2.9-0.1-4.2,1c-1.7,1.6-1.3,5.2-2.2,7.6c-1,2.8-2.5,5.7-3.8,8.3c-1.3,2.8-2.3,5.7-3.6,8.4
		c-2.9,6.2-5.8,12.8-8.7,19.2c-2.2,4.6-2.8,10.5-4.8,15.1c-3.3,7.4-7.3,15-9.7,23.1c-0.9,2.9-1.6,5.8-2.3,8.7
		c-0.1,1.3-0.4,2.8-0.6,4.1c-0.6,2.2-1.7,4.2-2.3,6.4c-0.3,1.2-0.3,2.2-0.4,3.6c-0.4,2.5-0.9,4.9-1.2,7.7c-0.4,3.6,2.6,9.7,3.6,12.1
		c0.9,1.9,1,5.2,2.6,6.1c1,0.4,1.7-0.7,3.1-1c0.7,0.6,1.5,0.9,2,1.6c1.7,2.2,2.5,5.1,4.1,7.3c2.8,3.5,10.7,7.3,15.7,8.1
		c1.7,0.1,3.5,0.4,5.2,0.6c10.6,1.2,17.6-6.8,23.2-10.6c2.2-1.5,6.2-0.6,8.7-1.9c2.3-1.3,3.6-3.6,5.4-5.4c2.8-2.8,5.4-5.7,8.1-8.4
		c2.8-2.6,5.1-5.7,8.1-8.1c1-0.9,3.2-1.6,3.2-3.2c-0.3-0.1-0.1-0.1-0.4-0.3c-1.3-0.7-5.7,1.2-6.7,1.7c-2.2,1.3-4.4,3.1-6.5,4.4
		c-1.7,1-3.6,1.2-5.8,1.9c-1.3,0.4-3.1,1-4.6,0.9c-0.1-0.1-0.1-0.3-0.1-0.6c0.6-0.4,1-1.2,1.6-1.6c1.2-0.9,2.5-1.5,3.6-2.3
		c1.7-1.3,3.5-2.8,5.1-4.2c1.5-1.2,3.1-2,4.4-3.2c0.4-0.4,0.7-1.2,1.3-1.6C775.4,207.7,775.4,207.5,775.4,207.4z M1277,31.5
		c-0.9,0.7-2.8,0.3-3.9,0.7c-1,0.4-2.8,0.4-4.1,0.6c-3.8,0.4-6.8,0.9-8.9,2.6c-3.6,2.9-6.2,6.8-9.4,10c-8.6,8.1-15.1,18.3-20.9,28.6
		c-2.8,4.9-6.2,9.7-9,14.7c-4.6,8.7-8.9,17.9-11.9,27.7c-2.3,7.7-4.8,16.6-2.9,27c0.6,3.1,0.3,6.5,1.2,9c0.3,0,0.1,0,0.3,0.1
		c0.7-0.3,1.3-0.4,2.2-0.4c0.6,0.9,1.5,1.5,1.9,2.5c0.7,1.2,0.7,2.5,1.3,3.8c1.2,2.9,2.2,6.4,3.8,9c1.3,2.2,3.5,3.3,5.1,5.1
		c3.5,3.6,8.4,6.4,13.1,8.6c3.2,1.5,7.3,1.3,11.2,1.7c2,0.3,4.2-0.3,6.2-0.1c2.3-0.3,4.8-0.6,7.1-0.9c3.5,0.3,6.5,0.1,10,0.4
		c1.9,0.1,5.5-0.9,7-1.5s2.6-0.4,3.9-1c3.8-1.6,7.6-3.2,10.9-5.1c3.6-2,7.1-3.6,10.6-5.7c8.1-4.8,15.1-11.2,21.6-17.3
		c2.3-2.2,5.2-3.8,7.7-6c2.6-2.2,4.8-4.8,7.3-7.1c3.8-3.5,7.7-6.5,10.7-10.5c1.7-2.2,3.1-4.5,4.4-7c0.3-0.6,1.3-1.7,0.9-2.6
		c-0.1-0.4,0-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.6-0.1c-0.9,1-2.2,1.7-3.2,2.8c-0.9,0.9-1.5,2.2-2.3,2.9c-2.5,2.3-4.8,4.8-7.4,7
		c-2.8,2.3-5.8,4.1-8.4,6.5c-1.6,1.5-3.9,3.1-5.8,4.2c-0.7,0.4-1.7,0.9-2.5,0.4c-0.1-0.1-0.3-0.6-0.3-1c0.4-0.6,0.6-1.5,0.9-2.2
		c1.2-2,3.6-3.5,5.5-4.9c1.6-1.2,5.2-2.8,5.7-4.6c0-0.1,0.1,0,0-0.1c-0.3-0.1-0.6-0.1-1,0c-0.6,0.6-1.5,0.7-2,1.2
		c-1,0.7-2,1.7-3.2,2.3s-2.3,0.4-2.5-0.9c2.3-1.9,4.2-4.2,6.5-6.1c3.2-2.5,7-4.2,9.9-7c1.2-1,1.9-2.8,2.6-4.2
		c1.3-2.3,3.9-4.4,6.1-6.1c2.5-1.9,5.1-3.6,7.6-5.5c1.2-0.9,2.9-1.5,2.8-3.5h-0.1V99c-1.2,0-3.3,0.9-4.2,0.3
		c-0.1-0.1-0.1-0.1-0.1-0.4c0.6-0.4,3.9-3.2,3.9-3.8c0.1-0.7-0.4-0.9-0.6-1.5c-0.3-1.3,0.1-1.9,0-3.1c-0.1,0-0.3,0-0.4-0.1
		c-1.5-0.7-4.5,2.9-5.1,3.8c-0.6,0.7-1.5,1.6-1.6,2.6c-0.3,1.6,0,2-1.2,3.1c-3.6,3.5-8,6.4-11.9,9.6c-5.2,4.2-10.9,7.7-16,11.9
		c-1.2,0.9-2.5,1.5-3.6,2.3c-8.3,6.5-17.4,12.9-26.9,18.4c-4.4,2.6-8.9,4.8-13.4,7.3c-4.4,2.5-9.4,4.1-14.1,6
		c-3.8,1.6-7.6,2-11.6,3.5c-4.4,1.6-9.6,2.8-14.7,3.3c-4.8,0.6-11.6,1-13.9-1.5c-2-2.2-0.1-4.1-0.9-6.4c-0.4-1.3-1.2-2.6-1.6-3.9
		c-0.7-1.7-0.3-4.1,0-5.4c0.3-1.5,0.1-2.6,0.6-3.9c0.6-2.3,1.5-4.6,2.2-7c0.7-2.3,0.7-5.5,2.6-7c0.7-0.6,2.6-0.9,3.8-0.7
		c1.3,0.1,2.3-0.3,3.6-0.1c1.5,0.1,4.8,0.7,6.5,0.1c6.4-2.3,11.9-4.6,16.4-8.3c4.2-3.3,8.3-6.8,12.2-10.5c2.2-2,4.1-4.5,6.4-6.5
		c3.9-3.8,7.8-7.6,11.6-11.3c1.9-2.6,3.8-5.1,5.8-7.7c1.3-1.6,3.2-2.9,4.2-4.6c3.1-5.2,6.2-10.6,8.4-16.4c2.3-6.2,1.2-11.8-2-17.4
		c-3.9-6.7-9.2-9.3-16.7-11.5C1285.6,31.5,1282.2,30.9,1277,31.5z M688,104.5c0.6,0,1-0.1,1.6-0.1c0,0.1,0,0.1,0,0.3
		c-1.2,0.9-1.9,2.3-3.1,3.3c-2.8,2.6-6.2,4.5-9.2,7c-2.2,2-4.1,4.4-6.2,6.4c-3.5,3.2-6.8,6.4-10.2,9.7c-2.8,2.6-5.4,5.4-8.3,8
		c-1.3,1.2-2.5,3.1-4.1,3.8v-0.1h0.1c0.7-1.6,2-3.1,3.2-4.4c1.9-2.5,3.8-4.9,6.1-7.1c1.6-1.5,2.9-3.5,4.5-4.9c5.7-5.4,11-11,17.1-16
		c1.7-1.5,3.6-2.9,5.5-4.2C686.1,105.4,687.2,105.1,688,104.5z M543.6,134.6c0.4,0,1,0,1.5,0c0.1,0.1,0.3,0.3,0.4,0.6
		c1.2,1.2,0.3,4.1-0.1,5.1c-2,4.6-4.9,8.7-8,12.5c-2.3,2.9-4.5,6.2-7.3,8.9c-0.4,0.6-0.9,1.2-1.3,1.7c-2,1.9-4.1,3.8-6,5.8
		c-1.7,1.6-1.6,4.8-3.2,6.4c-1.6,1.5-3.5,2.3-5.2,3.8c-2.5,1.9-4.6,4.1-7,6.1c-1.6,1.5-3.3,3.1-5.1,4.5c-1.3,1-2.6,1.6-3.8,2.5
		c-1.7,1.3-2.9,3.1-5.8,3.5c-0.3-0.3-0.4-0.1-0.7-0.6c-1.2-1.3,0.3-4.2,0.7-5.2c1.7-4.6,4.5-8.1,6.8-12.2c3.9-6.8,9-13.1,14.8-18.4
		c2-1.9,3.9-4.4,6.1-6.2c5.2-4.8,10.7-9.4,16.3-13.9c1.5-1.2,2.8-2.3,4.2-3.3C541.8,135.5,542.8,135.2,543.6,134.6z M867.7,95.7
		c0.4,0,1,0,1.5,0c0.1,0.1,0.3,0.3,0.4,0.6c1.2,1.2,0.3,4.1-0.1,5.1c-2,4.6-4.9,8.7-8,12.5c-2.3,2.9-4.5,6.2-7.3,8.9
		c-0.4,0.6-0.9,1.2-1.3,1.7c-2,1.9-4.1,3.8-6,5.8c-1.7,1.6-1.6,4.8-3.2,6.4c-1.6,1.5-3.5,2.3-5.2,3.8c-2.5,1.9-4.6,4.1-7,6.1
		c-1.5,1.3-2.9,2.8-4.4,3.9c-1.5,1.2-3.1,1.9-4.5,3.1c-1.7,1.3-2.9,3.1-5.8,3.5c-0.3-0.3-0.4-0.1-0.7-0.6c-1.2-1.3,0.3-4.2,0.7-5.2
		c1.7-4.6,4.5-8.1,6.8-12.2c3.9-6.8,9-13.1,14.8-18.4c2-1.9,3.9-4.4,6.1-6.2c5.2-4.8,10.7-9.4,16.3-13.9c1.5-1.2,2.8-2.3,4.2-3.3
		C865.9,96.5,867.1,96.3,867.7,95.7z M1289.1,45c0.4,0,1,0,1.5,0c0.1,0.1,0.3,0.3,0.4,0.6c1.3,1.3,0,4.5-0.4,5.5
		c-1.9,4.5-4.9,8.4-7.8,12.2c-2.3,2.9-4.5,6.1-7.1,8.7c-0.4,0.6-0.9,1.2-1.3,1.7c-2,1.9-4.1,3.8-6,5.8c-1.6,1.6-1.6,4.8-3.2,6.4
		c-1.5,1.5-3.5,2.3-5.2,3.8c-2.5,1.9-4.6,4.1-7,6.1c-1.5,1.3-2.9,2.8-4.4,3.9s-3.1,1.9-4.5,3.1c-1.7,1.5-2.9,3.1-6,3.5
		c-0.3-0.3-0.3-0.1-0.4-0.6c-1.2-1.3,0.3-4.2,0.7-5.2c1.7-4.6,4.5-8.1,6.8-12.2c3.9-6.8,9-13.1,14.8-18.4c2-1.9,3.9-4.4,6.1-6.2
		c5.2-4.8,10.7-9.4,16.3-13.9c1.5-1.2,2.8-2.3,4.2-3.3C1287.2,45.8,1288.3,45.6,1289.1,45z M507.5,146.5c0.4,0,0.7,0,1,0v0.1h0.1
		c0.1,0.4,0.1,0.7,0.3,1.2c-0.4,0.6-0.6,1.3-1,1.7c-1,1-2.5,2.3-3.2,3.6c-0.6,1-1.6,2.9-2.3,3.6c-1.6,1.6-2.9,3.2-4.4,4.9
		c-0.4,0.4-0.9,1-1.3,1.5c-3.8,6.4-7.7,12.3-11.3,18.9c-0.9,1.6-2.2,3.1-3.2,4.6c-1.9,3.3-3.5,7-5.1,10.5c-0.7,1.6-1.2,3.8-3.3,4.2
		c-0.1-0.1,0,0-0.3-0.1c-3.1-3.6,0-5.7,1.3-8.4c0.3-1.5,0.6-3.1,0.9-4.5c0.6-1.3,1.9-2.3,2.6-3.6c1.2-2,1.7-4.4,2.8-6.5
		c0.6-1.2,1.7-2.2,2.5-3.3c0.6-1,0.3-2.6,0.9-3.6c0.4-0.7,1.9-0.9,2.6-1.5c1-0.9,2.2-2.5,2.8-3.6c0.9-1.6,0.7-4.8,2-6
		c1-1,2.8-1.3,3.9-2.2c0.7-0.6,1.3-1.7,1.9-2.5c1.3-1.7,2.9-3.6,4.5-5.1c0.6-0.6,1.5-0.7,2.2-1.2C505.5,148.7,507,147.2,507.5,146.5
		z M831.6,107.6c0.4,0,0.7,0,1,0v0.1h0.1c0.1,0.4,0.1,0.7,0.3,1.2c-0.4,0.6-0.6,1.3-1,1.7c-1,1-2.5,2.3-3.2,3.6
		c-0.6,1-1.6,2.9-2.3,3.6c-1.6,1.6-2.9,3.2-4.4,4.9c-0.4,0.4-0.9,1-1.3,1.5c-3.8,6.4-7.7,12.3-11.3,18.9c-0.9,1.6-2.2,3.1-3.2,4.6
		c-1.9,3.3-3.5,7-5.1,10.5c-0.7,1.6-1.2,3.8-3.3,4.2c-0.1-0.1,0,0-0.3-0.1c-3.1-3.6,0-5.7,1.3-8.4c0.3-1.5,0.6-3.1,0.9-4.5
		c0.6-1.3,1.9-2.3,2.6-3.6c1.2-2,1.7-4.4,2.8-6.5c0.6-1.2,1.7-2.2,2.5-3.3c0.6-1,0.3-2.6,0.9-3.6c0.4-0.7,1.9-0.9,2.6-1.5
		c1-0.9,2.2-2.5,2.8-3.6c0.9-1.6,0.7-4.8,2-6c1-1,2.8-1.3,3.9-2.2c0.7-0.6,1.3-1.7,1.9-2.5c1.3-1.7,2.9-3.6,4.5-5.1
		c0.6-0.6,1.5-0.7,2.2-1.2C829.6,109.8,831,108.2,831.6,107.6z M1252.9,56.9c0.4,0,0.7,0,1,0V57h0.1c0.1,0.4,0.1,0.7,0.3,1.2
		c-0.4,0.6-0.6,1.3-1,1.7c-1,1-2.5,2.3-3.2,3.6c-0.6,1-1.6,2.9-2.3,3.6c-1.6,1.6-2.9,3.2-4.4,4.9c-0.4,0.4-0.9,1-1.3,1.5
		c-3.8,6.4-7.7,12.3-11.3,18.9c-0.9,1.6-2.2,3.1-3.2,4.6c-1.9,3.3-3.5,7-5.1,10.5c-0.7,1.6-1.2,3.8-3.3,4.2c-0.1-0.1,0,0-0.3-0.1
		c-3.1-3.6,0-5.7,1.3-8.4c0.3-1.5,0.6-3.1,0.9-4.5c0.6-1.3,1.9-2.3,2.6-3.6c1.2-2,1.7-4.4,2.8-6.5c0.6-1.2,1.7-2.2,2.5-3.3
		c0.6-1,0.3-2.6,0.9-3.6c0.4-0.7,1.9-0.9,2.6-1.5c1-0.9,2.2-2.5,2.8-3.6c0.9-1.6,0.7-4.8,2-6c1-1,2.8-1.3,3.9-2.2
		c0.7-0.6,1.3-1.7,1.9-2.5c1.3-1.7,2.9-3.6,4.5-5.1c0.6-0.6,1.5-0.7,2.2-1.2C1250.9,59.1,1252.5,57.6,1252.9,56.9z M429,156.7
		c0.6,0,0.9,0,1.2,0.1c1,1.3,1.2,3.5,0.9,5.4c-0.3,1.7,0,3.1-0.3,4.6c-0.4,2-1,4.1-1.5,6c-1.5,4.9-2.2,10.2-4.4,14.7
		c-2,4.5-4.2,9-6.4,13.5c-0.7,1.5-1.7,2.8-2.5,4.2c-0.7,1.3-1.2,2.6-1.7,3.9c-0.7,1.3-1.7,2.3-1.6,4.4c0.9,0.6,1.5,1.2,1.6,2.8
		c-0.9,1.3-1.5,2.8-2.6,3.9s-2.9,2.6-3.3,4.2c-0.6,1.9,1.5,3.2,0.6,4.9c-0.6,1.2-2.3,2.2-3.2,3.5c-1.2,2-2,4.2-3.2,6.2
		s-3.1,4.2-4.8,6c-0.9,0.9-1.5,2.2-2.3,2.9c-2.8,2.6-5.1,5.4-7.7,8c-1.7,1.7-3.2,3.9-4.9,5.7c-4.1,3.9-8.1,8.1-12.5,11.9
		c-3.3,2.9-7.1,5.1-10.9,7.7c-1.5,1-2.9,2.3-4.5,3.2c-1,0.6-4.8,2.5-5.7,1c-0.1-0.1-0.1-0.3-0.1-0.6c0.3-0.3,0.3-0.7,0.4-1.2
		c0.7-1.2,2.2-2.6,3.2-3.5c3.5-3.2,7.7-5.5,11.5-8.4c4.2-3.5,8.1-7.4,12.2-11c2.6-2.3,5.4-4.2,7.8-6.5c0.9-0.9,1.9-2.5,2-3.6
		c-0.1-0.7-0.1-1.5-0.3-2.2c0.1-0.9,1-1.7,1.5-2.5c1-1.3,2.5-2.2,3.8-3.3c1.6-1.5,2.2-3.9,3.8-5.5c1.5-1.3,3.6-2.6,3.3-5.1
		c-0.3,0-0.4-0.1-0.7,0c-0.4,0.3-1,0.4-1.5,0.7c-1,0.9-2.2,1.7-3.2,2.8c-0.7,0.7-1.3,1.9-2,2.6c-2.6,2.6-4.6,5.8-7.3,8.3
		c-6.5,6.2-13.1,12.3-19.8,18.3c-2.5,2.2-5.1,5.2-7.8,6.8c-1.2,0.7-3.6,2-3.9-0.3c0.4-0.9,0.6-1.9,1-2.9c1.3-3.3,2-6.8,3.5-10
		c1.2-2.5,2.2-5.1,3.5-7.6c0.9-1.7,2.2-3.3,2.9-5.1c0.9-1.9,1.6-3.9,2.2-5.8c0.3-0.9,0.3-1.6,0.6-2.5c0.3-0.7,1-1.3,1.3-2
		c0.3-1,0.6-2.2,0.9-3.2c0.4-0.9,1.2-1.7,1.6-2.8c1-2,1.2-4.5,2-6.7c0.9-2,2-4.1,2.9-6.1c1.3-2.8,1.9-5.5,3.3-8
		c2.8-4.9,6.8-9.2,10.2-13.5c7.4-9.4,15.1-18.7,24.7-26.6c3.9-3.2,8.1-6.2,12.1-9.3c0.9-0.7,2-1.5,3.1-2
		C428.2,157,428.7,157,429,156.7z M1012.5,86.8c0.3,0,0.6,0,0.9-0.1c0.1,0.3,0.1,0.4,0.3,0.9c-0.7,0.9-1.2,2-2,2.9
		c-4.5,4.4-9.3,8.4-13.8,12.8c-1.9,1.7-3.3,3.9-5.2,5.7c-5.1,4.8-9.9,10-15,14.8c-3.3,3.2-5.4,7.6-8.6,10.6
		c-1.5,1.3-2.3,3.1-4.5,3.8c-0.3-0.3-0.3-0.3-0.4-0.9c1.2-1.5,2-3.2,3.3-4.5c1.9-1.9,3.3-4.2,5.2-6c5.1-4.9,9.2-10.7,14.4-15.7
		c3.6-3.5,7.3-7,10.9-10.3c1.9-1.7,3.6-4.1,5.5-5.8c1.5-1.3,3.1-2.6,4.5-3.9c0.9-0.9,1.7-2,2.6-2.9
		C1011.2,87.7,1012,87.4,1012.5,86.8z M1122.6,77.7c0.3,0,0.6,0,0.9-0.1c0,0.1,0,0.1,0,0.3c0.7,1-0.4,2.3-0.9,2.9
		c-1.5,2.5-3.6,4.2-5.4,6.2c-2.9,3.6-5.2,7.4-7.6,11.5c-1,1.7-2.9,3.3-4.4,4.8c-1.3,1.3-1.9,3.1-2.9,4.6c-0.4,0.7-1.6,1.3-2.3,1.9
		c-1.6,1.5-3.1,3.3-4.8,4.6c0-0.1,0-0.1,0-0.3c0.7-1,0.7-2.3,1.2-3.3c0.9-1.9,1.9-3.8,2.9-5.5c0.4-0.4,0.9-1,1.3-1.5
		c0.9-1.2,1.7-2.3,2.5-3.6c0.6-0.7,1.3-1.6,1.9-2.3c1.6-2.6,3.8-5.4,6.1-7.6c1.2-1,1.9-2.6,2.9-3.8c1.7-1.6,3.2-3.5,4.6-5.4
		c0.6-0.9,1.2-1.6,1.7-2.5C1121.4,78.4,1122.2,78.1,1122.6,77.7z M688.8,139.1c0.6,0.3,0.7,0.4,0.7,1.5c-0.4,0.6-0.4,1.5-0.7,2.2
		c-1.3,2.6-3.8,7-6.2,8.6c0-0.1,0-0.3,0-0.4c0.7-1.3,0.7-2.9,1.3-4.2C685.1,143.8,686.7,141.3,688.8,139.1z M42.1,224.4
		c0.6,0,0.9,0,1.2,0.4c0.6,0.6,0.1,2-0.1,2.6c-1,2.6-1.7,5.4-2.8,8.1c-3.1,8.1-5.5,16.7-8.1,25.1c-1,3.3-1,8-3.1,10.7
		c-0.3,0-0.1,0-0.3-0.1c-0.4-0.1-0.1,0.1-0.3-0.3c-0.6-0.6-0.3-2.5-0.1-3.2c0.7-2.9,1.5-5.7,2.2-8.6c1.6-6.5,2.8-13.2,4.5-19.5
		c0.4-1.7,0.6-3.2,1-4.8c0.7-2.5,1.7-4.8,2.9-6.8c0.6-1.2,1-2.5,2-3.2C41.4,224.7,41.8,224.7,42.1,224.4z M617.2,158.4
		c0.3,0.6-0.6,2-0.9,2.6c-1,2.8-2.2,5.5-3.3,8.1c-1.5,3.1-2.3,6.1-3.8,9.2c-1.3,2.8-2.9,5.5-4.2,8.1c-0.7,1.6-1.3,3.6-1.9,5.2
		c-0.1,0.4-0.1,1-0.4,1.6c-0.4,0.7-1.6,1.2-2.2,1.6c-1.5,1.2-2.9,2.9-4.8,3.6v-0.1c0.9-0.9,1.3-2.3,1.7-3.5c1.5-3.1,2.6-6.2,4.1-9.4
		c1.2-2.3,2.6-4.6,3.8-7c0.7-1.6,1-3.1,1.7-4.6c0.9-1.7,1.9-3.5,2.9-5.2c1.5-2.5,3.2-4.8,4.8-7.4
		C615.5,160.3,616.6,159.4,617.2,158.4z M1094.4,117.2c0.3,0.3,0.3,0.6,0.4,1.2c-0.4,0.4-1,1-1.3,1.5c-0.1,0-0.1,0-0.3,0v-0.1
		C1093.8,119.1,1094.4,117.9,1094.4,117.2z M715.9,163.8c0.7,0.1,0.7,0.6,0.7,1.6c-0.6,0.7-0.7,1.7-1.2,2.6
		c-1.3,2.3-2.6,4.9-3.5,7.7c-1.3,4.1-1.9,8.1-3.1,12.3c-1.3,4.6-3.3,8.9-4.1,13.9c-0.6,3.8-0.1,8-0.9,11.5c-0.3,1.7,0,3.3-2,3.6
		c-3.2-3.6-1.3-11-0.3-15.1c1.2-4.5,2.5-9.2,3.9-13.5c1.3-4.5,2.5-8.9,4.4-12.9c1.6-3.3,2.5-6.8,4.2-10
		C714.5,165,715.3,164.5,715.9,163.8z M1093.4,123.1c0.4,0.1,0.1-0.1,0.3,0.3c-0.9,0.9-2.6,4.1-3.2,5.2c-0.6,1-0.4,2-1.9,2.3
		c-0.3-0.4-0.4-0.7-0.4-1.6c0.4-0.7,0.3-2.2,1-2.8C1090.3,125.4,1092.5,124.7,1093.4,123.1z M222.7,236.1c-1.2,2.6-0.1,7.3,0.3,11
		c0.1,1.5,0.4,3.6,1.3,3.9v0.1c0.1,0.1,0.1-0.1,0.1-0.1c1.9-1.9-0.9-5.5-0.6-8c0.3-2,1.7-3.2,1.6-5.7
		C224.6,236.9,224.4,236,222.7,236.1z M257.7,235.1c0.4,0.7-0.4,2.3-0.9,2.9c-1.3,1.6-2.5,3.2-3.8,4.9c-0.3,0.1-0.4,0.3-0.7,0.6
		c-0.7,1-1,2.2-2.5,2.5c0-0.1-0.1-0.1-0.1-0.3c-0.3-0.3-0.4-0.9-0.4-1.6c0.4-0.4,0.6-1.2,1-1.6c0.9-0.7,1.9-1.3,2.8-2
		c1.2-1.2,1.6-2.9,2.8-4.1C256.2,235.8,257.1,235.6,257.7,235.1z M1087.3,135.5c0.1,0,0.3,0,0.4,0c0.1,0.3,0.1,0.3,0.3,0.6
		c1.5,1.5-0.3,4.1-1.9,4.4c-0.3-0.4-0.4-0.7-0.4-1.6c0.3-0.6,0.3-1.3,0.6-1.9C1086.6,136.3,1087,135.9,1087.3,135.5z M1074.8,138.2
		c0.1,0,0.1,0,0.3,0c-1.7,3.9-0.3,10.6,0.3,16.6c-3.1,2.3-5.1,5.7-7.7,8.1c-1.9,1.7-4.8,2.6-7.1,4.1c-1.3,0.9-4.9,3.8-7.4,3.3
		c-1.9-0.3-2.3-2.3-4.5-2.2c-0.6,0.6-3.9,2-4.5,1.6c-0.3-0.1-0.3-0.3-0.4-0.7c0.6-0.9,0.9-1.9,1.6-2.6c0.6-0.6,1.2-1,1.5-1.6
		c0.9-2,1-4.4,2.6-5.8c0.7-0.6,1.7-0.9,2.5-1.5c1.2-1,2.5-1.9,3.6-2.9c4.4-3.5,8.6-7.4,12.8-11
		C1070.1,142.2,1073.3,140.3,1074.8,138.2z M610.7,196.5c0.6,0,0.9,0,1.3,0.1c2.2,3.3-2.5,8.9-3.1,11.8c-0.1-0.1,0,0-0.1-0.1
		c-0.3-0.1-0.1-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.1c-0.9,1-2.2,1.9-3.2,2.8c-1.6,1.5-2.6,3.6-4.5,4.9c0-0.1,0-0.1,0-0.3
		c1.2-1.2,2.5-4.6,3.2-6.2C605.5,204.6,607.6,200.1,610.7,196.5z M467,214.5c0.3,0,0.7-0.1,1-0.1c0.1,0.1,0.3,0.3,0.3,0.4
		c1,1,0.6,3.9,0.3,4.9c-0.9,3.1-1.6,6.4-2.3,9.4c-0.4,2.2-0.3,4.5-1.3,6.1c-0.3,0-0.1,0-0.3-0.1c-1.2-0.7-1.7-4.2-1.5-6.1
		C463.8,224.4,464.5,217.7,467,214.5z M791.1,175.6c0.3,0,0.7-0.1,1-0.1c0.1,0.1,0.3,0.3,0.3,0.4c1,1,0.4,3.6,0.3,4.5
		c-0.4,2-0.9,4.2-1.3,6.2c-0.1,0.3-0.7,0.3-1,0.6c-1,0.9-1.9,1.9-2.9,2.6c0-0.1,0-0.1,0-0.3c0.4-1,0.1-2.6,0.4-3.8
		C788.5,182.4,789.2,178,791.1,175.6z M1212.4,124.9c0.3,0,0.7-0.1,1-0.1c0.1,0.1,0.3,0.3,0.3,0.4c1,1,0.6,3.9,0.3,4.9
		c-0.9,3.1-1.6,6.4-2.3,9.4c-0.4,2.2-0.3,4.5-1.3,6.1c-0.3,0-0.1,0-0.3-0.1c-1.2-0.7-1.7-4.2-1.5-6.1
		C1209.3,134.7,1209.9,128.2,1212.4,124.9z M593.5,211.2c0.4,0.1,0.1-0.1,0.3,0.3c-0.7,0.7-1,2-1.5,3.1c-0.7,1.5-1.7,2.8-2.3,4.2
		c-1.7-0.1-2.9,0.9-3.9,1.6c0-0.1,0-0.1,0-0.3c1.3-1.3,1.5-4.6,2.8-5.8C590.2,213,592.4,212.5,593.5,211.2z M790.5,189.4
		c0.3,0.4-0.1,1.5-0.1,1.9c-0.4,1.9-0.3,3.6-1.2,5.1c-0.3,0-0.1,0-0.3-0.1c-1-0.6-1.2-2.6-1.5-4.2c0.6-0.4,0.7-1.2,1.3-1.6
		C789.2,189.9,790.1,189.8,790.5,189.4z M423.3,234c0.6,0.1,0.6,0.1,0.7,1c-0.6,0.7-0.7,1.6-1.2,2.3c-0.7,1.2-1.9,2.3-2.9,3.3
		c-0.4,0.6-0.7,1-1.2,1.6c-2.3,2.2-4.4,4.6-6.5,6.8c-0.3,0.4-0.7,1-1,1.5c-2.8,2.8-4.9,6.1-7.7,8.7c-2.3,2.2-4.2,4.6-6.5,6.8
		c-4.2,4.1-8.6,7.8-13.1,11.6c-3.6,2.9-7.4,5.5-11.5,8.3c-1.5,1-3.5,2.8-5.7,2.8c0-0.1,0-0.4,0-0.6c0.7-0.6,1.6-1.6,2.5-2
		c2.2-1.3,4.6-2.3,6.5-3.9c1.3-1,2.5-2.5,3.8-3.5c2.2-1.9,4.5-3.3,6.7-5.2c4.6-4.2,9-8.7,13.7-13.1c3.5-3.2,6.1-7.3,9.6-10.6
		c1.9-1.7,3.1-4.2,4.9-6.1c1.6-1.6,2.6-3.8,4.2-5.4c1-1,1.9-2,2.9-3.1C421.8,235,422.6,234.4,423.3,234z M604.7,216.1
		c0.1,0.1,0,0,0.1,0.3c-1.5,1.6-2.5,4.8-3.5,6.8c-2.9,5.4-5.8,10.6-7.7,17c-0.6,2.2-1.3,3.9-2.3,5.8c-0.3,0.6-0.9,1.7-1.9,1.3
		c-0.6-0.3-0.7-0.9-1-1.5c-1.6-2.9,0.3-6,1.3-7.8c1.3-2.5,2.5-5.2,3.6-7.8c0.4-1,0.7-2.6,1.5-3.5
		C598.2,223.2,602.7,220.3,604.7,216.1z M589.2,219.7c0.3,0.7-1,2.3-1.5,3.2c-0.7,1.5-0.9,3.1-1.7,4.4c-0.4,0.7-3.3,2.5-4.2,2.9V230
		c1.3-1.9,1.6-4.5,2.8-6.5C585.3,222.2,588.2,221.2,589.2,219.7z M234.2,272c-0.1,3.2-4.8,7.1-6.5,9.4c-3.5,4.4-6.8,9-9.6,13.9
		c-1.6,2.8-4.1,5.2-5.7,8c-1.2,2.2-3.1,3.9-4.2,6c-0.7,1.2-1.6,2.8-3.3,3.1c-0.7-0.6-0.9-0.9-1-2.2c1.3-2.3,0.3-2.5,3.6-3.5
		c1.3-2.8,1.3-6.4,2.8-9c1-1.9,3.2-5.7,4.6-7.1c1.6-1.6,3.3-3.3,4.4-5.2c1-1.9,2.2-6.4,4.8-6.8c0.1,0.3,0.3,0.9,0.3,1.5
		c-0.6,1-0.7,2.2-1.3,3.1c-0.9,1.5-2.2,2.6-2.8,4.2h0.1c1.6-2,3.8-3.6,5.1-5.8C227,278.3,230.1,272.7,234.2,272z M240.7,274.3
		c2.2,0.1,2.9,2.6,3.8,4.5c2,3.8,3.8,8,6.4,11c1,1.3,3.1,2.2,3.5,4.2c-0.4,0.1-1.2,0.3-1.7,0.3c-0.7-0.4-1.6-0.6-2.3-1
		c-2.6-1.5-7.7-7.6-9.3-10.5c-0.9-1.6-2.3-5.4-1.3-7.4C240,274.9,240.4,274.6,240.7,274.3z M349.6,261.7c0.4,0.1,0.4,0.3,0.9,0.4
		c0.6,4.5-2.5,6.4-4.1,9.3c-0.6,1-0.7,2.8-1.9,3.3c-0.1,0.1-0.3,0.1-0.6,0.1c-0.1-0.3-0.3-0.7-0.3-1.3c0.6-0.9,0.6-2.2,1-3.1
		C346,267.4,347.5,264.3,349.6,261.7z M580.9,238.8c0.1,0,0.1,0,0.3,0v0.1H581c-0.1,0.6-1.7,2.5-2.3,2.2c-0.1-0.1-0.1-0.1-0.1-0.4
		C579.3,240.4,580.6,239.3,580.9,238.8z M337,289.7c0.3,0,0.7-0.1,1-0.1c0,0.1,0.1,0.3,0.1,0.4c1,1.2-0.4,6.4-0.7,7.3
		c-0.9,2.2-2.8,4.1-3.9,6.1c-2.2,4.1-3.8,8.6-5.7,12.8c-3.5,7.7-6.5,15.7-10.2,23.4c-7,14.7-14.1,29.3-21.1,44.2
		c-2.6,5.7-4.5,12.1-7.3,17.7c-0.7,1.6-1.5,4.8-3.3,5.2c-0.1-0.3-0.3-0.9-0.3-1.5c0.3-0.4,0.1-1,0.3-1.6c0.4-1.6,1-3.2,1.5-4.8
		c3.2-7,5.7-14.4,8.9-21.4c8.7-19,17.7-37.9,26.7-56.8c3.2-6.7,6-13.4,9-20c1.5-3.2,2-7,3.8-9.9C336.3,290.5,336.6,290.2,337,289.7z
		"
        />
        <g>
          <g>
            <path
              className="st16"
              d="M909.9,484.2h-27.4v26.2h27.4v29.9c-7,3.2-17.7,5.4-29.5,5.4c-4.8,0-9.4-0.4-13.7-1.2
				c-29.7-5.4-46.8-28.6-46.8-61.9c0-37.6,22.5-63.8,65.2-63.8c19,0,34.9,4.8,48.9,11.8v-30.1c-13.5-4.8-30.6-8.9-51.2-8.9
				c-58.7,0-97.1,39.4-97.1,93.1c0,47.4,29.9,82.9,81.1,87.4c3.7,0.3,7.4,0.5,11.3,0.5c24.6,0,48.3-6.2,64.4-14.3v-48.1V484
				L909.9,484.2L909.9,484.2z"
            />
            <path
              className="st16"
              d="M962.5,506.7c0-38.1,20.9-66.8,57.1-66.8c35.7,0,56.3,25.7,56.3,67.9v7.5h-81.3
				c0.5,19.5,14.5,32.2,35.7,32.2c12.9,0,23-3.5,32.2-8.6l9.7,22c-13.4,8.3-29.2,11.8-44.8,11.8C981,572.6,962.5,541,962.5,506.7z
				 M1043.5,492.9c-0.5-14.5-7.5-28.7-24.4-28.7c-15.9,0-24.7,13.5-24.7,28.7H1043.5z"
            />
            <path
              className="st16"
              d="M1090.4,506.1c0-38.1,25.5-66.2,63.3-66.2s63.6,28.1,63.6,66.2s-25.7,66.5-63.6,66.5
				C1115.9,572.6,1090.4,544.2,1090.4,506.1z M1184.3,506.1c0-23.3-10.5-40.5-30.6-40.5s-30.6,17.1-30.6,40.5
				c0,23.6,10.5,40.8,30.6,40.8S1184.3,529.7,1184.3,506.1z"
            />
            <path
              className="st16"
              d="M1236.6,443.1h27.1l3.5,14.3c11.6-11.6,22-17.4,34.9-17.4c5.9,0,12.9,1.6,17.9,5.1l-11.3,27
				c-5.6-3.5-10.8-4.3-14.5-4.3c-8.3,0-15.3,3.5-25.7,12.7V570h-31.9V443.1L1236.6,443.1z"
            />
            <path
              className="st16"
              d="M1314.2,596.2c0-14,9.7-24.9,22.8-30.6c-5.4-3.2-8.3-9.1-8.3-15.9c0-7.5,4.8-16.6,15.3-23.9
				c-13.5-7.8-21.2-22.5-21.2-39.4c0-23.3,17.1-46.7,51.7-46.7c11.3,0,20.1,2.7,25.2,5.7c1.4,0.5,2.2,0.3,3.2-0.5
				c6.7-6.7,15.1-11,30.6-11c3,0,6.7,0.3,8.6,0.5l-6.4,23c-3-0.8-6.5-1.4-9.4-1.4c-2.7,0-6.7,0.6-9.4,2.2c7.5,8.1,11.3,18.7,11.3,30
				c0,28.4-21.7,47-52.5,47c-5.9,0-11.3-0.8-15.6-2.2c-2.4,4-3.2,6.7-3.2,9.4c0,22.8,83.4,1.6,83.4,49.1c0,23-23,41.9-67,41.9
				C1336,633.5,1314.2,618.2,1314.2,596.2z M1409.7,595.1c0-18.2-39.4-14.5-56.5-21.1c-4,3-10.2,8.3-10.2,16.6
				c0,12.6,13.4,20.4,34.6,20.4C1396.9,611,1409.7,604.5,1409.7,595.1z M1397.9,487.8c0-12.6-8.6-23.8-22.8-23.8
				c-12.6,0-22,8.3-22,23.6c0,12.7,8.1,23.6,22.2,23.6C1388.3,511.2,1397.9,503.2,1397.9,487.8z"
            />
            <path
              className="st16"
              d="M1457.3,406.7c0-10.8,8.3-19.3,19.5-19.3c11.3,0,19.8,8.4,19.8,19.3c0,10.5-8.6,19-19.8,19
				C1465.7,425.7,1457.3,417.5,1457.3,406.7z M1460.8,443.1h32.2V570h-32.2V443.1z"
            />
            <path
              className="st16"
              d="M1596.3,557.8c-9.1,8.3-20.6,14.8-36.5,14.8c-24.1,0-42.2-16.1-42.2-40.5c0-17.1,8.3-27.9,21.4-34.6
				c8.9-4.6,19.8-6.7,32.7-7.3l21.7-0.8v-4c0-14.8-9.7-21.1-24.4-21.1c-12.6,0-24.1,4-33.3,8.9l-10.5-20.6
				c15-8.4,31.4-12.6,49.1-12.6c34.1,0,50.9,15.6,50.9,46.2v51.5c0,14.8,0.5,25.7,2.4,32.5h-26.8L1596.3,557.8z M1576.3,511.5
				c-8.3,0.3-14.3,1.6-18.5,4c-5.4,3-7.8,8.1-7.8,14.5c0,11,7.3,17.4,18.8,17.4c11.8,0,20.3-5.9,24.6-9.4v-27.1L1576.3,511.5z"
            />
            <path
              className="st16"
              d="M1652.2,443.1h27.3l3.2,14.3c13.5-10.5,27.6-17.4,43.5-17.4c20.1,0,40.8,9.9,40.8,46.2V570h-32.2v-74.1
				c0-15.6-3.2-28.2-19.5-28.2c-10.5,0-21.7,5.6-31.1,13.5v88.7h-31.9V443.1H1652.2z"
            />
          </g>
          <g>
            <path
              className="st17"
              d="M670.8,326.1c-22.2,0-42.3,9.1-56.9,23.6c-3.8,3.8-7.2,8-10.2,12.5c12.8-8.5,28-13.5,44.5-13.5h80.5v80.5
				c0,16.5-5,31.7-13.5,44.4c21.7-14.5,36.1-39.1,36.1-67.1v-80.4H670.8L670.8,326.1z"
            />
            <path
              className="st16"
              d="M627.6,369.3c-22.1,0-42.3,9-56.8,23.5c-3.7,3.7-7.1,7.8-10,12.2c12.8-8.6,28.2-13.7,44.8-13.7H686v80.5
				c0,16.5-5,31.9-13.7,44.8c21.5-14.5,35.7-39,35.7-66.8v-80.5H627.6z"
            />
            <path
              className="st17"
              d="M665.5,411.9v80.5c0,44.5-36,80.5-80.5,80.5h-80.5v-80.5c0-44.5,36-80.5,80.5-80.5L665.5,411.9L665.5,411.9
				z M559.1,549.3c5.1-2.7,6.7-5.9,6.7-14v-23.7h-23.7c-9.3,0-14,1.9-16.8,6.7l-0.5,0.9h33.6v30.6L559.1,549.3z M592.8,556.1
				c6.1-3.4,7.6-6.2,8-14.4v-65.1h-63.7c-10.4,0-13.4,3.4-16.3,7.8l-0.6,0.9h71.7v71.4L592.8,556.1z M627.8,534.7
				c6.1-4.1,7.7-6.1,8.2-14.5v-78.8h-79.2c-10.1,0-14.5,4.6-16.8,8.1l-0.6,0.9H627v84.8L627.8,534.7z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ExperienceLogo
