import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import MobileMenu from "../components/mobile-menu"
import Breadcrumbs from "../components/breadcrumbs"
import { UserInterfaceContext } from "../context/UserInterfaceContext"
import { Transition } from "@headlessui/react"
import LoadingSpinner from "../components/loading-spinner"
import classNames from "../utils/classnames"
import LocalMenu from "../components/local-menu"

interface ILayoutProps {
  children?: React.ReactNode
  breadcrumbs?: string
}

export default function MainLayout(props: ILayoutProps): JSX.Element {
  const { children, breadcrumbs } = props
  const {
    setMegaMenuActive,
    showMainContent,
    showAwkward,
    mobileMenuOpen,
    setMobileMenuOpen,
    tocMobilePortalRef,
  } = React.useContext(UserInterfaceContext)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <div role="navigation">
        <a
          href="#main"
          className="sr-only focus:not-sr-only absolute bg-blue text-white p-4 block text-center"
        >
          Skip to content
        </a>
      </div>

      {/* <WelcomeHeader /> */}
      <Header siteTitle={data.site.siteMetadata?.title || `Georgian College`} />

      <nav className="sticky top-0 lg:static z-[9999] bg-blue min-h-[10px]">
        <LocalMenu />
      </nav>

      {breadcrumbs && breadcrumbs !== `` && (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      )}

      <div
        ref={tocMobilePortalRef}
        id="table-of-contents-mobile-portal"
        className="2xl:hidden block sticky top-14 h-0 w-full z-[999]"
      />

      {showAwkward && (
        <Transition
          as={React.Fragment}
          show={showAwkward}
          appear
          enter="transform transition duration-50"
          enterFrom="opacity-0 translate-y-12"
          enterTo="opacity-100 translate-y-0"
          leave="transform transition duration-50"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-12"
        >
          <div className="flex flex-col gap-2 text-center lg:text-left lg:flex-row lg:container lg:mx-auto items-center justify-center m-4">
            <LoadingSpinner className="mx-auto lg:mx-0 h-8 w-8 lg:h-6 lg:w-6" />
            <div className="text-gray-300">
              well this is awkward
              <span className="whitespace-nowrap mx-2">¯\_(ツ)_/¯</span>
              something seems to be slowing us down, please wait
            </div>
          </div>
        </Transition>
      )}

      <div
        className={classNames(`gutenberg-page mt-8`, `min-h-[1000px]`)}
        onMouseEnter={(): void => setMegaMenuActive(``)}
      >
        <Transition
          as={React.Fragment}
          show={showMainContent}
          appear
          enter="transform transition duration-75"
          enterFrom="opacity-0 translate-y-12"
          enterTo="opacity-100 translate-y-0"
          leave="transform transition duration-75"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-12"
        >
          <article
            className="page gutenberg-content"
            role="main"
            aria-label="Page Content"
          >
            {children}
          </article>
        </Transition>
      </div>

      <Footer />

      {/* mobile menu backdrop */}
      <Transition
        show={mobileMenuOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="bg-black opacity-25 fixed top-0 right-0 left-0 bottom-0"
          onClick={(): void => setMobileMenuOpen(false)}
        />
      </Transition>

      {/* <WelcomeBack /> */}
    </>
  )
}
